.DeconectionButtton{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 5px;
    height: 30px;
}

.DeconectionButtton .DeconectionButtton__loader{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .05);
    z-index: 9999;
}

.DeconectionButtton .DeconectionButtton__label{
    font-size: 0.85rem;
}

.DeconectionButtton .DeconectionButtton__label.processing{
    color: #CCCCCC;
}