.switch-orgs{
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #177BD1;
}
.switch-content-list{
    width: 500px;
    min-height: 50px;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFFFFF;
}

.switch-content{
    position: relative;
    margin: 0;
    padding: 5px;
    border-radius: 5px;
    border: 0.5px solid #F0F0F0;
    min-height: 25px;
}
.small-title{
    text-align: center;
    color: #B5B5B5;
    font-size: 0.8rem;
}
.item-list{
    position: relative;
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: center;
    border-bottom: 0.5px solid #F0F0F0;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.item-list:last-child{
    border-bottom: none;
}
.item-list:hover{
    background-color: rgb(247, 247, 247);
}
.org-description{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    justify-content: center;
}
.item-org-logo-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    padding: 2px;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: var(--logo-url);
    background-size: contain;
    background-color: #F0F0F0;
    border-radius: 3px;
}

.item-org-logo {
    height: auto;
    width: 100%;
}

.orgName{
    font-weight: bold;
    color: #177BD1;
}
.socialReason{
    font-weight: 300;
    font-size: 0.8rem;
    color: #FAAA20;
}

@media (max-width: 575.98px) { 
    .switch-content-list{
        width: calc(100vw - 20px);
    }
}