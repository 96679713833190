.social-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.right-content-fluid{
    margin-right: -450px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.showTab{
    margin-right: 0;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.social-detail {
    z-index: 10;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 175px);
    background-color: #FFFFFF;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.social-container {
    /* flex-wrap: nowrap; */
    position: relative;
    flex-grow: 1;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.social-content .social-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2px 0;
    height: 50px;
    background-color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.social-content .content-menu{
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 auto;
    margin: 0;
    padding: 0;
}
.social-content .active-minimized{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 1;
    margin: 0;
    padding: 0;
    width: 30px;
    overflow: hidden;
}
.social-content .active-minimized button{
    position: relative;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}
.social-content .active-minimized .active-menu-item{
    position: relative;
    margin: 0;
    padding: 10px 0;
    width: 30px;
    writing-mode: vertical-rl;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #177BD1;
    font-size: 0.9rem;
}
ul.social-menu-container {
    position: relative;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding:0;
    padding-left: 10px;
    flex: 1 auto;
}

ul.social-menu-container.mp-15 {
    position: relative;
    display: block;
    list-style: none;
    margin: 0 15px;
    padding:0;
}

ul.social-menu-container > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 5px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.social-menu-container > li:not(:first-child) {
    margin-left: 15px;
}

ul.social-menu-container > li::before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #177BD1;
    opacity: 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.social-menu-container > li.active::before {
    height: 3px;
    opacity: 1;
}

a.social-menu-item {
    height: inherit;
    width: inherit;
    position: relative;
    cursor: pointer;
    color: #b5b5b5;
    /* margin-bottom: 15px; */
    font-size: 0.75rem;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

a.social-menu-item:hover {
    color: #177BD1;
    text-decoration: none;
}

a.social-menu-active {
    color: #177BD1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.active-minimized > .item-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #177BD1;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0;
    padding: 5px;
    border-radius: 50%;
    writing-mode: vertical-rl;
}

.item-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #177BD1;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    margin-left: 5px;
    padding: 5px;
    border-radius: 50%;
}

.page-social-content{
    flex: 1; 
}
.tdatas{
    position: relative;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.btn-hide {
    border: none;
    background-color: transparent;
}
.head-option-left{
    position: relative;
    display: flex;
    align-items: center;
}

.btn-plus {
    width: 27px;
    height: 28px;
    border: 1px solid #177BD1;
    background-color: #177BD1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-period-title {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    height: 28px;
    border: 0.5px solid #177BD1;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
}

.btn-period-title span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

.btn-period-share{
    position: relative;
    display: flex;
    height: 28px;
    background-color: #177BD1;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
}

.btn-primary-2{
    position: relative;
    height: 30;
    border: none;
    background-color: #177BD1;
    border-radius: 3px;
}

.vertical {
    writing-mode: vertical-rl;
}

.flex-part {
    display: flex;
    flex: 1 auto;
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    
}

@media (max-width: 767.98px) {
    .flex-part {
        padding-bottom: 30px;
    }
}

.w-1 {
    width: 2%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-40 { 
    width: 40%;
}

.w-15 {
    width: 13%;
}

.w-80 {
    width: 80%;
}

.w-77 {
    width: 80%;
}

.w-90 {
    width: 99.5%;
}

.w-100 {
    width: 100%;
}
.width100{ width: 100px !important; }
.width150{ width: 150px !important; }
.width200{ width: 200px !important; }
.min-width100{ min-width: 100px !important; }
.min-width150{ min-width: 150px !important; }
.min-width200{ min-width: 200px !important; }
.pl-15{
    padding-left: 15 !important;
}
.pr-15{
    padding-right: 15 !important;
}
.width-0{
    width: 0 !important;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.minimized-btn-in {
    animation: minimize-in 0.3s ease-in-out;
    -ms-animation: minimize-in 0.3s ease-in-out;
    -moz-animation: minimize-in 0.3s ease-in-out;
    -o-animation: minimize-in 0.3s ease-in-out;
    -webkit-animation: minimize-in 0.3s ease-in-out;
    -webkit-animation: minimize-in 0.3s ease-in-out;
}
.minimized-btn-out {
    animation: minimize-out 0.3s ease-in-out;
    -ms-animation: minimize-out 0.3s ease-in-out;
    -moz-animation: minimize-out 0.3s ease-in-out;
    -o-animation: minimize-out 0.3s ease-in-out;
    -webkit-animation: minimize-out 0.3s ease-in-out;
    -webkit-animation: minimize-out 0.3s ease-in-out;
}
.width-minimized{
    margin-left: -140px;
    /* width: 30px !important; */
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.width-minimized button.minimize-btn {
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.slide-in-right{
    animation: slide-in-right 0.3s ease-in-out 0.3s;
    -webkit-animation: slide-in-right 0.3s ease-in-out 0.3s;
    animation-fill-mode: both;
}
.slide-out-left{
    animation: slide-out-left 0.3s ease-in-out;
    -webkit-animation: slide-out-left 0.3s ease-in-out;
    animation-fill-mode: both;
}
.rotate-180{
    animation: rotate-180 0.3s ease-in-out 0.3s;
    -webkit-animation: rotate-180 0.3s ease-in-out 0.3s;
    animation-fill-mode: both;
}

@keyframes minimize-in{
    to{
        width: 30px;
    }
}
@keyframes minimize-out{
    to{
        width: 170px;
    }
}
@keyframes slide-in-right{
    from{
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
    }
    to{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
}
@keyframes slide-out-left{
    from{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
    to{
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
    }
}
@keyframes rotate-180 {
    from{
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    to{
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
}
}

@keyframes show-tab {
    to{
        margin-right: 0;
    }
}

.fluid-content-data {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
}

/* Medium devices (tablets, less than 992px) */
/* @media (max-width: 991.98px) {
    .social-detail.w-450, .social-detail {
        width: 310px;
    }
} */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .social-detail.w-450, .social-detail {
        position: fixed;
        top: 75px;
        left: 0;
        bottom: 0;
        width: 100vw !important;
        height: calc(100vh - 125px);
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 9;
    }
    ul.social-menu-container > li {
        padding: 0 2.5px;
    }
    .ul.social-menu-container > li a {
        font-size: 0.6rem;
    }
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .social-detail {
        position: fixed;
        top: 75px;
        right: 0;
        bottom: 0;
        width: 440px;
        height: calc(100vh - 125px);
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 9;
        box-shadow: -3px 0 5px rgba(0,0,0,0.1);
    }
}