.scan-invoices {
  position: relative;
  padding-top: 15px;
  width: 70%;
}

.scan-info {
  position: relative;
  padding-top: 15px;
  width: 30%;
}

.pdf-view {
  height: 100vh;
}

.container-img-invoice {
  background-color: rgba(0, 0, 0, .4);
  height: 100vh;
}

.container-img-invoice .container-fluid {
  transform: scale(0.7) translate(0%, -15%);
  zoom: 115%;
}
  
.container-img-invoice > div:first-of-type {
  display: flex;
  flex-direction: column;
}

.container-fluid .pan-container img {
  height: 100vh;
}

.category {
  color: #FD543E;
}

.border-bottom-1 {
  border-bottom: 1px solid #b5b5b5;
}

/* NEW STYLE */

.invoice-preview{
  --bg-color-light: #F0F0F0;
  position: relative;
  margin: 0; padding: 0;
  width: 100%;
  height: 100%;
}
.file-prefiew{
  position: relative;
  margin: 0; padding: 0;
  width: calc(100% - 300px);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.data-preview{
  position: relative;
  margin: 0; padding: 0;
  width:100%;
  height: 100%;
  background-color: #FFFFFF;
  font-size: 0.8rem;
  color: #707070 !important;
}
.preview-file-container{
  width: calc(100% - 10px); 
  /* width: 100%;*/
  height: calc(100% - 10px) ;
}
.item-row{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}
.tags-wrapper{
  display: flex;
  flex-wrap: wrap;
}
.overflow-y{
  height: 110px;
  overflow: scroll;
}

@media (max-width: 767.98px) {
  .file-prefiew{
    display: none !important;
  }
  .file-prefiew.show{
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex !important;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 9999;
  }
  .data-preview{
    width:100% !important;
  }
}