.account-menu{
    position: fixed;
}

.account-menu-area {
    position: absolute;
    display: none;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: rgba(0,0,0,0.1);
    z-index: 98;
}

.account-menu-wrapper{
    position: absolute;
    top: 76px; right: 0; bottom: 0; left: 0;
    display: none;
    flex-direction: column;
    z-index: 99;
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);
    border-radius: 3px;
    right:0;
    top: 75.5px;
    height: 100%;
    width: 100%;
    z-index: 15;
    background:rgba(0,0,0, 0.5);
}
.account-menu-wrapper.fade-out{
    animation: fade-out 0.3s ease-in-out forwards;
    -webkit-animation: fade-out 0.3s ease-in-out forwards;
}

.account-menu-content{
    position: fixed;
    top: 76px; right: 0;
    display: flex;
    flex-direction: column;
    width: 250px;
    background-color: #FFFFFF;
    z-index: 10;
    box-shadow: 0 0 7px rgba(0,0,0,0.2);
}

.account-menu-header{
    display: flex;
    flex-direction: row;
    align-content: center;
    border-bottom: 0.5px solid #F0F0F0;
    padding-top: 15px;
    padding-left: 10px;
    padding-bottom: 15px;
    min-height: 100px;
}

.account-avatar {
    position: relative;
    display: flex;
    align-items: center;
}

.account-infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
}
.account-name{
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.account-email{
    color: #B5B5B5;
    font-size: 0.8rem;
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.account-menu-item{
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.account-menu-item:hover{
    background-color: #F0F0F0;
}

.account-menu-footer{
    position: relative;
    border-top: 0.5px solid #F0F0F0;
    box-sizing: border-box;
    padding: 5px 0 5px 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.account-menu-footer:hover{
    background-color: #F0F0F0;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    overflow: hidden;
    max-width: 100%;
}

.account-menu-footer-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.account-menu-footer-item{
    cursor: pointer;
}

.fade-in {
    animation: fade-in 0.5s ease-in-out;
    -webkit-animation: fade-in 0.5s ease-in-out;
}

.fade-out {
    animation: fade-out 0.5s ease-in-out 0 forwards;
    -webkit-animation: fade-out 0.5s ease-in-out 0 forwards;
}

.hidden{
    display: none;
}

.visible{
    display: block;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .account-menu-content{
        top: 76px; right: 0;
        width: 100%;
    }
}
