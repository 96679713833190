.users-page {
    position: relative;
}
.users-content{
    position: relative;
    flex: 1 auto;
}
.user-detail {
    position: relative;
    display: flex;
    flex-direction: column;
}