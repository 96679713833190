.loader{
    display: none
}

.loader.visible{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    border: 3px solid rgba(0,0,0,.1);
    border-top: 3px solid #177BD1;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(720deg)
    }
}

.loader-create-staff.visible .half-circle{
    width: 20px;
    height: 20px;
}
