.orgsEmpty-wrapper{
    position: relative;
    display: flex;
    height: 55px;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
}


.orgsEmpty-logo{
    position: relative;
    display: flex;
    margin: 0 10px 0 0;
    padding: 5px;
    width: 50px;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 3px;
    animation:loader-anim 1.5s;
}

.orgsEmpty-content{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 5px 0 0;
    padding: 5px;
    width: 100%;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 3px;
    
}

.orgsEmpty-content-link {
    color: #177BD1;
    font-size: 14px;
    cursor: pointer;
}

