.inputarea-wrapper {
  background-color: #f1f1f1;
  border-radius: 3px;
  min-height: 35px;
  padding: 10px;
}
.inputarea-actions-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.inputarea-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #f0f0f0;
  height: 30px;
  width: 30px;
  z-index: 99;
}
.inputarea-action:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.inputarea-action:active {
  background-color: #c5c5c5;
}
.hidden {
  display: none;
}
.visible{
  display: inline;
}
.edit-mode {
  border: 1px solid #007bff;
  background-color: #fff;
}
.inputarea-edit{
  width: 100%;
}
.inputarea {
  outline: none;
  resize: none;
}