.main{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: inherit;
    margin: 0; 
    padding: 0;
    background-color: #F8F8F8;
}
.main .explicit-content{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    flex: 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9;
}