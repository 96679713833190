.CustomInputFile{
    position: relative;
    display: flex;
}

.CustomInputFile .CustomInputFile__controls{
    margin-left: 10px;
}

.CustomInputFile__controls button:not(:first-child){
    margin-left: 10px;
}

.CustomInputFile .btn {
    padding: 0 !important;
}

.CustomInputFile .circular{
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
}

.CustomInputFile .bg-none{
    background-color: transparent !important;
}

.btn__cancel{
    display: inline-block;
    width: inherit;
    height: inherit;
    background-image: url(./assets/svg/_ionicons_svg_ios-close.svg);
    background-position: 45% 48%;
    background-repeat: no-repeat;
    background-size: 30%;
}

.btn__upload{
    display: inline-block;
    width: inherit;
    height: inherit;
    background-image: url(./assets/svg/_ionicons_svg_ios-cloud-upload.svg);
    background-position: 45% 48%;
    background-repeat: no-repeat;
    background-size: 50%;
}

.CustomInputFile .hidden {
    display: none;
}

.import-label{
    margin: 0 15px;
    font-weight: 400;
    font-size: 12px;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    color: #000;
}

.import-file{
    width: 150;
    height: 30px;
    border-radius: 50px;
    border: 1px solid #E29300;
    background-image: url('../../assets/svg/icon-arrow-round-up.svg');
    background-repeat: no-repeat;
    background-position: top 49% right 10%;
    background-size: 6px;
    padding: 0 10px 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}