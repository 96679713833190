.fees-content{
    position: relative;
    display: flex;
    height: 100%;
}
.fees{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
}
.fees .headerContent{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 70px;
    border-bottom: 0.5px solid rgba(125,125,125,.2);
    box-sizing: border-box;
}
.display-table {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.fee-detail {
    z-index: 10;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.attachment-prefiew {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(100% - 50px);
    background-color: rgba(0, 0, 0, .6);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.preview-file-container .btn-close {
    z-index: 999;
    position: absolute;
    top: 17px;
    right: 10px;
    padding: 0px;
    border: none;
    width: 25px;
    height: 25px;
    background-color: rgba(0, 0, 0, .6);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

/* For small screens */
@media (max-width: 575.98px) {
    .fees-date-width {
        width: 20%;
    }
    .fees-deliver-width {
        width: 45%;
    }
    .fees-amount-width {
        width: 45%;
    }
    .preview-file-container .btn-close {
        top: 50px;
        width: 50px;
        height: 50px;
    }
}

/* For medium screens */
@media (min-width: 767.98px) {
    .fees-date-width {
        width: 10%;
    }
    .fees-deliver-width {
        width: 20%;
    }
    .fees-amount-width {
        width: 25%;
    }
    .fees-vat-width {
        width: 20%;
    }
    .fees-observation-width {
        width: 25%;
    }
    .fees-attachment-width {
        width: 4%;
    }
}

/* For large screens */
@media only screen and (min-width: 991.98px) {
    .fees-date-width {
        width: 7%;
    }
    .fees-deliver-width {
        width: 12%;
    }
    .fees-amount-width {
        width: 12%;
    }
    .fees-vat-width {
        width: 12%;
    }
    .fees-observation-width {
        width: 55%;
    }
    .fees-attachment-width {
        width: 4%;
    }
}
