.steps-content{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0 5px;
}
.steps-content .btn-step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-left: 10px;
    border: thin solid var(--default-btncolor);
    color: var(--default-btncolor);
    background-color: var(--default-btnbg);
    outline: none !important;
    cursor: pointer;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.steps-content .btn-step .step-position{
    position: relative;
    font-weight: bold;
    font-size: 0.8rem;
}

button.btn-step + div.step-name {
    position: relative;
    font-size: 0.8rem;
    color: var(--txt-color);
    margin-left: 5px;
}
.steps-content .btn-step.active {
    border: thin solid var(--active-btncolor);
    background-color: var(--active-btnbg);
    color: var(--active-btncolor);
}
.steps-content .btn-step.visited {
    border: thin solid var(--visited-btncolor);
    background-color: var(--visited-btnbg);
    color: var(--visited-btncolor);
}
