:root{
  --fontSize-card: 0.85rem;
}
.formCard-wrapper{
    display: flex;
    align-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 3px;
    height: 45px;
    width: 100%;
    padding: 7px;
    margin-left: 10px;
    margin-right: 10px;
    /* padding-right: 60px; */
}

.formCard-input{
    outline: none;
    border: 0;
    flex: 1 auto;
    /* width: inherit !important; */
    color: #707070;
    font-size: var(--fontSize-card);
}

.formCard-select{
  border: none;
  background-color: transparent !important;
  color: #707070;
  width: 100%;
  font-size: var(--fontSize-card);
}

.formCard-input:focus{
    color: #707070
}
.formCard-input:disabled{
    background-color: transparent;
}

.formCard-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 auto;
    /* width: 100%; */
}

.formCard-row .country-flag{
  position: relative;
  display: block;
  width: 20px;
  height: 14px;
  background-color: #AAA7A7;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.formCard-upload {
  border: none;
  color: #AAA7A7;
  background: transparent;
  font-size: var(--fontSize-card);
}

.formCard-input::-webkit-input-placeholder, .formCard-input:-moz-placeholder, .formCard-input::-moz-placeholder, .formCard-input:-ms-input-placeholder{
  color: #AAA7A7;
}

.formCard-text-error {
  color: red;
  font-size: var(--fontSize-card);
}
.formCard-input .formCard-button{
  width: 100%;
  height: 40px;
  background-color: #005AAA;
  color: #FFF;
  font-size: 0.85rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
}

.popover.err{
  padding: 10px !important;
  background-color: rgb(255 231 231) !important;
}

.popover.err.top .arrow::after{
  border-top-color: rgb(255 231 231) !important;
}

.popover.err.bottom .arrow::after{
  border-bottom-color: rgb(255 231 231) !important;
}

.popover.err.left .arrow::after{
  border-left-color: rgb(255 231 231) !important;
}

.popover.err.right .arrow::after{
  border-right-color: rgb(255 231 231) !important;
}