.auth{
    position: absolute;
    top: 0; left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #177BD1;
}
.auth .box-auth{
    width: 500px;
    min-height: 300px;
    padding: 20px;
    border-radius: 5px;
    /* border: thin solid #EFA959; */
    background-color: #FFFFFF;
}

.auth .h-40{
    height: 40px !important;
}

.auth .create-account{
    font-size: 0.7rem;
    font-weight: 700;
    color: #177BD1;
    cursor: pointer;
}

@media (max-width: 575.98px) { 
    .auth .box-auth{
        width: calc(100vw - 20px) !important;
    }
}