.adder{
    position: relative;
    display: flex;
    min-width: 25px;
    height: 25px;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}
.adder > button.btn-add {
    position: relative;
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: thin solid #177BD1;
    border-radius: 3px 0 0 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
}
/* .adder > button.btn-add:hover {
    background-color: rgb(22, 97, 163);
} */
.adder > span.value {
    position: relative;
    width: 25px;
    height: 25px;
    background-color: #177BD1;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-right: 0.3px solid rgba(255,255,255,0.7); */
}
.adder > span.value:last-child {
    border-radius: 0  3px 3px 0;
    border-right: none;
}