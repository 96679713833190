.tri-search{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-items: center;
}

.title-tri{
    display: flex;
    align-items: flex-end;
    color: #555555;
    font-weight: bold;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem; 
    vertical-align: bottom;
    margin-right: 9px;
}

.export-label{
   margin: 0 15px;
   font-weight: 400;
   font-size: 12px;
   font-family: "Exo",Arial, Helvetica, sans-serif;
   color: #000;
}

.export-file{
   width: 150;
   height: 30px;
   border-radius: 50px;
   border: 1px solid #FD543E;
   background-image: url('../../assets/svg/icon-arrow-round-down.svg');
   background-repeat: no-repeat;
   background-position: top 49% right 10%;
   background-size: 6px;
   padding: 0 10px 0 5px;
}




