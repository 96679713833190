.custom-image-zoom{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom-image-zoom img{
    position: relative;
    display: block;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
.custom-image-zoom img:hover{
    cursor: move;
}
.custom-image-zoom img.width-auto{
    width: auto;
    max-width: calc(100% - 10px);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom-image-zoom img.height-auto{
    height: auto;
    max-height: calc(100% - 10px);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom-image-zoom img.width-height-auto{
    max-width: calc(100% - 10px);
    max-height: calc(100% - 10px);
    width: auto;
    height: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out; 
}
.custom-image-zoom .custom-image-zoom-controls{
    z-index: 99;
    position: absolute;
    bottom: 30px;
    left: calc(50% - 60px);
    right: calc(50% - 60px);
    display: flex;
    width: 120px;
    background-color: rgba(255, 255, 255, 0.623);
    overflow: hidden;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.custom-image-zoom .percentage-view{
    z-index: 99;
    position: absolute;
    bottom: 30px;
    right: 50px;
    color: rgb(143, 143, 143);
    height: 40px;
    line-height: 40px;
    padding: 5px;
}
.custom-image-zoom .box-loader{
    z-index: 99;
    position: absolute; 
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 25px);
    right: calc(50% - 25px);
    bottom: calc(50% - 25px);
    left: calc(50% - 25px);
    background-color: rgba(0, 0, 0, 0.3);
    height: 50px;
    line-height: 50px;
    padding: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.box-loader .b-load{
    position: relative;
    width: 25px;
    height: 25px;
    background-image: url(./assets/svg/_ionicons_svg_ios-qr-scanner.svg);
    animation: b-spin 4s ease-in-out infinite;
    -webkit-animation: b-spin 4s ease-in-out infinite;
}
.close-zoom {
    position: absolute;
    top: 5px; right: 25px;
    margin: 0;
    padding: 5px;
    width: 35px;
    height: 35px;
    text-align: center;
    cursor: pointer;
    z-index: 9999;
}
.custom-image-zoom-controls .zoom-control-item{
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-right: 0.5px solid rgba(0,0,0,.3);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out; 
}
.custom-image-zoom-controls .zoom-control-item:last-child{
    border-right: none;
}
.custom-image-zoom-controls .zoom-control-iten:hover{
    background-color: rgba(0,0,0,0.1);
}
.zoom-control-item.zoom-in{
    background-image: url(./assets/svg/_ionicons_svg_ios-add.svg);
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
}
.zoom-control-item.zoom-out{
    background-image: url(./assets/svg/_ionicons_svg_ios-remove.svg);
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;

}
.zoom-control-item.zoom-rotate{
    background-image: url(./assets/svg/_ionicons_svg_ios-refresh.svg);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;

}
.custom-image-zoom .image-view {
    position: relative;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    background-position: center;
    background-size: contain;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
img.opacity-0 {
    opacity: 0;
}

@keyframes b-spin {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
    }
    100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
}

@media (max-width: 767.98px) {
    .custom-image-zoom .percentage-view{
        z-index: 99;
        position: fixed !important;
        top: 3px;
        right: 0;
        left: 0;
        width: 100%;
        /* left: calc(137.5px /2 - 50%); */
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #F0F0F0;
        padding: 5px;
        font-weight: bold;
        font-size: 0.8rem;
    }
    .close-zoom-responsive{
        position: fixed;
        top: 5px; right: 30px;
        margin: 0;
        padding: 5px;
        z-index: 9999;
    }
    .custom-image-zoom .custom-image-zoom-controls{
        bottom: 60px;
        border: 0.5px solid rgba(0,0,0,.3);
    }
}