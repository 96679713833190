.bank{
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 auto;
    height: 100%;
}
@media (max-width:767.98px) {
    .bank {
        padding-bottom: 30px;
    }
}
.bank-detail {
    z-index: 10;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.fluid-content-data {
    position: relative; 
    display: flex;
    flex-direction: column;
    flex: 1 auto;
}

.period-box{
    position: relative;
    width: 260px;
}

.bank .file-prefiew{
    z-index: 999;
    position: absolute;
    top: 0; left: 0;
    display: none;
    width: 100%; height: 100%;
    margin: 0; padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.bank .file-prefiew.show{
    z-index: 999;
    position: absolute;
    top: 0; left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; height: 100%;
    margin: 0; padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
.bank .preview-file-container {
    position: relative;
    flex: 1 auto;
}

.flex-part {
    display: flex;
    flex: 1 auto;
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
@media (max-width: 767.98px) {
    .flex-part {
        padding-bottom: 30px;
    }
}
.chart-box{
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 250px;
}
.chart-box .chart-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    background-color: #FFFFFF;
}
.btn-arrow-circled{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.records{
    position: relative;
    display: flex;
    flex: 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
}
.bank-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.bank-content .bank-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2px 0;
    height: 50px;
    background-color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.bank-content .content-menu{
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 auto;
    margin: 0;
    padding: 0;
}
.bank-content .active-minimized{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 1;
    margin: 0;
    padding: 0;
    width: 30px;
    overflow: hidden;
}
.bank-content .active-minimized button{
    position: relative;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}
.bank-content .active-minimized .active-menu-item{
    position: relative;
    margin: 0;
    padding: 10px 0;
    width: 30px;
    writing-mode: vertical-rl;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E29300;
    font-size: 0.9rem;
}
ul.bank-menu-container {
    position: relative;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding:0;
    padding-left: 10px;
    flex: 1 auto;
}

ul.bank-menu-container.mp-15 {
    position: relative;
    display: block;
    list-style: none;
    margin: 0 15px;
    padding:0;
}

ul.bank-menu-container > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 5px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.bank-menu-container > li:not(:first-child) {
    margin-left: 15px;
}

ul.bank-menu-container > li::before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #E29300;
    opacity: 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.bank-menu-container > li.active::before {
    height: 3px;
    opacity: 1;
}

.bank-menu-item {
    height: inherit;
    width: inherit;
    position: relative;
    cursor: pointer;
    color: #b5b5b5;
    text-decoration: none;
    /* margin-bottom: 15px; */
    font-size: 0.75rem;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

a.bank-menu-item:hover {
    color: #E29300;
    text-decoration: none;
}

a.bank-menu-active {
    color: #E29300;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.active-minimized > .item-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #E29300;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0;
    padding: 5px;
    border-radius: 50%;
    writing-mode: vertical-rl;
}

.item-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #E29300;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    margin-left: 5px;
    padding: 5px;
    border-radius: 50%;
}
.btn-toggle-chart{
    position: relative;
    background-color: #e2e2e2;
    color: #333333;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.page-bank-content{
    flex: 1; 
}

.bank-date-width {
    width: 20%;
}
.bank-account-width {
    width: 20%;
}
.bank-debit-width {
    width: 30%;
}
.bank-credit-width {
    width: 30%;
}

.stmt-date-width {
    width: 25%;
}

.stmt-account-width {
    width: 45%;
}
.stmt-balance-width {
    width: 35%;
}

@media(min-width:575.98px) and (max-width:767.98px) {
    .bank-date-width {
        width: 10%;
    }
    .bank-account-width {
        width: 30%;
    }
    .stmt-balance-width {
        width: 50%;
    }
}

@media(min-width: 767.98px) {
    .bank-date-width {
        width: 11%;
    }
    .bank-reference-width {
        width: 20%;
    } 
    .bank-description-width {
        width: 30%;
    }
    .bank-debit-width {
        width: 25%;
    }
    .bank-credit-width {
        width: 25%;
    }

    .stmt-date-width {
        width: 10%;
    }
    .stmt-period-width {
        width: 30%;
    }
    .stmt-name-width {
        width: 30%;
    }
    .stmt-attachment-width {
        width: 20%;
    }
    .stmt-account-width {
        width: 30%;
    }
    .stmt-balance-width {
        width: 25%;
    }
}