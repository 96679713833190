/* Animations */

@keyframes bounce-down{
    0%{
      transform: translateY(0);
    }
    50%{
        transform: translateY(-75px);
    }
    100%{
        transform: translateY(0);
    }
  }

@keyframes fade-in-out{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@keyframes fade-in-out-light{
  from{
    opacity: 0.2;
  }
  to{
    opacity: 0.8;
  }
}
@keyframes fade-in-top-lighter{
    from{
    transform: translateY(-75px);
    opacity: 0;
    }
    to{
    transform: translateY(0);
    opacity: 0.6;
    }
  }

  @keyframes fade-in-top-light{
    from{
    transform: translateY(-75px);
    opacity: 0;
    }
    to{
    transform: translateY(0);
    opacity: 0.8;
    }
  }
  @keyframes fade-in-top{
    from{
    transform: translateY(-75px);
    opacity: 0;
    }
    to{
    transform: translateY(0);
    opacity: 1;
    }
  }
  @keyframes fade-in-left-light{
    from{
    transform: translateX(-50px);
    opacity: 0;
    }
    to{
    transform: translateX(0);
    opacity: 1;
    }
  }
  @keyframes fade-in-right-light{
    from{
    transform: translateX(50px);
    opacity: 0;
    }
    to{
    transform: translateX(0);
    opacity: 1;
    }
  }
  @keyframes fade-out-top-fade{
    0%{
        transform: translateY(0);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 0.7;
    }
    100%{
        transform: translateY(-300px);
        opacity: 0;
    }
  }
  
  @keyframes o-fadeInLeft{
    0%{
      transform: scale(0.9);
      transform: translateX(-100px) translateY(-50px);
      opacity: 0;
    }
    100%{
      transform: scale(0.9);
      transform: translateX(-50px) translateY(-50px);
      opacity: 1;
    }
  }
  @keyframes o-fadeInRight{
    0%{
      width: 70%;
      transform: scale(0.8) translateX(-20px);
      opacity: 0;
    }
    100%{
      width: 70%;
      transform: scale(0.8) translateX(-70px);
      opacity: 1;
    }
  }
  @keyframes o-fadeInLeft-up{
    0%{
      
      opacity: 0;
      display: inline-block; 
      margin-left: auto !important;
      margin-right: auto !important;/**/
      margin-bottom: 15px;
      width: auto;
      float: none !important;
      transform: scale(1) translateX(-50px);
    }
    100%{
      transform: scale(1) translateX(0);
      opacity: 1;
      display: inline-block; 
      margin-left: auto !important;
      margin-right: auto !important;/**/
      margin-bottom: 15px;
      width: auto;
      float: none !important;
    }
  }
  @keyframes o-fadeInRight-up{
    0%{
      transform: scale(0.9) translateX(50px);
      opacity: 0;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    100%{
      transform: scale(0.9) translateX(0);
      opacity: 1;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @keyframes slide-top{
    from{
    transform: translateY(0);
    }
    to{
    transform: translateY(-650px);
    opacity: 1;
    }
  }
  @keyframes slide-left-map{
    from{
    transform: translateX(0);
    }
    to{
    transform: translateX(-660px);
    opacity: 1;
    }
  }
  /* slide-top-start-fade */
  @keyframes slide-top-start-fade{
    0%{
        transform: translateY(50px);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
      transform: translateY(-500px);
      opacity: 0;
    }
  }
  @keyframes slide-left-right-opacity{
    0%{
      transform: translateX(0);
      opacity: 0;
    }
    33.33%{
      transform: translateX(-50px);
      opacity: 1;
    }
    66.66%{
      transform: translateX(50px);
    }
    100%{
      transform: translateY(0);
      opacity: 0;
    }
  }
  
  @keyframes slide-in-left{
    from{
      transform: translateX(-300px);
      -webkit-transform: translateX(-300px);
      -moz-transform: translateX(-300px);
      -ms-transform: translateX(-300px);
      -o-transform: translateX(-300px);
    }
    to{
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
    }
  }

  @keyframes slide-out-left-2{
    to{
      transform: translateX(-300px);
      -webkit-transform: translateX(-300px);
      -moz-transform: translateX(-300px);
      -ms-transform: translateX(-300px);
      -o-transform: translateX(-300px);
    }
  }

  @keyframes rotate-180{
    to {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
    }
  }

  @keyframes rotate-180-reverse{
    to {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }
  
  /* Animated classes */

  /* delay classes animations 0s - 10s with half between */
  .delay-half{
    animation-delay: 0.5s !important;
  }
  .delay-0{
    animation-delay: 0s !important;
  }
  .delay-1{
    animation-delay: 1s !important;
  }
  .delay-1-half{
    animation-delay: 1.5s !important;
  }
  .delay-2{
    animation-delay: 2s !important;
  }
  .delay-2-half{
    animation-delay: 2.5s !important;
  }
  .delay-3{
    animation-delay: 3s !important;
  }
  .delay-3-half{
    animation-delay: 3.5s !important;
  }
  .delay-4{
    animation-delay: 4s !important;
  }
  .delay-4-half{
    animation-delay: 4.5s !important;
  }
  .delay-5{
    animation-delay: 5s !important;
  }
  .delay-5-half{
    animation-delay: 5.5s !important;
  }
  .delay-6{
    animation-delay: 6s !important;
  }
  .delay-6-half{
    animation-delay: 6.5s !important;
  }
  .delay-7{
    animation-delay: 7s !important;
  }
  .delay-7-half{
    animation-delay: 7.5s !important;
  }
  .delay-8{
    animation-delay: 8s !important;
  }
  .delay-8-halh{
    animation-delay: 8.5s !important;
  }
  .delay-9{
    animation-delay: 9s !important;
  }
  .delay-9-half{
    animation-delay: 9.5s !important;
  }
  .delay-10{
    animation-delay: 10s !important;
  }
  /* duration classes animations 1s - 10s */
  .duration-1{
    animation-duration: 1s !important;
  }
  .duration-2{
    animation-duration: 2s !important;
  }
  .duration-3{
    animation-duration: 3s !important;
  }
  .duration-4{
    animation-duration: 4s !important;
  }
  .duration-5{
    animation-duration: 5s !important;
  }
  .duration-6{
    animation-duration: 6s !important;
  }
  .duration-7{
    animation-duration: 7s !important;
  }
  .duration-8{
    animation-duration: 8s !important;
  }
  .duration-9{
    animation-duration: 9s !important;
  }
  .duration-10{
    animation-duration: 10s !important;
  }
  .duration-half{
    animation-duration: 0.5s !important;
  }
  .duration-1-half{
    animation-duration: 1.5s !important;
  }
  .duration-2-half{
    animation-duration: 2.5s !important;
  }
  .duration-3-half{
    animation-duration: 3.5s !important;
  }
  .duration-4-half{
    animation-duration: 4.5s !important;
  }
  .duration-5-half{
    animation-duration: 5.5s !important;
  }
  .duration-6-half{
    animation-duration: 6.5s !important;
  }
  .duration-7-half{
    animation-duration: 7.5s !important;
  }
  .duration-8-half{
    animation-duration: 8.5s !important;
  }
  .duration-9-half{
    animation-duration: 9.5s !important;
  }
  .duration-10-half{
    animation-duration: 10.5s !important;
  }
  /* play state animation (paused - running)*/
  .paused{animation-play-state: paused !important;}
  .running{animation-play-state: running !important;}
  /* infinite animation */
  .infinite{animation-iteration-count: infinite !important;}
  /* alternate animation */
  .alternate{animation-direction: alternate !important;}
  /* reverse animation */
  .reverse{animation-direction: reverse !important;}
  /* alternate-reverse animation */
  .alternate-reverse{animation-direction: alternate-reverse !important;}
  /* animation fill mode --> indique la façon dont une animation CSS doit appliquer les styles à sa cible avant et après son exécution.*/
  .animation-fill-mode-both{animation-fill-mode: both !important;}
  .animation-fill-mode-none{animation-fill-mode: none !important;}
  .animation-fill-mode-forwards{animation-fill-mode: forwards !important;}
  .animation-fill-mode-backwards{animation-fill-mode: backwards !important;}

  /* bounce up */
  .bounce-up{
    animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -ms-animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -moz-animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -o-animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -webkit-animation: bounce-down 3s ease-in-out 1s infinite alternate;
  }
  /* fades effects */
  .fade-in-out-light{
    animation: fade-in-out-light 2s ease-in-out;
    -ms-animation: fade-in-out-light 2s ease-in-out;
    -moz-animation: fade-in-out-light 2s ease-in-out;
    -o-animation: fade-in-out-light 2s ease-in-out;
    -webkit-animation: fade-in-out-light 2s ease-in-out;
  }
  .fade-in-out{
    animation: fade-in-out 2s ease-in-out;
    -ms-animation: fade-in-out 2s ease-in-out;
    -moz-animation: fade-in-out 2s ease-in-out;
    -o-animation: fade-in-out 2s ease-in-out;
    -webkit-animation: fade-in-out 2s ease-in-out;
  }
  .fade-in-top{
    animation: fade-in-top 0.5s ease-in-out;
    -ms-animation: fade-in-top 0.5s ease-in-out;
    -moz-animation: fade-in-top 0.5s ease-in-out;
    -o-animation: fade-in-top 0.5s ease-in-out;
    -webkit-animation: fade-in-top 0.5s ease-in-out;
  }
  .fade-in-top-light{
    animation: fade-in-top-light 0.5s ease-in-out;
    -ms-animation: fade-in-top-light 0.5s ease-in-out;
    -moz-animation: fade-in-top-light 0.5s ease-in-out;
    -o-animation: fade-in-top-light 0.5s ease-in-out;
    -webkit-animation: fade-in-top-light 0.5s ease-in-out;
  }
  .fade-in-top-lighter{
    animation: fade-in-top-lighter 0.5s ease-in-out;
    -ms-animation: fade-in-top-lighter 0.5s ease-in-out;
    -moz-animation: fade-in-top-lighter 0.5s ease-in-out;
    -o-animation: fade-in-top-lighter 0.5s ease-in-out;
    -webkit-animation: fade-in-top-lighter 0.5s ease-in-out;
  }
  .o-fadeInLeft{
    animation: o-fadeInLeft 2s ease-in-out;
    -ms-animation: o-fadeInLeft 2s ease-in-out;
    -moz-animation: o-fadeInLeft 2s ease-in-out;
    -o-animation: o-fadeInLeft 2s ease-in-out;
    -webkit-animation: o-fadeInLeft 2s ease-in-out;
  }
  
  .o-fadeInLeft-up{
    animation: o-fadeInLeft-up 2s ease-in-out;
    -ms-animation: o-fadeInLeft-up 2s ease-in-out;
    -moz-animation: o-fadeInLeft-up 2s ease-in-out;
    -o-animation: o-fadeInLeft-up 2s ease-in-out;
    -webkit-animation: o-fadeInLeft-up 2s ease-in-out;
  }
  .o-fadeInRight{
    animation: o-fadeInRight 2s ease-in-out;
    -ms-animation: o-fadeInRight 2s ease-in-out;
    -moz-animation: o-fadeInRight 2s ease-in-out;
    -o-animation: o-fadeInRight 2s ease-in-out;
    -webkit-animation: o-fadeInRight 2s ease-in-out;
  }
  
  .o-fadeInRight-up{
    animation: o-fadeInRight-up 2s ease-in-out;
    -ms-animation: o-fadeInRight-up 2s ease-in-out;
    -moz-animation: o-fadeInRight-up 2s ease-in-out;
    -o-animation: o-fadeInRight-up 2s ease-in-out;
    -webkit-animation: o-fadeInRight-up 2s ease-in-out;
  }
  /* fade-out-top-fade */
  .fade-out-top-fade{
    animation: fade-out-top-fade 2s ease-in-out;
    -ms-animation: fade-out-top-fade 2s ease-in-out;
    -moz-animation: fade-out-top-fade 2s ease-in-out;
    -o-animation: fade-out-top-fade 2s ease-in-out;
    -webkit-animation: fade-out-top-fade 2s ease-in-out;
  }
  .fade-in-left-light{
    animation: fade-in-left-light 2s ease-in-out;
    -ms-animation: fade-in-left-light 2s ease-in-out;
    -moz-animation: fade-in-left-light 2s ease-in-out;
    -o-animation: fade-in-left-light 2s ease-in-out;
    -webkit-animation: fade-in-left-light 2s ease-in-out;
  }
  .fade-in-right-light{
    animation: fade-in-right-light 2s ease-in-out;
    -ms-animation: fade-in-right-light 2s ease-in-out;
    -moz-animation: fade-in-right-light 2s ease-in-out;
    -o-animation: fade-in-right-light 2s ease-in-out;
    -webkit-animation: fade-in-right-light 2s ease-in-out;
  }
  /* slides effects */
  .slide-top{
    animation: slide-top 3s ease-in-out;
    -ms-animation: slide-top 3s ease-in-out;
    -moz-animation: slide-top 3s ease-in-out;
    -o-animation: slide-top 3s ease-in-out;
    -webkit-animation: slide-top 3s ease-in-out;
  }
  .slide-top-start-fade{
    animation: slide-top-start-fade 2s linear;
    -ms-animation: slide-top-start-fade 2s linear;
    -moz-animation: slide-top-start-fade 2s linear;
    -o-animation: slide-top-start-fade 2s linear;
    -webkit-animation: slide-top-start-fade 2s linear;
  }
  .slide-left-map{
    animation: slide-left-map 2s linear;
    -ms-animation: slide-left-map 2s linear;
    -moz-animation: slide-left-map 2s linear;
    -o-animation: slide-left-map 2s linear;
    -webkit-animation: slide-left-map 2s linear;
  }
  .slide-left-right-opacity{
    animation: slide-left-right-opacity 2s ease-in-out;
    -ms-animation: slide-left-right-opacity 2s ease-in-out;
    -moz-animation: slide-left-right-opacity 2s ease-in-out;
    -o-animation: slide-left-right-opacity 2s ease-in-out;
    -webkit-animation: slide-left-right-opacity 2s ease-in-out;
  }
  .slide-in-left{
    animation: slide-in-left .3s ease-in-out forwards;
    -ms-animation: slide-in-left .3s ease-in-out forwards;
    -moz-animation: slide-in-left .3s ease-in-out forwards;
    -o-animation: slide-in-left .3s ease-in-out forwards;
    -webkit-animation: slide-in-left .3s ease-in-out forwards;
  }
  .slide-out-left{
    animation: slide-out-left .3s ease-in-out forwards;
    -ms-animation: slide-out-left .3s ease-in-out forwards;
    -moz-animation: slide-out-left .3s ease-in-out forwards;
    -o-animation: slide-out-left .3s ease-in-out forwards;
    -webkit-animation: slide-out-left .3s ease-in-out forwards;
  }
.slide-out-left-2{
  animation: slide-out-left-2 .3s ease-in-out forwards;
  -ms-animation: slide-out-left-2 .3s ease-in-out forwards;
  -moz-animation: slide-out-left-2 .3s ease-in-out forwards;
  -o-animation: slide-out-left-2 .3s ease-in-out forwards;
  -webkit-animation: slide-out-left-2 .3s ease-in-out forwards;
}
.rotate-180{
  animation: rotate-180 .3s ease-in-out forwards;
  -ms-animation: rotate-180 .3s ease-in-out forwards;
  -moz-animation: rotate-180 .3s ease-in-out forwards;
  -o-animation: rotate-180 .3s ease-in-out forwards;
  -webkit-animation: rotate-180 .3s ease-in-out forwards;
}
.rotate-180-reverse{
  animation: rotate-180-reverse .3s ease-in-out forwards;
  -ms-animation: rotate-180-reverse .3s ease-in-out forwards;
  -moz-animation: rotate-180-reverse .3s ease-in-out forwards;
  -o-animation: rotate-180-reverse .3s ease-in-out forwards;
  -webkit-animation: rotate-180-reverse .3s ease-in-out forwards;
}
