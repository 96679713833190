:root{
    --light-grey: #B5B5B5;
    --lighter-grey: #F0F0F0;
  }
  .tag {
    position: relative;
    display: inline-flex;
    height: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 4px;
    align-items: center;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    border: 0.5px solid var(--light-grey);
    border-radius: 4px;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .tag.active{
    background-color: var(--light-grey);
  }

  .tag.pointer, .tag.active.pointer, .tag-row.pointer, .tag-row.active.pointer{
    cursor: pointer;
  }

  .tag-title {
    position: relative;
    font-size: 0.7rem;
    color: var(--light-grey);
    padding: 0px 5px;
  }
  
  .tag.active .tag-title.active{
    color: #FFFFFF;
  }
  
  .tag-active {
      background-color: #177BD1;
  }
  
  .tag-accountant {
    background-color: var(--light-grey);
    color: #fff !important;
  }
  
  .tag-close {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(112, 112, 112, 0.7);
      margin-left: 5px;
      padding: 3px; 
  }
  
  .tag-description{
      border-radius: 5px;
      border: 1px solid #b5b5b5;
      box-sizing: border-box;
      padding-right: 10px;
      padding-left: 10px;
      padding-top: 2px;
      padding-bottom: 1px;
      margin-right: 10px;
      margin-bottom: 10px;
      color: #b5b5b5;
      font-family: "Exo",Arial, Helvetica, sans-serif;
      font-size: 11px;
    }
  
  
    .tag-description img{
      margin-right: 5px;
    }
  
    .ct-tag{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  
  .clickable {
    cursor: pointer;
  }
  .tag .price-tag-box{
    position: relative;
    margin-left: 5px;
  }
  .tag .btn-renover, .tag-row .btn-renover{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border: none;
    background-color: var(--lighter-grey);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .tag-row {
    position: relative;
    padding: 2px 4px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #B5B5B5;
    color: #FFFFFF;
    width: 100%;
    height: 25px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    -ms-border-radius: 3px;
  }
  .tag-row .content-tag{
    position: relative;
    flex: 1 auto;
    font-size: 0.8rem;
  }