.label-status {
    position: relative;
    display: inline-block;
    padding: 5px;
    border: none;
    border-radius: 4px;
    color: #333333;
    font-size: 9px;
}

.success {
    background-color: #0C9F23 !important;
    color: #ffffff !important;
}

.danger {
    background-color: #FD543E !important;
    color: #ffffff !important;
}

.default { background-color: #f0f0f0 !important; }

.warning { background-color: #FFD58D !important; }

.primary { 
    background-color: #345283 !important; 
    color: #ffffff !important;
}