.form-box .dropdown{
    width: 100%;
}
.custom-dropdown{
    position: relative;
}
.custom-dropdown-btn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    font-size: 0.65rem;
    color: #707070;
    width: 100%;
    cursor: pointer;
}
.custom-dropdown-menu{
    position: relative;
    width: 100%;
}
.custom-dropdown-menu ul.list-unstyled{
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
}
.custom-dropdown-menu ul.list-unstyled a{
    font-size: 0.65rem;
    padding: 4px 6px;
}
.custom-dropdown-menu input.custom-dropdown-filter-input{
    position: relative;
    display: block;
    margin: 0 5px;
    padding: 4px 1px;
    font-size: 0.65rem;
    border: thin solid #F0F0F0;
    box-sizing: border-box;
    width: calc(100% - 10px) ;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.custom-dropdown-menu .dropdown-item.active{
    background-color: rgba(12, 159, 35, 0.05);
    color: #0C9F23;
}
input.new-elm, input.new-elm::placeholder{
    padding: 5px !important;
    background-color: rgba(12, 159, 34, 0.068);
    color: #0C9F23;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
}