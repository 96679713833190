.header{
   position: fixed;
   display:flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   left: 0; top: 0;
   width: 100%;
   height: 75px;
   margin: 0; 
   padding: 0;
   z-index: 10;
}

.container-right{
   margin: 0;
   padding: 0 16px 0 133px;
   width: 100%;
   height: 75px;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   display: flex;
   justify-content: space-between;
   background-color: #FFFFFF;
   border-bottom: 1px solid rgba(0,0,0,.1);
   box-sizing: border-box;
}
.right{
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   justify-content: space-between;
   margin-right: 30px;
}

.usr-block{
   display: flex;
   margin-right: 8px;
   align-items: center;
   flex-direction: row;
   justify-content: space-between;
   cursor: pointer;
}

.title{
   margin: 0 10px 0 0;
   font-weight: bold !important;
   font-family: "Exo",Arial, Helvetica, sans-serif;
   text-transform: uppercase;
   color: #000000;
   font-size: 25px;
}

.bloc{
   display: block;
}

.label-user{
   margin: 0 10px;
   font-weight: 300;
   font-size: 0.9rem;
   font-family: "Exo",Arial, Helvetica, sans-serif;
   color: #707070;
}

.menu {
   margin: 0 10px;
}
.menu-display {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   width: 14px;
   height: 14px;
   border: 1px solid rgba(112, 112, 112, .1);
   padding: 2px;
}

.box {
   width: 6px;
   height: 6px;
   background-color: #B5B5B5;
   border-radius: 1px;
}

.box-hide {
   width: 4px;
   height: 4px;
   background-color: #B5B5B5;
   border-radius: 1px;
}

.line {
   width: 9px;
   color: #707070;
   border: 1px solid;
   margin-top: 2px;
   border-radius: 1px;
}

.btn-nav-menu{
   position: relative;
   cursor: pointer;
}

@media (max-width: 767.98px) {
   .header{
      position: fixed;
      left: 0; top: 0;
   }
   .container-right{
      padding: 0 16px;
   }
   .title{
      font-size: 1rem;
   }
}

 /* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
   .btn-nav-menu{
      display: none;
   }
}
@media (orientation: landscape) {
   
}