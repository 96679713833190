:root{
    --light-grey: #B5B5B5;
    --dark-grey: #707070;
    --font-size-small: .85rem;
    --font-size-smaller: .75rem;
    --font-size-smallest: .65rem;
    --font-size-medium: .95rem;
    --font-size-normal: 1rem;
    --font-size-big: 1.2rem;
    --font-size-bigger: 1.35rem;
    --font-size-biggest: 1.5rem;
    --blue: #177BD1;
    --error-color: rgb(250, 182, 170);
    --error-textcolor: rgb(185, 72, 52);
}
.EditableInput{
    position: relative;
    display: block;
}

/**
* RELATIVE TO THE INPUT
* {text} {number} {email} {date} {password} {tel} {time} {url} {search}
*/

.EditableInput .input-txt{
    position: relative;
    display: block;
    margin: 0;
    padding: 5px;
    width: 100%;
    border: .5px solid var(--light-grey);
    border-radius:3px;
    background: #FFFFFF;
    font-size: var(--font-size-small);
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -ms-border-radius:3px;
    -o-border-radius:3px;
}
.EditableInput .input-txt:disabled{
    border: none;
    background-color: var(--light-grey);
}
.EditableInput .valueRecord {
    position: relative;
    margin: 0; padding: 0;
    width: 100%;
    color: var(--dark-grey);
    font-size: var(--font-size-small);
}
.EditableInput .valueRecord.withIcon {
    position: relative;
    display: flex;
    align-items: center;
    padding:5px 5px 5px 0;
    width: 100%;
    height: auto;
}
.EditableInput .valueRecord.withIcon .icon-comp{
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin-left: 5px;
}
.EditableInput .valueRecord.withIcon .input-txt {
    position: absolute;
    /* z-index: -1; */
    top: 0; left: 0; right: 0; bottom: 0;
    padding-left: 25px;
    color: var(--dark-grey);
}
.EditableInput .valuePreview{
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: var(--font-size-smaller);
    font-weight: bold;
    color: var(--dark-grey);
}
.EditableInput .valuePreview.withIcon{
    padding-left: 0;
}
.EditableInput .valuePreview.withIcon .icon-comp{
    position: relative;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 20px;
    height: 25px;
}
.EditableInput .valuePreview.withIcon div.txt-preview{
    margin-left: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}
span.puce{
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    background-color: var(--blue);
    font-family: 'Exo';
    font-size: 0.6rem;
    font-weight: 900;
    color: #FFFFFF;
    border-radius: 50%;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.EditableInput .valueRecord.withIcon input.pl-30-10{
    padding-left: 30px;
}
.EditableInput .valuePreview.withIcon  .pl-30-10{
    padding-left: 10px;
}
.EditableInput.boxSizing-inherit {
    width: inherit !important;
}
.EditableInput.ei-error {
    color: var(--error-textcolor);
}

.EditableInput.ei-error .input-txt {
    border-color: var(--error-color);
}
.EditableInput.ei-error .valuePreview{
    color: var(--error-textcolor);
}
.EditableInput.ei-social-error {
    border-bottom: thin solid var(--error-color);
    color: var(--error-textcolor);
}
.EditableInput.ei-social-error::after {
    content: attr(content);
    position: absolute;
    display: inline-block;
    bottom: 0;
    right: 0;
    padding: 1px 2px 0 2px;
    font-size: 0.45rem;
    color: var(--error-textcolor);
    background-color: var(--error-color);
    border-radius: 2px 2px 0 0;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
}

.EditableInput.ei-social-error .input-txt {
    border-color: var(--error-color);
}
.EditableInput.ei-social-error .valuePreview{
    color: var(--error-textcolor);
}
.react-datepicker__input-container input[type="text"] {
    border: none;
    background-color: transparent;
    font-size: 0.7rem;
}
.react-datepicker-popper{
    z-index: 9999 !important;
}
.custom-date-picker{
    position: relative;
    font-weight: 900;
    color: var(--dark-grey);
    width: 100%;
    height: 100%;
}
.custom-date-picker::placeholder{
    font-weight: 900;
    color: var(--dark-grey);
}
.popover-body{
    padding: 0 !important;
    background-color: transparent !important;
    border: thin solid rgba(0,0,0,0) !important;
}
.react-datepicker__month-select, .react-datepicker__year-select{
    background-color: #335284;
    border: none;
    padding: 2px 3px !important;
    border-radius:3px;
    color: #FFFFFF;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -ms-border-radius:3px;
    -o-border-radius:3px;
}
.react-datepicker__month-select{
    width: 80px;
}
.react-datepicker__year-select{
    width: 60px;
}

.EditableInput .input-drop-down{
    text-align: left;
}
.EditableInput .input-drop-down .input-drop-down-btn{
    display: flex;
    align-items: center;
    height: 30px;
    width: 100%;
    border: solid 0.3px;
    border-radius: 20px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    outline: none;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.EditableInput .dropdown-toggle.btn.btn-primary:active{
    border-color: rgba(0,0,0,0.2) !important;
    outline: none !important;
}
.EditableInput .input-drop-down .input-drop-down-btn::after {
    display: none;
}
.EditableInput .text-color-input{
    color: #707070;
    font-size: 0.8rem;
}
.EditableInput a.dropdown-item{
    padding: 4px 15px;
}
.EditableInput .text-color-input.active{
    background-color: #335284;
    color: #FFFFFF;
    font-weight: 800;
}
.EditableInput .input-nane{
    color: #707070;
    font-size: 0.8rem;
}
.input-drop-down-menu.show{
    margin-top: 8px;
    width: 100%;
}
.EditableInput .input-arrow{

}

.btn-date{
    position: relative;
    background-color: transparent;
    border: none;
    font-size: 0.75rem;
    font-weight: 900;
    color: #707070;
}
.btn-date::placeholder{
    color: #707070;
}
.zi-9999{
    z-index: 9999 !important;
}
.popover.err{
    padding: 10px !important;
    background-color: rgb(255 231 231) !important;
}
.popover.err .arrow::after{
    border-top-color: rgb(255 231 231) !important;
}

.popover.err.top .arrow::after{
  border-top-color: rgb(255 231 231) !important;
}

.popover.err.bottom .arrow::after{
  border-bottom-color: rgb(255 231 231) !important;
}

.popover.err.left .arrow::after{
  border-left-color: rgb(255 231 231) !important;
}

.popover.err.right .arrow::after{
  border-right-color: rgb(255 231 231) !important;
}