@import url('https://fonts.googleapis.com/css?family=Exo&display=swap');
@import url('./App-responsive.css');
:root{
  --bank-color: #E29300;
  --fees-color: #FD543E;
  --bill-color: #B5B5B5;
  --social-color: #177BD1;
  --teasury-color: #AF4E07;
  --dashboard-color: #B5B5B5;
  --taxes-color: #09B6CC;
}
body{
  font-family: "Exo", sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.color-bank-feature{
  color: var(--bank-color);
}
.color-primary{
  color: #335284;
  outline: none !important;
}
.bg-color-bank{
  background-color: var(--bank-color);
}
.bg-white{ background-color: #FFFFFF; }
.bg-color-bank:hover, .bg-color-bank:active, .bg-color-bank:focus, .bg-color-bank:visited{
  background-color: var(--bank-color) !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.p-10{
  padding: 10px;
}
.pl-20{
  padding-left: 20px;
}
.pt-20{
  padding-top: 20px;
}

.pr-20{
  padding-right: 20px;
}
.ml-20{
  margin-left: 20px;
}
.ml-25{
  margin-left: 25px;
}

.ml-15{
  margin-left: 15px;
}

.ml-10{
  margin-left: 10px;
}

.mr-20{
  margin-right: 20px;
}
.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px;
}
.size-10 {
  width: 10px;
  height: 10px;
}
.size-20 {
  width: 20px;
  height: 20px;
}
.size-30 {
  width: 30px;
  height: 30px;
}
.size-40 {
  width: 40px !important;
  height: 40px !important;
}
.size-50 {
  width: 50px;
  height: 50px;
}
.separator{
  border-bottom: 0.5px solid rgba(152, 152, 152, .3);
}
.text-center-h{
  text-align: center;
}
.text-right-h{
  text-align: right;
}
.bg-light-grey{
  background-color: #F0F0F0;
}
.text-left-h{
  text-align: left;
}

.font-size-table-data{
  font-size: 0.9rem;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.deliver-style{
  font-size: 0.9rem;
  font-weight: 600;
  color:#000000;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.mr-pag-15{
  margin-right: 15px;
}

.pr-10{
  padding-right: 10px;
}

.mt-pag-15{
  margin-top: 15px;
}
button, input, select, button:focus, input:focus, select:focus{
  outline: none !important;
}
.pl-10{
  padding-left: 10px !important; 
}
.pl-15{
  padding-left: 15px;
}
.flex-1-auto{ flex: 1 auto !important;}
.bold{ font-weight: bold; }
.font-size-12{ font-size: 1rem; }
.h-separator{ 
  position: relative; 
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 0.5px solid rgba(112,112,112, .3);
}
.w-full{
  width: 100%;
}
.h-full{
  height: 100%;
}
.flex-1-auto{
  flex: 1 auto !important;
}
.wh-full{
  width: 100%;
  height: 100%;
}
.overflow-y-auto{
  overflow-y: auto;
}
.color-white {
  color: #ffffff;
  font-size: 12px;
}
.hidden{
  display: none;
}
.pb-50{
  padding-bottom: 50px;
}
.custom-alert{
  position: relative;
  display: inline-block;
  margin-left: 10px;
  padding: 5px 10px;
  height: 30px;
  line-height: 25px;
  box-sizing: border-box;
  font-size: 0.7rem;
  border-radius: 4px;
}
.w100{ width: 100% !important; }
.display-block{ display: block !important; }
.h-30{ height: 30px !important; }
.h-30-strict{ height: 30px !important; box-sizing: border-box !important;}
.h-inherit-strict{
  height: inherit !important; 
  box-sizing: border-box !important; 
  padding: 0 !important; 
  margin: 0 !important;
}
.no-input-border { border: none !important }
.display-block{ display: block !important; }
.rotate-z-180{
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
}
.row-justify-between{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

 /* Custom Toastr style  */
.toastr.rrt-success {
  background: rgb(0, 114, 6) !important;
  color: #FFFFFF !important;
}
.toastr.rrt-warning {
  background: rgb(243, 176, 33)!important;
  color: #FFFFFF !important;
}
.toastr.rrt-error {
  background: rgb(255, 62, 62)!important;
  color: #FFFFFF !important;
}
.toastr.rrt-info {
  background: #335284!important;
  color: #FFFFFF !important;
}
.redux-toastr .toastr {
  box-shadow: none !important;
  opacity: 1 !important;
}
div.close-toastr.toastr-control {
  opacity: 1 !important;
}
div.close-toastr.toastr-control span {
  top: 20px !important;
  color: #FFFFFF !important;
  font-weight: 900 !important;
  font-size: 0.7rem !important;
  opacity: 1 !important;
}
.bg-light-gray{
  background-color: #F0F0F0 !important;
}

@media (max-width: 767.98px) {
  .sm-hidden{
    display: none !important;
  }
  .top-layer{
    z-index: 99999 !important;
  }
}
@media (min-width: 768px) { 
  .lg-hidden{
    display: none;
  }
}
@media screen and (orientation: landscape) {
  /* .sm-hidden{
    display: none !important;
  } */
}
.w100{ width: 100% !important; }
.w-500{ width: 500px; }
.w-450{ width: 450px; }
.w-5-percent{ width: 5%; }
.w-10-percent{ width: 10%; }
.w-15-percent{ width: 15%; }
.w-20-percent{ width: 20%; }
.w-25-percent{ width: 25%; }
.w-30-percent{ width: 30%; }
.w-35-percent{ width: 35%; }
.w-40-percent{ width: 40%; }
.w-45-percent{ width: 45%; }
.w-50-percent{ width: 50%; }
.w-55-percent{ width: 55%; }
.w-60-percent{ width: 60%; }
.w-65-percent{ width: 65%; }
.w-70-percent{ width: 70%; }
.w-75-percent{ width: 75%; }
.w-80-percent{ width: 80%; }
.w-85-percent{ width: 85%; }
.w-90-percent{ width: 90%; }
.w-95-percent{ width: 95%; }
.w-100-percent{ width: 100%; }
.w-100{ width: 100px !important; }
.display-block{ display: block !important; }
.no-wrap{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.h-30{ height: 30px !important; }
.h-30-strict{ height: 30px !important; box-sizing: border-box !important;}
.h-inherit-strict{
  height: inherit !important; 
  box-sizing: border-box !important; 
  padding: 0 !important; 
  margin: 0 !important;
}
.mlr-1{
  margin-left: 1px; 
  margin-right: 1px; 
}
.mt-50{
  margin-top: 50px;
}
.mt-130{
  margin-top: 130px;
}
.text-elipsis{
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: var(--max-width);
}
.no-input-border { border: none !important }
.display-block{ display: block !important; }
.table-no-wrap { white-space: nowrap; }
.txt-thin{ font-weight: 200 !important; }
.txt-bold{ font-weight: bold !important; }
.txt-small{ font-size: 0.8rem !important; }
.txt-smaller { font-size: 0.65rem !important; }
.txt-smallest { font-size: 0.55rem !important; }
.txt-medium{ font-size: 1rem !important; text-transform: capitalize;}
.txt-big{ font-size: 1.3rem !important; }
.txt-grey{ color: #707070 !important; }

.bank-color{ color: var(--bank-color) !important; }
.fees-color{ color: var(--fees-color) !important; }
.bill-color{ color: var(--bill-color) !important; }
.social-color{ color: var(--social-color) !important; }
.teasury-color{ color: var(--teasury-color) !important; }

.border-dashed{
  border-width: 0.5px;
  border-style: dashed;
  border-color: rgb(230, 230, 230);
}
.border-top-dashed{
  border-top-width: 0.5px;
  border-top-style: dashed;
  border-top-color: rgb(230, 230, 230);
}
.border-left-dashed{
  border-left-width: 0.5px;
  border-left-style: dashed;
  border-left-color: rgb(230, 230, 230);
}
.border-right-dashed{
  border-right-width: 0.5px;
  border-right-style: dashed;
  border-right-color: rgb(230, 230, 230);
}
.border-bottom-dashed{
  border-bottom-width: 0.5px;
  border-bottom-style: dashed;
  border-bottom-color: rgb(230, 230, 230);
}

.bordered{
  border: 0.5px solid rgb(230, 230, 230);
}
.bordered-top{
  border-top: 0.5px solid rgb(230, 230, 230);
}
.bordered-left{
  border-left: 0.5px solid rgb(230, 230, 230);
}
.bordered-right{
  border-right: 0.5px solid rgb(230, 230, 230);
}
.bordered-bottom{
  border-bottom: 0.5px solid rgb(230, 230, 230);
}

.input-align-left{
  text-align: left;
}

.input-align-center{
  text-align: center;
}

.input-align-right{
  text-align: right;
}

.h-25{ height: 25px !important;}
.h-30{ height: 30px !important;}
.overflow-hidden{ overflow: hidden; }
.overflow-x-hidden{ overflow-x: hidden !important; }
.overflow-y-hidden{ overflow-y: hidden !important; }
.overflow-x-visible{ overflow-x: visible !important; }
.overflow-y-visible{ overflow-y: visible !important; }
.cursor-pointer{ cursor: pointer; }
.flex-1{ flex: 1; }
.flex-2{ flex: 2; }
.flex-3{ flex: 3; }
.flex-4{ flex: 4; }
.flex-5{ flex: 5; }
.flex-6{ flex: 6; }


.min-width-medium{ min-width: 320px !important; }
.dropdown-menu.show { width: 100%; }

@media (max-width: 991.98px) {
  .txt-medium{ font-size: 0.8rem; }
  .txt-small{ font-size: 0.65rem; }
  .txt-smaller { font-size: 0.6rem }
  .txt-big{ font-size: 1rem; }
}

.txt-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}
.position-relative {
  position: relative !important;
}
.tag-ellipsis{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}