.detail-container {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
}
.detail-container .close-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.detail-container .header-detail-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
    padding: 0 10px;
    height: 40px;
}
.header-detail-container .detail-container-title {
    font-size: 1rem;
    font-weight: 800;
    color: #707070;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    
}