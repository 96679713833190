.minimized-attachment-preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #CBCBCB;
    height: 180px;
    border-radius: 5px;
}

.minimized-attachment-preview img {
    display: inline-block;
}
img.width-auto{
    width: auto;
    max-width: calc(100% - 10px);
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
}
img.height-auto{
    height: auto;
    max-height: calc(100% - 10px);
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
}
img.width-height-auto{
    max-width: calc(100% - 10px);
    max-height: calc(100% - 10px);
    width: auto;
    height: auto;
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out; 
}
img.opacity-0 { 
    opacity: 0;
}
.box-loader{
    z-index: 99;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-color: transparent;
    height: 50px;
    line-height: 50px;
    padding: 5px;
}
.box-loader .b-load{
    position: relative;
    width: 25px;
    height: 25px;
    animation: b-spin 4s ease-in-out infinite;
    -webkit-animation: b-spin 4s ease-in-out infinite;
}

.minimized-attachment-preview, .minimized-attachment-preview img, .minimized-attachment-preview object #root {
    cursor: pointer;
}
.overlay-box{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 99;
}