.bank-drop-down {
    text-align: left;
}
.bank-drop-down-menu {
    margin-top: 5px;
    font-size: 12px;
    max-width: 100%;
}
.bank-drop-down-btn {
    display: flex;
    align-items: center;
    height: 30px;
    width: 100%;
    border: solid 0.3px;
    border-radius: 20px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
}
.bank-arrow {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bank-drop-down-btn::after {
    display: none;
}
.text-color-bank {
    color: #707070;
}
.dropdown-item:active {
    background-color: transparent;
}
.bank-item-wrapped{
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 115px;
    font-size: 0.75rem;
    overflow: hidden;
    display: inline-block;
}
@media (max-width: 575.98px) {
    .bank-item-wrapped{
        font-size: 0.65rem;
    }
}