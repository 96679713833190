.displayContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    margin: 0;
    padding: 0;
    width: 350px;
    background-color: #FFFFFF;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 50px;
}
.displayContainer .headerDisplayContainer{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 50px;
}
.headerDisplayContainer .titleStatement {
    font-size: 0.8rem;
    font-weight: bold !important;
    color: #707070;
}

.headerDisplayContainer button.btn-close {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    /* margin-right: 15px; */
}
.headerDisplayContainer .customHeaderContent {
    color: #707070;
    position: relative;
    flex: 1 auto;
}
.displayContainer .contentDisplayContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50px; bottom: 0; left: 0; right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 auto;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .displayContainer{
        position: fixed;
        top: 75px; right: 0; bottom: 50px; left: 0;
        width: 100vw;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .displayContainer{
        position: fixed;
        top: 75px; right: 0; bottom: 0;
        box-shadow: -3px 0 5px rgba(0,0,0,0.1);
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .displayContainer{
        position: absolute;
        z-index: 18;
        top: 0; right: 0; bottom: 0;
        box-shadow: -3px 0 5px rgba(0,0,0,0.1);
    }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    
}