.item-list-loader{
    position: relative;
    display: flex;
    height: 55px;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
    background: #f8f8f8;
}


.logo-org-loader{
    position: relative;
    display: flex;
    margin: 0 10px 0 0;
    padding: 5px;
    width: 50px;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 3px;
    animation:loader-anim 1.5s;
    -moz-animation: loader-anim 1.5s infinite; /* Firefox */
    -webkit-animation:loader-anim 1.5s infinite; /* Safari and Chrome */
}

.descript-org-loader{
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 5px 0 0;
    padding: 5px;
    width: 100%;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 3px;
    animation:loader-anim 1.5s;
    -moz-animation: loader-anim 1.5s infinite; /* Firefox */
    -webkit-animation:loader-anim 1.5s infinite; /* Safari and Chrome */
}

.org-loading-text {
    position: relative;
    text-align: center;
    overflow: hidden;
    font-size: 8px;
    margin-top: -55px;
    width: 11em;
}


@-moz-keyframes loader-anim /* Firefox */
{
    0%   {background: #F0F0F0;}
    50%  {background: #FAFAFA;}
    100%   {background: #F0F0F0;}
}
 
@-webkit-keyframes loader-anim
{
    0%   {background: #F0F0F0;}
    50%  {background: #FAFAFA;}
    100%   {background: #F0F0F0;}
}
