.user-views {
    position: relative;
    display: flex;
    flex: 1 auto;
    height: 100%;
}
.invitation-form {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
.btn-user-add, .btn-user-add:hover, .btn-user-add:active, .btn-user-add:visited {
    background-color: #335284;
    height: 32px;
    border-radius: 4px;
    color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
    outline: none !important;
    border: none;
}
.tag-area{
    position: relative;
    padding: 5px;
    background-color: #F5F5F5;
    border-radius: 4px;
    min-height: 150px;
}
.btn-cancel, .btn-cancel:hover, .btn-cancel:active, .btn-cancel:focus, .btn-cancel.btn.btn-primary:active .btn-cancel:visited {
    display: block;
    background-color: #FFFFFF !important;
    height: 32px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    border: 0.5px solid #B5B5B5 !important;
    color: #B5B5B5 !important;
    outline: none !important;
}

.btn-color-blue {
    background-color: #335284;
}

.btn-color-blue:hover {
    background-color: #335284 !important;
    cursor: pointer;
}

.btn-submit, .btn-submit:hover, .btn-submit:active, .btn-submit:visited{
    display: block;
    background-color: #335284 !important;
    height: 32px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    color: #FFFFFF;
    outline: none !important;
    border: none;
    color: #FFFFFF;
}
.email-text{
    color: #177BD1;
}

.user-name-width {
    width: 25%;
}
.user-surname-width {
    width: 25%;
}
.user-email-width {
    width: 30%;
}
.user-role-width {
    width: 20%;
}

@media (min-width: 767.98px) {
    .user-surname-width {
        width: 20%;
    }
    .user-role-width {
        width: 15%;
    } 
    .user-status-width {
        width: 10%;
    }
}
