/* :root{
    --primary-color: #177BD1;
    --secondary-color: #b5b5b5;
    --bgnav-color: #FFFFFF;
    --hovernav-color: transparent;
} */
a{ text-decoration: none; }
.sub-navlink{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2px 0;
    height: 50px;
    font-size: 0.75rem;
    background-color: var(--bgnav-color);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
ul.sub-navlink-container {
    position: relative;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding:0;
    padding-left: 10px;
    flex: 1 auto;
}

ul.sub-navlink-container.mp-15 {
    position: relative;
    display: block;
    list-style: none;
    margin: 0 15px;
    padding:0;
}

ul.sub-navlink-container > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 5px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.sub-navlink-container > li:hover{
    background-color: var(--hovernav-color);
}

ul.sub-navlink-container > li:not(:first-child) {
    margin-left: 15px;
}

ul.sub-navlink-container > li::before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background-color: var(--primary-color);
    opacity: 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.sub-navlink-container > li.active::before {
    height: 1.5px;
    opacity: 1;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

a.sub-navlink-item-item {
    position: relative;
    display: flex;
    cursor: pointer;
    color: var(--secondary-color);
    /* margin-bottom: 15px; */
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none !important;
    text-overflow: ellipsis;
}

a.sub-navlink-item-item:hover {
    color: var(--primary-color);
    text-decoration: none !important;
}

a.sub-navlink-active {
    color: var(--primary-color);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.sub-navlink-item-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    margin-left: 5px;
    padding: 5px;
    border-radius: 50%;
}