.bill-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.btn-plus.bill {
    border: 1px solid #0C9F23;
    background-color: #0C9F23;
}

.edit-bill-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
}

.bill-content-box{ 
    display: flex;
    flex-direction: column;
    background-color: #FDFDFD;
    flex: 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.btn-cancel-bill{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #FDFDFD;
    border: none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    color: #B5B5B5;
}

.btn-save-bill{
    background-color: rgba(0, 0, 0, 0);
    border: thin solid #0C9F23;
    color: #707070;
    font-size: 0.75rem;
    height: 30px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.btn-plus-bill{
    width: 30px;
    height: 30px;
    background-color: #0C9F23;
    border: none;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.btn-edit-bill{
    color: #707070;
    font-size: 0.75rem;
    width: 30px;
    height: 30px;
    background-color: #FDFDFD;
    border: none;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.total-ttc{ font-weight: bold; color: #707070; }

.box-infos{
    position: relative;
    margin: 0;
    padding: 0;
    width: 290px;
}
.logo-area{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    overflow: hidden;
    background-color: #F0F0F0;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.label-title{
    color: #A7A7A7;
    font-weight: bold;
    font-size: 0.9rem;
}
.label-desc{
    color: #A7A7A7;
    font-weight: normal;
    font-size: 0.7rem;
}
.form-box{
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 20px;
}
.form-box input:not([type="checkbox"]), .form-box select{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    padding-left: 5px;
    width: 100%;
    height: inherit;
    border: none;
    background-color: transparent;
    font-size: 0.65rem;
    color: #707070;
}
.form-box .form-inline{
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex: 1;
}
.form-box input::placeholder{
    font-size: 0.65rem;
    color: #707070;
}
.form-box .react-datepicker-wrapper{
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 20px !important;
}
.form-box .react-datepicker-wrapper input{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}
.txt-title{
    color: #707070;
    font-weight: bold;
    text-transform: uppercase;
}

.bill-display .dashed{
    border-width: 0.5px;
    border-style: dashed;
    border-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.bill-display .top-dashed{
    border-top-width: 0.5px;
    border-top-style: dashed;
    border-top-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .bill-display .left-dashed{
    border-left-width: 0.5px;
    border-left-style: dashed;
    border-left-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .bill-display .right-dashed{
    border-right-width: 0.5px;
    border-right-style: dashed;
    border-right-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .bill-display .bottom-dashed{
    border-bottom-width: 0.5px;
    border-bottom-style: dashed;
    border-bottom-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

.bill-display:hover .dashed{
    border-color: rgba(197, 197, 197, 1);
}
.bill-display:hover .left-dashed{
    border-left-color: rgba(197, 197, 197, 1);
}
.bill-display:hover .bottom-dashed{
    border-bottom-color: rgba(197, 197, 197, 1);
}
.bill-display:hover .right-dashed{
    border-right-color: rgba(197, 197, 197, 1);
}
.bill-display:hover .top-dashed{
    border-top-color: rgba(197, 197, 197, 1);
}

.table-bill{
    position: relative;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}


.table-bill thead tr {
    background-color: #DEDEDE;
    border-radius: 5px;
}

.table-bill thead tr th {
    font-size: 0.75rem;
    font-weight: bold;
    color: #707070;
    padding: 0 7px;
    height: 35px;
    overflow: hidden;
}

.table-bill tbody tr{
    position: relative;
}

.table-bill tr th span, .table-bill tr td {
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}
.plr-10{
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.btn-add-row {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: none;
    background-color: #0C9F23;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 0.7rem;
}
.text-total-row{
    font-size: 0.75rem;
    font-weight: bold;
    color: #707070;
    align-items: center;
}
.label-title{
    font-size: 0.85rem;
    font-weight: 300;
    color: #707070;
}
.text-amount{
    font-size: 0.9rem;
    font-weight: bold;
    color: #707070;
}
.bill-primary-color{ color: #0C9F23 }
.bg-bill-primary-light{ background-color: rgba(12, 159, 35, 0.05); }
.remove-row-btn{
    position: absolute;
    top: 1px;
    left: -26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    border: none;
    background-color: #FFD3D3;
    cursor: pointer;
    z-index: 999;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}
.remove-row-btn.fades{
    position: absolute;
    top: 1px;
    left: -26px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    border: none;
    background-color: #FFD3D3;
    cursor: pointer;
    z-index: 999;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.table-bill tbody tr {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.table-bill tbody tr:hover .remove-row-btn, .bill-footer .form-box:hover .remove-row-btn {
    left: -4px;
}
.table-bill tbody tr:hover .remove-row-btn.fades, .bill-footer .form-box:hover .remove-row-btn.fades {
    left: -4px;
    opacity: 1;
}

.bill-footer .form-box{
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}


.pl-push-hover {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.table-bill tbody tr:hover .pl-push-hover, .bill-footer .form-box:hover .pl-push-hover {
    padding-left: 25px;
}
.alert.bill-alert{
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 25px;
    font-size: 0.7rem;
    margin-bottom: 0;
    background-color: rgba(255,0,0,0.1);
    color: #FF0000;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.alert.bill-alert button.close{
    margin-left: 5px;
    color: #FF0000;
}
.form-box.msg-error, tr.msg-error {
    background-color: rgba(255,0,0,0.1);
}
.msg-table-error{
    font-size: 0.7rem;
    color: #FF0000;
}
.customer{
    position: relative;
    margin-top: 130px;
}
.bill-header-right{
    position: relative;
}
.w-unit-mesure{ width: 100px; }
.w-qty{ width: 50px; }
.w-pu{ width: 125px; }
.w-vat{ width: 80px; }
.w-pt{ width: 125px; }
.hoverlay-controls{
    display: none;
}
.font-small{ font-size: 0.65rem; }
.hoverlay-controls.offset{
    z-index: 99;
    position: fixed;
    top: 90px;
    left: 135px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F8F8F8;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.sm-txt-light{
    font-size: 0.65rem;
    color: #707070;
}

@media (max-width: 575.98px) { 
    .bill-header-right{
        margin-top: 10px;
    }
    .box-infos{
        width: 100%;
    }
    .customer{
        margin-top: 10px;
    }
    .table-bill thead tr th {
        font-size: 0.65rem;
        font-weight: bold;
        color: #707070;
        padding: 0 4px;
        overflow: hidden;
    }
    
    .table-bill tr th span, .table-bill tr td {
        max-width: 100px;
    }
    .alert.bill-alert{
        font-size: 0.55rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
    }
    .hoverlay-controls.offset{
        left: 15px;
        right: 15px;
    }

}

@media (max-width: 767.98px) { 
    .hoverlay-controls.offset{
        left: 15px;
        right: 15px;
    }
}

