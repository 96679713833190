/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .xsmall-vw-hidden{
        display: none !important;
    }
    .xsmall-vw-show{
        display: initial !important;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .small-vw-hidden{
        display: none !important;
    }
    .small-vw-show{
        display: initial !important;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .medium-vw-hidden{
        display: none !important;
    }
    .medium-vw-show{
        display: initial !important;
    }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    .large-vw-hidden{
        display: none !important;
    }
    .large-vw-show{
        display: initial !important;
    }
}