.col-display{
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1 auto;
}
.bg-dark-grey {
    background-color: #707070;
}
.round-1{
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
}
.round-2{
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.round-3{
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.round-4{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.round-5{
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.btn-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.w-140{ width: 140px; }
.content-details{
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    flex: 1 auto;
    overflow: hidden;
}
.title-content-section {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 10px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #707070;
}
.row-detail-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    height: 40px;
}
.options-control-header{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.head-option-left button span {
    position: relative;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 575.98px) {
    .payslip-staff-width {
        width: 40%;
    }
    
    .payslip-attachment-width {
        width: 40%;
    }
    .payslip-status-width {
        width: 25%;
    }
 }
@media (max-width: 768.98px){
    .payslip-period-width {
        width: 50%;
    }
    .payslip-payroll-width {
        width: 50%;
    }
}
@media(min-width:575.98px) and (max-width:767.98px) {
    .payslip-staff-width {
        width: 60%;
    }
    .payslip-attachment-width {
        width: 30%;
    }
    .payslip-status-width {
        width: 20%;
    }
}

@media (min-width: 767.98px) {
    .payslip-period-width {
        width: 25%;
    }
    .payslip-payroll-width {
        width: 25%;
    }
    .payslip-declaration-width {
        width: 25%;
    }
    .payslip-tax-width {
        width: 25%;
    }

    .payslip-staff-width {
        width: 60%;
    }
    .payslip-attachment-width {
        width: 30%;
    }
    .payslip-status-width {
        width: 20%;
    }
}
@media (min-width:991.98px) {
    .payslip-staff-width {
        width: 35%;
    }
    .payslip-attachment-width {
        width: 12%;
    }
    .payslip-absence-width {
        width: 20%;
    }
    .payslip-status-width {
        width: 9%;
    }
    .payslip-hsupp-width {
        width: 20%;
    }
    .payslip-allowance-width {
        width: 10%;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .options-control-header{
        flex-direction: column-reverse;
        justify-content: center;
    }
    .options-control-header .head-option-left{
        flex-direction: column;
    }
    .options-control-header .head-option-left button:not(:first-child){
        margin: 5px 0 !important;
        padding: 5px 0;
        width: 100%;
    }

    .options-control-header .head-option-left button:first-child{
        margin: 10px 0 5px 0 !important;
        padding: 5px 0;
        width: 100%;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
    .options-control-header .ct-search{
        flex: 1 auto !important;
    }
}
.btn-disabled{
    cursor: not-allowed !important;
    background-color: #CCCCCC !important;
}

.drop-down-div {
    width: 200px;
    margin-left: 16px;
}
.drop-down-txt {
    margin-top: 5px;
    font-size: 12px;
}
.drop-down-btn {
    text-align: left;
    height: 30px;
    width: 100%;
    border: solid 0.3px;
    border-radius: 3px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
}
.drop-down-btn::after {
    margin-right: 0.745em;
    margin-top: .5em;
    vertical-align: .255em;
    content: "";
    float: right;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.item-line .active {
    background-color: rgba(23, 123, 209, 0.1) !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .drop-down-div {
        width: 100% !important;
        margin-left: 0;
    }
    .drop-down-btn{
        padding-left: 10px !important;
    }
    .dropdown-menu{
        width: 100% !important;
    }
    .dropdown-menu .dropdown-item {
        padding-left: 10px !important;
    }
}
    
