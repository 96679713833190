.card-bank-preview{
    background-color: #F8F8F8;
    padding: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
}
.card-bank-preview:hover{
    background-color: rgba(0,0,0, .1);
}
.card-bank-preview.avtive{
    background-color: rgba(23,123,209,.05);
}
.card-bank-preview.avtive:hover{
    background-color: rgba(23,123,209,.1);
}