@import url(https://fonts.googleapis.com/css?family=Exo&display=swap);
html {
    
}
/* Let's get this party started */
::-webkit-scrollbar {
    width: 6px;
}
/* Btton control */
::-webkit-scrollbar-button{

}
/* Track */
::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    background-color: 0.5px solid rgba(34, 34, 34, 0.2);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: rgba(34, 34, 34, 0.4); 
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(34, 34, 34, 0); 
}
body {
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: inherit;
}
.loader{
    display: none
}

.loader.visible{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    border: 3px solid rgba(0,0,0,.1);
    border-top: 3px solid #177BD1;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    -webkit-animation: spin 1s ease-in-out infinite;
            animation: spin 1s ease-in-out infinite;
}

@-webkit-keyframes spin {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(720deg)
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(720deg)
    }
}

.loader-create-staff.visible .half-circle{
    width: 20px;
    height: 20px;
}

.auth{
    position: absolute;
    top: 0; left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #177BD1;
}
.auth .box-auth{
    width: 500px;
    min-height: 300px;
    padding: 20px;
    border-radius: 5px;
    /* border: thin solid #EFA959; */
    background-color: #FFFFFF;
}

.auth .h-40{
    height: 40px !important;
}

.auth .create-account{
    font-size: 0.7rem;
    font-weight: 700;
    color: #177BD1;
    cursor: pointer;
}

@media (max-width: 575.98px) { 
    .auth .box-auth{
        width: calc(100vw - 20px) !important;
    }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .xsmall-vw-hidden{
        display: none !important;
    }
    .xsmall-vw-show{
        display: inline !important;
        display: initial !important;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .small-vw-hidden{
        display: none !important;
    }
    .small-vw-show{
        display: inline !important;
        display: initial !important;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .medium-vw-hidden{
        display: none !important;
    }
    .medium-vw-show{
        display: inline !important;
        display: initial !important;
    }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    .large-vw-hidden{
        display: none !important;
    }
    .large-vw-show{
        display: inline !important;
        display: initial !important;
    }
}
:root{
  --bank-color: #E29300;
  --fees-color: #FD543E;
  --bill-color: #B5B5B5;
  --social-color: #177BD1;
  --teasury-color: #AF4E07;
  --dashboard-color: #B5B5B5;
  --taxes-color: #09B6CC;
}
body{
  font-family: "Exo", sans-serif;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.color-bank-feature{
  color: #E29300;
  color: var(--bank-color);
}
.color-primary{
  color: #335284;
  outline: none !important;
}
.bg-color-bank{
  background-color: #E29300;
  background-color: var(--bank-color);
}
.bg-white{ background-color: #FFFFFF; }
.bg-color-bank:hover, .bg-color-bank:active, .bg-color-bank:focus, .bg-color-bank:visited{
  background-color: #E29300 !important;
  background-color: var(--bank-color) !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.p-10{
  padding: 10px;
}
.pl-20{
  padding-left: 20px;
}
.pt-20{
  padding-top: 20px;
}

.pr-20{
  padding-right: 20px;
}
.ml-20{
  margin-left: 20px;
}
.ml-25{
  margin-left: 25px;
}

.ml-15{
  margin-left: 15px;
}

.ml-10{
  margin-left: 10px;
}

.mr-20{
  margin-right: 20px;
}
.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px;
}
.size-10 {
  width: 10px;
  height: 10px;
}
.size-20 {
  width: 20px;
  height: 20px;
}
.size-30 {
  width: 30px;
  height: 30px;
}
.size-40 {
  width: 40px !important;
  height: 40px !important;
}
.size-50 {
  width: 50px;
  height: 50px;
}
.separator{
  border-bottom: 0.5px solid rgba(152, 152, 152, .3);
}
.text-center-h{
  text-align: center;
}
.text-right-h{
  text-align: right;
}
.bg-light-grey{
  background-color: #F0F0F0;
}
.text-left-h{
  text-align: left;
}

.font-size-table-data{
  font-size: 0.9rem;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.deliver-style{
  font-size: 0.9rem;
  font-weight: 600;
  color:#000000;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.mr-pag-15{
  margin-right: 15px;
}

.pr-10{
  padding-right: 10px;
}

.mt-pag-15{
  margin-top: 15px;
}
button, input, select, button:focus, input:focus, select:focus{
  outline: none !important;
}
.pl-10{
  padding-left: 10px !important; 
}
.pl-15{
  padding-left: 15px;
}
.flex-1-auto{ flex: 1 1 auto !important;}
.bold{ font-weight: bold; }
.font-size-12{ font-size: 1rem; }
.h-separator{ 
  position: relative; 
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 0.5px solid rgba(112,112,112, .3);
}
.w-full{
  width: 100%;
}
.h-full{
  height: 100%;
}
.flex-1-auto{
  flex: 1 1 auto !important;
}
.wh-full{
  width: 100%;
  height: 100%;
}
.overflow-y-auto{
  overflow-y: auto;
}
.color-white {
  color: #ffffff;
  font-size: 12px;
}
.hidden{
  display: none;
}
.pb-50{
  padding-bottom: 50px;
}
.custom-alert{
  position: relative;
  display: inline-block;
  margin-left: 10px;
  padding: 5px 10px;
  height: 30px;
  line-height: 25px;
  box-sizing: border-box;
  font-size: 0.7rem;
  border-radius: 4px;
}
.w100{ width: 100% !important; }
.display-block{ display: block !important; }
.h-30{ height: 30px !important; }
.h-30-strict{ height: 30px !important; box-sizing: border-box !important;}
.h-inherit-strict{
  height: inherit !important; 
  box-sizing: border-box !important; 
  padding: 0 !important; 
  margin: 0 !important;
}
.no-input-border { border: none !important }
.display-block{ display: block !important; }
.rotate-z-180{
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
}
.row-justify-between{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

 /* Custom Toastr style  */
.toastr.rrt-success {
  background: rgb(0, 114, 6) !important;
  color: #FFFFFF !important;
}
.toastr.rrt-warning {
  background: rgb(243, 176, 33)!important;
  color: #FFFFFF !important;
}
.toastr.rrt-error {
  background: rgb(255, 62, 62)!important;
  color: #FFFFFF !important;
}
.toastr.rrt-info {
  background: #335284!important;
  color: #FFFFFF !important;
}
.redux-toastr .toastr {
  box-shadow: none !important;
  opacity: 1 !important;
}
div.close-toastr.toastr-control {
  opacity: 1 !important;
}
div.close-toastr.toastr-control span {
  top: 20px !important;
  color: #FFFFFF !important;
  font-weight: 900 !important;
  font-size: 0.7rem !important;
  opacity: 1 !important;
}
.bg-light-gray{
  background-color: #F0F0F0 !important;
}

@media (max-width: 767.98px) {
  .sm-hidden{
    display: none !important;
  }
  .top-layer{
    z-index: 99999 !important;
  }
}
@media (min-width: 768px) { 
  .lg-hidden{
    display: none;
  }
}
@media screen and (orientation: landscape) {
  /* .sm-hidden{
    display: none !important;
  } */
}
.w100{ width: 100% !important; }
.w-500{ width: 500px; }
.w-450{ width: 450px; }
.w-5-percent{ width: 5%; }
.w-10-percent{ width: 10%; }
.w-15-percent{ width: 15%; }
.w-20-percent{ width: 20%; }
.w-25-percent{ width: 25%; }
.w-30-percent{ width: 30%; }
.w-35-percent{ width: 35%; }
.w-40-percent{ width: 40%; }
.w-45-percent{ width: 45%; }
.w-50-percent{ width: 50%; }
.w-55-percent{ width: 55%; }
.w-60-percent{ width: 60%; }
.w-65-percent{ width: 65%; }
.w-70-percent{ width: 70%; }
.w-75-percent{ width: 75%; }
.w-80-percent{ width: 80%; }
.w-85-percent{ width: 85%; }
.w-90-percent{ width: 90%; }
.w-95-percent{ width: 95%; }
.w-100-percent{ width: 100%; }
.w-100{ width: 100px !important; }
.display-block{ display: block !important; }
.no-wrap{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.h-30{ height: 30px !important; }
.h-30-strict{ height: 30px !important; box-sizing: border-box !important;}
.h-inherit-strict{
  height: inherit !important; 
  box-sizing: border-box !important; 
  padding: 0 !important; 
  margin: 0 !important;
}
.mlr-1{
  margin-left: 1px; 
  margin-right: 1px; 
}
.mt-50{
  margin-top: 50px;
}
.mt-130{
  margin-top: 130px;
}
.text-elipsis{
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: var(--max-width);
}
.no-input-border { border: none !important }
.display-block{ display: block !important; }
.table-no-wrap { white-space: nowrap; }
.txt-thin{ font-weight: 200 !important; }
.txt-bold{ font-weight: bold !important; }
.txt-small{ font-size: 0.8rem !important; }
.txt-smaller { font-size: 0.65rem !important; }
.txt-smallest { font-size: 0.55rem !important; }
.txt-medium{ font-size: 1rem !important; text-transform: capitalize;}
.txt-big{ font-size: 1.3rem !important; }
.txt-grey{ color: #707070 !important; }

.bank-color{ color: #E29300 !important; color: var(--bank-color) !important; }
.fees-color{ color: #FD543E !important; color: var(--fees-color) !important; }
.bill-color{ color: #B5B5B5 !important; color: var(--bill-color) !important; }
.social-color{ color: #177BD1 !important; color: var(--social-color) !important; }
.teasury-color{ color: #AF4E07 !important; color: var(--teasury-color) !important; }

.border-dashed{
  border-width: 0.5px;
  border-style: dashed;
  border-color: rgb(230, 230, 230);
}
.border-top-dashed{
  border-top-width: 0.5px;
  border-top-style: dashed;
  border-top-color: rgb(230, 230, 230);
}
.border-left-dashed{
  border-left-width: 0.5px;
  border-left-style: dashed;
  border-left-color: rgb(230, 230, 230);
}
.border-right-dashed{
  border-right-width: 0.5px;
  border-right-style: dashed;
  border-right-color: rgb(230, 230, 230);
}
.border-bottom-dashed{
  border-bottom-width: 0.5px;
  border-bottom-style: dashed;
  border-bottom-color: rgb(230, 230, 230);
}

.bordered{
  border: 0.5px solid rgb(230, 230, 230);
}
.bordered-top{
  border-top: 0.5px solid rgb(230, 230, 230);
}
.bordered-left{
  border-left: 0.5px solid rgb(230, 230, 230);
}
.bordered-right{
  border-right: 0.5px solid rgb(230, 230, 230);
}
.bordered-bottom{
  border-bottom: 0.5px solid rgb(230, 230, 230);
}

.input-align-left{
  text-align: left;
}

.input-align-center{
  text-align: center;
}

.input-align-right{
  text-align: right;
}

.h-25{ height: 25px !important;}
.h-30{ height: 30px !important;}
.overflow-hidden{ overflow: hidden; }
.overflow-x-hidden{ overflow-x: hidden !important; }
.overflow-y-hidden{ overflow-y: hidden !important; }
.overflow-x-visible{ overflow-x: visible !important; }
.overflow-y-visible{ overflow-y: visible !important; }
.cursor-pointer{ cursor: pointer; }
.flex-1{ flex: 1 1; }
.flex-2{ flex: 2 1; }
.flex-3{ flex: 3 1; }
.flex-4{ flex: 4 1; }
.flex-5{ flex: 5 1; }
.flex-6{ flex: 6 1; }


.min-width-medium{ min-width: 320px !important; }
.dropdown-menu.show { width: 100%; }

@media (max-width: 991.98px) {
  .txt-medium{ font-size: 0.8rem; }
  .txt-small{ font-size: 0.65rem; }
  .txt-smaller { font-size: 0.6rem }
  .txt-big{ font-size: 1rem; }
}

.txt-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}
.position-relative {
  position: relative !important;
}
.tag-ellipsis{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}
/* Animations */

@-webkit-keyframes bounce-down{
    0%{
      transform: translateY(0);
    }
    50%{
        transform: translateY(-75px);
    }
    100%{
        transform: translateY(0);
    }
  }

@keyframes bounce-down{
    0%{
      transform: translateY(0);
    }
    50%{
        transform: translateY(-75px);
    }
    100%{
        transform: translateY(0);
    }
  }

@-webkit-keyframes fade-in-out{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes fade-in-out{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-out-light{
  from{
    opacity: 0.2;
  }
  to{
    opacity: 0.8;
  }
}
@keyframes fade-in-out-light{
  from{
    opacity: 0.2;
  }
  to{
    opacity: 0.8;
  }
}
@-webkit-keyframes fade-in-top-lighter{
    from{
    transform: translateY(-75px);
    opacity: 0;
    }
    to{
    transform: translateY(0);
    opacity: 0.6;
    }
  }
@keyframes fade-in-top-lighter{
    from{
    transform: translateY(-75px);
    opacity: 0;
    }
    to{
    transform: translateY(0);
    opacity: 0.6;
    }
  }

  @-webkit-keyframes fade-in-top-light{
    from{
    transform: translateY(-75px);
    opacity: 0;
    }
    to{
    transform: translateY(0);
    opacity: 0.8;
    }
  }

  @keyframes fade-in-top-light{
    from{
    transform: translateY(-75px);
    opacity: 0;
    }
    to{
    transform: translateY(0);
    opacity: 0.8;
    }
  }
  @-webkit-keyframes fade-in-top{
    from{
    transform: translateY(-75px);
    opacity: 0;
    }
    to{
    transform: translateY(0);
    opacity: 1;
    }
  }
  @keyframes fade-in-top{
    from{
    transform: translateY(-75px);
    opacity: 0;
    }
    to{
    transform: translateY(0);
    opacity: 1;
    }
  }
  @-webkit-keyframes fade-in-left-light{
    from{
    transform: translateX(-50px);
    opacity: 0;
    }
    to{
    transform: translateX(0);
    opacity: 1;
    }
  }
  @keyframes fade-in-left-light{
    from{
    transform: translateX(-50px);
    opacity: 0;
    }
    to{
    transform: translateX(0);
    opacity: 1;
    }
  }
  @-webkit-keyframes fade-in-right-light{
    from{
    transform: translateX(50px);
    opacity: 0;
    }
    to{
    transform: translateX(0);
    opacity: 1;
    }
  }
  @keyframes fade-in-right-light{
    from{
    transform: translateX(50px);
    opacity: 0;
    }
    to{
    transform: translateX(0);
    opacity: 1;
    }
  }
  @-webkit-keyframes fade-out-top-fade{
    0%{
        transform: translateY(0);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 0.7;
    }
    100%{
        transform: translateY(-300px);
        opacity: 0;
    }
  }
  @keyframes fade-out-top-fade{
    0%{
        transform: translateY(0);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 0.7;
    }
    100%{
        transform: translateY(-300px);
        opacity: 0;
    }
  }
  
  @-webkit-keyframes o-fadeInLeft{
    0%{
      transform: scale(0.9);
      transform: translateX(-100px) translateY(-50px);
      opacity: 0;
    }
    100%{
      transform: scale(0.9);
      transform: translateX(-50px) translateY(-50px);
      opacity: 1;
    }
  }
  
  @keyframes o-fadeInLeft{
    0%{
      transform: scale(0.9);
      transform: translateX(-100px) translateY(-50px);
      opacity: 0;
    }
    100%{
      transform: scale(0.9);
      transform: translateX(-50px) translateY(-50px);
      opacity: 1;
    }
  }
  @-webkit-keyframes o-fadeInRight{
    0%{
      width: 70%;
      transform: scale(0.8) translateX(-20px);
      opacity: 0;
    }
    100%{
      width: 70%;
      transform: scale(0.8) translateX(-70px);
      opacity: 1;
    }
  }
  @keyframes o-fadeInRight{
    0%{
      width: 70%;
      transform: scale(0.8) translateX(-20px);
      opacity: 0;
    }
    100%{
      width: 70%;
      transform: scale(0.8) translateX(-70px);
      opacity: 1;
    }
  }
  @-webkit-keyframes o-fadeInLeft-up{
    0%{
      
      opacity: 0;
      display: inline-block; 
      margin-left: auto !important;
      margin-right: auto !important;/**/
      margin-bottom: 15px;
      width: auto;
      float: none !important;
      transform: scale(1) translateX(-50px);
    }
    100%{
      transform: scale(1) translateX(0);
      opacity: 1;
      display: inline-block; 
      margin-left: auto !important;
      margin-right: auto !important;/**/
      margin-bottom: 15px;
      width: auto;
      float: none !important;
    }
  }
  @keyframes o-fadeInLeft-up{
    0%{
      
      opacity: 0;
      display: inline-block; 
      margin-left: auto !important;
      margin-right: auto !important;/**/
      margin-bottom: 15px;
      width: auto;
      float: none !important;
      transform: scale(1) translateX(-50px);
    }
    100%{
      transform: scale(1) translateX(0);
      opacity: 1;
      display: inline-block; 
      margin-left: auto !important;
      margin-right: auto !important;/**/
      margin-bottom: 15px;
      width: auto;
      float: none !important;
    }
  }
  @-webkit-keyframes o-fadeInRight-up{
    0%{
      transform: scale(0.9) translateX(50px);
      opacity: 0;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    100%{
      transform: scale(0.9) translateX(0);
      opacity: 1;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @keyframes o-fadeInRight-up{
    0%{
      transform: scale(0.9) translateX(50px);
      opacity: 0;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    100%{
      transform: scale(0.9) translateX(0);
      opacity: 1;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @-webkit-keyframes slide-top{
    from{
    transform: translateY(0);
    }
    to{
    transform: translateY(-650px);
    opacity: 1;
    }
  }
  @keyframes slide-top{
    from{
    transform: translateY(0);
    }
    to{
    transform: translateY(-650px);
    opacity: 1;
    }
  }
  @-webkit-keyframes slide-left-map{
    from{
    transform: translateX(0);
    }
    to{
    transform: translateX(-660px);
    opacity: 1;
    }
  }
  @keyframes slide-left-map{
    from{
    transform: translateX(0);
    }
    to{
    transform: translateX(-660px);
    opacity: 1;
    }
  }
  /* slide-top-start-fade */
  @-webkit-keyframes slide-top-start-fade{
    0%{
        transform: translateY(50px);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
      transform: translateY(-500px);
      opacity: 0;
    }
  }
  @keyframes slide-top-start-fade{
    0%{
        transform: translateY(50px);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
      transform: translateY(-500px);
      opacity: 0;
    }
  }
  @-webkit-keyframes slide-left-right-opacity{
    0%{
      transform: translateX(0);
      opacity: 0;
    }
    33.33%{
      transform: translateX(-50px);
      opacity: 1;
    }
    66.66%{
      transform: translateX(50px);
    }
    100%{
      transform: translateY(0);
      opacity: 0;
    }
  }
  @keyframes slide-left-right-opacity{
    0%{
      transform: translateX(0);
      opacity: 0;
    }
    33.33%{
      transform: translateX(-50px);
      opacity: 1;
    }
    66.66%{
      transform: translateX(50px);
    }
    100%{
      transform: translateY(0);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes slide-in-left{
    from{
      transform: translateX(-300px);
      -webkit-transform: translateX(-300px);
      -moz-transform: translateX(-300px);
      -ms-transform: translateX(-300px);
      -o-transform: translateX(-300px);
    }
    to{
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
    }
  }
  
  @keyframes slide-in-left{
    from{
      transform: translateX(-300px);
      -webkit-transform: translateX(-300px);
      -moz-transform: translateX(-300px);
      -ms-transform: translateX(-300px);
      -o-transform: translateX(-300px);
    }
    to{
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
    }
  }

  @-webkit-keyframes slide-out-left-2{
    to{
      transform: translateX(-300px);
      -webkit-transform: translateX(-300px);
      -moz-transform: translateX(-300px);
      -ms-transform: translateX(-300px);
      -o-transform: translateX(-300px);
    }
  }

  @keyframes slide-out-left-2{
    to{
      transform: translateX(-300px);
      -webkit-transform: translateX(-300px);
      -moz-transform: translateX(-300px);
      -ms-transform: translateX(-300px);
      -o-transform: translateX(-300px);
    }
  }

  @-webkit-keyframes rotate-180{
    to {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
    }
  }

  @keyframes rotate-180{
    to {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
    }
  }

  @-webkit-keyframes rotate-180-reverse{
    to {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }

  @keyframes rotate-180-reverse{
    to {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }
  
  /* Animated classes */

  /* delay classes animations 0s - 10s with half between */
  .delay-half{
    -webkit-animation-delay: 0.5s !important;
            animation-delay: 0.5s !important;
  }
  .delay-0{
    -webkit-animation-delay: 0s !important;
            animation-delay: 0s !important;
  }
  .delay-1{
    -webkit-animation-delay: 1s !important;
            animation-delay: 1s !important;
  }
  .delay-1-half{
    -webkit-animation-delay: 1.5s !important;
            animation-delay: 1.5s !important;
  }
  .delay-2{
    -webkit-animation-delay: 2s !important;
            animation-delay: 2s !important;
  }
  .delay-2-half{
    -webkit-animation-delay: 2.5s !important;
            animation-delay: 2.5s !important;
  }
  .delay-3{
    -webkit-animation-delay: 3s !important;
            animation-delay: 3s !important;
  }
  .delay-3-half{
    -webkit-animation-delay: 3.5s !important;
            animation-delay: 3.5s !important;
  }
  .delay-4{
    -webkit-animation-delay: 4s !important;
            animation-delay: 4s !important;
  }
  .delay-4-half{
    -webkit-animation-delay: 4.5s !important;
            animation-delay: 4.5s !important;
  }
  .delay-5{
    -webkit-animation-delay: 5s !important;
            animation-delay: 5s !important;
  }
  .delay-5-half{
    -webkit-animation-delay: 5.5s !important;
            animation-delay: 5.5s !important;
  }
  .delay-6{
    -webkit-animation-delay: 6s !important;
            animation-delay: 6s !important;
  }
  .delay-6-half{
    -webkit-animation-delay: 6.5s !important;
            animation-delay: 6.5s !important;
  }
  .delay-7{
    -webkit-animation-delay: 7s !important;
            animation-delay: 7s !important;
  }
  .delay-7-half{
    -webkit-animation-delay: 7.5s !important;
            animation-delay: 7.5s !important;
  }
  .delay-8{
    -webkit-animation-delay: 8s !important;
            animation-delay: 8s !important;
  }
  .delay-8-halh{
    -webkit-animation-delay: 8.5s !important;
            animation-delay: 8.5s !important;
  }
  .delay-9{
    -webkit-animation-delay: 9s !important;
            animation-delay: 9s !important;
  }
  .delay-9-half{
    -webkit-animation-delay: 9.5s !important;
            animation-delay: 9.5s !important;
  }
  .delay-10{
    -webkit-animation-delay: 10s !important;
            animation-delay: 10s !important;
  }
  /* duration classes animations 1s - 10s */
  .duration-1{
    -webkit-animation-duration: 1s !important;
            animation-duration: 1s !important;
  }
  .duration-2{
    -webkit-animation-duration: 2s !important;
            animation-duration: 2s !important;
  }
  .duration-3{
    -webkit-animation-duration: 3s !important;
            animation-duration: 3s !important;
  }
  .duration-4{
    -webkit-animation-duration: 4s !important;
            animation-duration: 4s !important;
  }
  .duration-5{
    -webkit-animation-duration: 5s !important;
            animation-duration: 5s !important;
  }
  .duration-6{
    -webkit-animation-duration: 6s !important;
            animation-duration: 6s !important;
  }
  .duration-7{
    -webkit-animation-duration: 7s !important;
            animation-duration: 7s !important;
  }
  .duration-8{
    -webkit-animation-duration: 8s !important;
            animation-duration: 8s !important;
  }
  .duration-9{
    -webkit-animation-duration: 9s !important;
            animation-duration: 9s !important;
  }
  .duration-10{
    -webkit-animation-duration: 10s !important;
            animation-duration: 10s !important;
  }
  .duration-half{
    -webkit-animation-duration: 0.5s !important;
            animation-duration: 0.5s !important;
  }
  .duration-1-half{
    -webkit-animation-duration: 1.5s !important;
            animation-duration: 1.5s !important;
  }
  .duration-2-half{
    -webkit-animation-duration: 2.5s !important;
            animation-duration: 2.5s !important;
  }
  .duration-3-half{
    -webkit-animation-duration: 3.5s !important;
            animation-duration: 3.5s !important;
  }
  .duration-4-half{
    -webkit-animation-duration: 4.5s !important;
            animation-duration: 4.5s !important;
  }
  .duration-5-half{
    -webkit-animation-duration: 5.5s !important;
            animation-duration: 5.5s !important;
  }
  .duration-6-half{
    -webkit-animation-duration: 6.5s !important;
            animation-duration: 6.5s !important;
  }
  .duration-7-half{
    -webkit-animation-duration: 7.5s !important;
            animation-duration: 7.5s !important;
  }
  .duration-8-half{
    -webkit-animation-duration: 8.5s !important;
            animation-duration: 8.5s !important;
  }
  .duration-9-half{
    -webkit-animation-duration: 9.5s !important;
            animation-duration: 9.5s !important;
  }
  .duration-10-half{
    -webkit-animation-duration: 10.5s !important;
            animation-duration: 10.5s !important;
  }
  /* play state animation (paused - running)*/
  .paused{-webkit-animation-play-state: paused !important;animation-play-state: paused !important;}
  .running{-webkit-animation-play-state: running !important;animation-play-state: running !important;}
  /* infinite animation */
  .infinite{-webkit-animation-iteration-count: infinite !important;animation-iteration-count: infinite !important;}
  /* alternate animation */
  .alternate{-webkit-animation-direction: alternate !important;animation-direction: alternate !important;}
  /* reverse animation */
  .reverse{animation-direction: reverse !important;}
  /* alternate-reverse animation */
  .alternate-reverse{animation-direction: alternate-reverse !important;}
  /* animation fill mode --> indique la façon dont une animation CSS doit appliquer les styles à sa cible avant et après son exécution.*/
  .animation-fill-mode-both{-webkit-animation-fill-mode: both !important;animation-fill-mode: both !important;}
  .animation-fill-mode-none{-webkit-animation-fill-mode: none !important;animation-fill-mode: none !important;}
  .animation-fill-mode-forwards{-webkit-animation-fill-mode: forwards !important;animation-fill-mode: forwards !important;}
  .animation-fill-mode-backwards{-webkit-animation-fill-mode: backwards !important;animation-fill-mode: backwards !important;}

  /* bounce up */
  .bounce-up{
    animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -ms-animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -moz-animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -o-animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -webkit-animation: bounce-down 3s ease-in-out 1s infinite alternate;
  }
  /* fades effects */
  .fade-in-out-light{
    animation: fade-in-out-light 2s ease-in-out;
    -ms-animation: fade-in-out-light 2s ease-in-out;
    -moz-animation: fade-in-out-light 2s ease-in-out;
    -o-animation: fade-in-out-light 2s ease-in-out;
    -webkit-animation: fade-in-out-light 2s ease-in-out;
  }
  .fade-in-out{
    animation: fade-in-out 2s ease-in-out;
    -ms-animation: fade-in-out 2s ease-in-out;
    -moz-animation: fade-in-out 2s ease-in-out;
    -o-animation: fade-in-out 2s ease-in-out;
    -webkit-animation: fade-in-out 2s ease-in-out;
  }
  .fade-in-top{
    animation: fade-in-top 0.5s ease-in-out;
    -ms-animation: fade-in-top 0.5s ease-in-out;
    -moz-animation: fade-in-top 0.5s ease-in-out;
    -o-animation: fade-in-top 0.5s ease-in-out;
    -webkit-animation: fade-in-top 0.5s ease-in-out;
  }
  .fade-in-top-light{
    animation: fade-in-top-light 0.5s ease-in-out;
    -ms-animation: fade-in-top-light 0.5s ease-in-out;
    -moz-animation: fade-in-top-light 0.5s ease-in-out;
    -o-animation: fade-in-top-light 0.5s ease-in-out;
    -webkit-animation: fade-in-top-light 0.5s ease-in-out;
  }
  .fade-in-top-lighter{
    animation: fade-in-top-lighter 0.5s ease-in-out;
    -ms-animation: fade-in-top-lighter 0.5s ease-in-out;
    -moz-animation: fade-in-top-lighter 0.5s ease-in-out;
    -o-animation: fade-in-top-lighter 0.5s ease-in-out;
    -webkit-animation: fade-in-top-lighter 0.5s ease-in-out;
  }
  .o-fadeInLeft{
    animation: o-fadeInLeft 2s ease-in-out;
    -ms-animation: o-fadeInLeft 2s ease-in-out;
    -moz-animation: o-fadeInLeft 2s ease-in-out;
    -o-animation: o-fadeInLeft 2s ease-in-out;
    -webkit-animation: o-fadeInLeft 2s ease-in-out;
  }
  
  .o-fadeInLeft-up{
    animation: o-fadeInLeft-up 2s ease-in-out;
    -ms-animation: o-fadeInLeft-up 2s ease-in-out;
    -moz-animation: o-fadeInLeft-up 2s ease-in-out;
    -o-animation: o-fadeInLeft-up 2s ease-in-out;
    -webkit-animation: o-fadeInLeft-up 2s ease-in-out;
  }
  .o-fadeInRight{
    animation: o-fadeInRight 2s ease-in-out;
    -ms-animation: o-fadeInRight 2s ease-in-out;
    -moz-animation: o-fadeInRight 2s ease-in-out;
    -o-animation: o-fadeInRight 2s ease-in-out;
    -webkit-animation: o-fadeInRight 2s ease-in-out;
  }
  
  .o-fadeInRight-up{
    animation: o-fadeInRight-up 2s ease-in-out;
    -ms-animation: o-fadeInRight-up 2s ease-in-out;
    -moz-animation: o-fadeInRight-up 2s ease-in-out;
    -o-animation: o-fadeInRight-up 2s ease-in-out;
    -webkit-animation: o-fadeInRight-up 2s ease-in-out;
  }
  /* fade-out-top-fade */
  .fade-out-top-fade{
    animation: fade-out-top-fade 2s ease-in-out;
    -ms-animation: fade-out-top-fade 2s ease-in-out;
    -moz-animation: fade-out-top-fade 2s ease-in-out;
    -o-animation: fade-out-top-fade 2s ease-in-out;
    -webkit-animation: fade-out-top-fade 2s ease-in-out;
  }
  .fade-in-left-light{
    animation: fade-in-left-light 2s ease-in-out;
    -ms-animation: fade-in-left-light 2s ease-in-out;
    -moz-animation: fade-in-left-light 2s ease-in-out;
    -o-animation: fade-in-left-light 2s ease-in-out;
    -webkit-animation: fade-in-left-light 2s ease-in-out;
  }
  .fade-in-right-light{
    animation: fade-in-right-light 2s ease-in-out;
    -ms-animation: fade-in-right-light 2s ease-in-out;
    -moz-animation: fade-in-right-light 2s ease-in-out;
    -o-animation: fade-in-right-light 2s ease-in-out;
    -webkit-animation: fade-in-right-light 2s ease-in-out;
  }
  /* slides effects */
  .slide-top{
    animation: slide-top 3s ease-in-out;
    -ms-animation: slide-top 3s ease-in-out;
    -moz-animation: slide-top 3s ease-in-out;
    -o-animation: slide-top 3s ease-in-out;
    -webkit-animation: slide-top 3s ease-in-out;
  }
  .slide-top-start-fade{
    animation: slide-top-start-fade 2s linear;
    -ms-animation: slide-top-start-fade 2s linear;
    -moz-animation: slide-top-start-fade 2s linear;
    -o-animation: slide-top-start-fade 2s linear;
    -webkit-animation: slide-top-start-fade 2s linear;
  }
  .slide-left-map{
    animation: slide-left-map 2s linear;
    -ms-animation: slide-left-map 2s linear;
    -moz-animation: slide-left-map 2s linear;
    -o-animation: slide-left-map 2s linear;
    -webkit-animation: slide-left-map 2s linear;
  }
  .slide-left-right-opacity{
    animation: slide-left-right-opacity 2s ease-in-out;
    -ms-animation: slide-left-right-opacity 2s ease-in-out;
    -moz-animation: slide-left-right-opacity 2s ease-in-out;
    -o-animation: slide-left-right-opacity 2s ease-in-out;
    -webkit-animation: slide-left-right-opacity 2s ease-in-out;
  }
  .slide-in-left{
    animation: slide-in-left .3s ease-in-out forwards;
    -ms-animation: slide-in-left .3s ease-in-out forwards;
    -moz-animation: slide-in-left .3s ease-in-out forwards;
    -o-animation: slide-in-left .3s ease-in-out forwards;
    -webkit-animation: slide-in-left .3s ease-in-out forwards;
  }
  .slide-out-left{
    animation: slide-out-left .3s ease-in-out forwards;
    -ms-animation: slide-out-left .3s ease-in-out forwards;
    -moz-animation: slide-out-left .3s ease-in-out forwards;
    -o-animation: slide-out-left .3s ease-in-out forwards;
    -webkit-animation: slide-out-left .3s ease-in-out forwards;
  }
.slide-out-left-2{
  animation: slide-out-left-2 .3s ease-in-out forwards;
  -ms-animation: slide-out-left-2 .3s ease-in-out forwards;
  -moz-animation: slide-out-left-2 .3s ease-in-out forwards;
  -o-animation: slide-out-left-2 .3s ease-in-out forwards;
  -webkit-animation: slide-out-left-2 .3s ease-in-out forwards;
}
.rotate-180{
  animation: rotate-180 .3s ease-in-out forwards;
  -ms-animation: rotate-180 .3s ease-in-out forwards;
  -moz-animation: rotate-180 .3s ease-in-out forwards;
  -o-animation: rotate-180 .3s ease-in-out forwards;
  -webkit-animation: rotate-180 .3s ease-in-out forwards;
}
.rotate-180-reverse{
  animation: rotate-180-reverse .3s ease-in-out forwards;
  -ms-animation: rotate-180-reverse .3s ease-in-out forwards;
  -moz-animation: rotate-180-reverse .3s ease-in-out forwards;
  -o-animation: rotate-180-reverse .3s ease-in-out forwards;
  -webkit-animation: rotate-180-reverse .3s ease-in-out forwards;
}

.auth{
    position: absolute;
    top: 0; left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #177BD1;
}
.auth .box-auth{
    width: 500px;
    min-height: 300px;
    padding: 20px;
    border-radius: 5px;
    /* border: thin solid #EFA959; */
    background-color: #FFFFFF;
}

.auth .h-40{
    height: 40px !important;
}
.switch-orgs{
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #177BD1;
}
.switch-content-list{
    width: 500px;
    min-height: 50px;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFFFFF;
}

.switch-content{
    position: relative;
    margin: 0;
    padding: 5px;
    border-radius: 5px;
    border: 0.5px solid #F0F0F0;
    min-height: 25px;
}
.small-title{
    text-align: center;
    color: #B5B5B5;
    font-size: 0.8rem;
}
.item-list{
    position: relative;
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: center;
    border-bottom: 0.5px solid #F0F0F0;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.item-list:last-child{
    border-bottom: none;
}
.item-list:hover{
    background-color: rgb(247, 247, 247);
}
.org-description{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;
}
.item-org-logo-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    padding: 2px;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: var(--logo-url);
    background-size: contain;
    background-color: #F0F0F0;
    border-radius: 3px;
}

.item-org-logo {
    height: auto;
    width: 100%;
}

.orgName{
    font-weight: bold;
    color: #177BD1;
}
.socialReason{
    font-weight: 300;
    font-size: 0.8rem;
    color: #FAAA20;
}

@media (max-width: 575.98px) { 
    .switch-content-list{
        width: calc(100vw - 20px);
    }
}
.item-list-loader{
    position: relative;
    display: flex;
    height: 55px;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
    background: #f8f8f8;
}


.logo-org-loader{
    position: relative;
    display: flex;
    margin: 0 10px 0 0;
    padding: 5px;
    width: 50px;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 3px;
    animation:loader-anim 1.5s;
    -moz-animation: loader-anim 1.5s infinite; /* Firefox */
    -webkit-animation:loader-anim 1.5s infinite; /* Safari and Chrome */
}

.descript-org-loader{
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 5px 0 0;
    padding: 5px;
    width: 100%;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 3px;
    animation:loader-anim 1.5s;
    -moz-animation: loader-anim 1.5s infinite; /* Firefox */
    -webkit-animation:loader-anim 1.5s infinite; /* Safari and Chrome */
}

.org-loading-text {
    position: relative;
    text-align: center;
    overflow: hidden;
    font-size: 8px;
    margin-top: -55px;
    width: 11em;
}
 
@-webkit-keyframes loader-anim
{
    0%   {background: #F0F0F0;}
    50%  {background: #FAFAFA;}
    100%   {background: #F0F0F0;}
}

.createOrg{
    position: relative;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background-color: #335284;
}

.co-wrapper{
    position: relative;
    margin: 0;
    padding: 0;
    height: inherit;
    width: inherit;
    background: url(/static/media/worldmap.06492a7b.svg) no-repeat fixed content-box;
    background-size: contain;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.co-form-wrapper{
    background-color: #FFF;
    border-radius: 5px;
    height: auto;
    width: 395px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0px;
    margin-right: 200px;
}

.co-form-title{
    color: #177BD1;
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.co-fields-wrapper{
    padding: 10px 20px 0px 20px;
    display: flex;
    flex-direction: column;
}

.co-field-item{
    border-radius: 3px;
    height: 40px;
    width: 100%;
}

.co-form-button{
    width: 100%;
    height: 40px;
    background-color: #005AAA;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 3px;
}
.co-wrapper .co-note{
    padding: 3px;
    font-size: 0.7rem;
    color: #666666;
}
.co-note b {
    color: #005AAA;
    font-size: 0.8rem;
    font-weight: bold;
}
@media (max-width: 767.98px) { 
    .co-wrapper{
        display: flex;
        justify-content: center;
        background-size: 110%;
        background-position: top -75px left 75px;
    }
    .co-form-wrapper{
        position: relative;
        width: 395px;
        margin: 200px 0 0 0;
    }
    .co-form-title{
        font-size: 1.1rem;
    }
}

@media (max-width: 575.98px) { 
    .co-wrapper{
        display: flex;
        justify-content: center;
        background-size: 150%;
        background-position: top -50px left 0;
    }
    .co-form-wrapper{
        position: relative;
        margin: 150px 0 0 0;
        width: calc(100% - 20px);
    }
    .co-form-title{
        font-size: 1.1rem;
    }
}


:root{
  --fontSize-card: 0.85rem;
}
.formCard-wrapper{
    display: flex;
    align-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 3px;
    height: 45px;
    width: 100%;
    padding: 7px;
    margin-left: 10px;
    margin-right: 10px;
    /* padding-right: 60px; */
}

.formCard-input{
    outline: none;
    border: 0;
    flex: 1 1 auto;
    /* width: inherit !important; */
    color: #707070;
    font-size: 0.85rem;
    font-size: var(--fontSize-card);
}

.formCard-select{
  border: none;
  background-color: transparent !important;
  color: #707070;
  width: 100%;
  font-size: 0.85rem;
  font-size: var(--fontSize-card);
}

.formCard-input:focus{
    color: #707070
}
.formCard-input:disabled{
    background-color: transparent;
}

.formCard-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
    /* width: 100%; */
}

.formCard-row .country-flag{
  position: relative;
  display: block;
  width: 20px;
  height: 14px;
  background-color: #AAA7A7;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.formCard-upload {
  border: none;
  color: #AAA7A7;
  background: transparent;
  font-size: 0.85rem;
  font-size: var(--fontSize-card);
}

.formCard-input::-webkit-input-placeholder, .formCard-input:-moz-placeholder, .formCard-input::-moz-placeholder, .formCard-input:-ms-input-placeholder{
  color: #AAA7A7;
}

.formCard-text-error {
  color: red;
  font-size: 0.85rem;
  font-size: var(--fontSize-card);
}
.formCard-input .formCard-button{
  width: 100%;
  height: 40px;
  background-color: #005AAA;
  color: #FFF;
  font-size: 0.85rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
}

.popover.err{
  padding: 10px !important;
  background-color: rgb(255 231 231) !important;
}

.popover.err.top .arrow::after{
  border-top-color: rgb(255 231 231) !important;
}

.popover.err.bottom .arrow::after{
  border-bottom-color: rgb(255 231 231) !important;
}

.popover.err.left .arrow::after{
  border-left-color: rgb(255 231 231) !important;
}

.popover.err.right .arrow::after{
  border-right-color: rgb(255 231 231) !important;
}
.orgsEmpty-wrapper{
    position: relative;
    display: flex;
    height: 55px;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
}


.orgsEmpty-logo{
    position: relative;
    display: flex;
    margin: 0 10px 0 0;
    padding: 5px;
    width: 50px;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 3px;
    -webkit-animation:loader-anim 1.5s;
            animation:loader-anim 1.5s;
}

.orgsEmpty-content{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 5px 0 0;
    padding: 5px;
    width: 100%;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 3px;
    
}

.orgsEmpty-content-link {
    color: #177BD1;
    font-size: 14px;
    cursor: pointer;
}


.header{
   position: fixed;
   display:flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   left: 0; top: 0;
   width: 100%;
   height: 75px;
   margin: 0; 
   padding: 0;
   z-index: 10;
}

.container-right{
   margin: 0;
   padding: 0 16px 0 133px;
   width: 100%;
   height: 75px;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   display: flex;
   justify-content: space-between;
   background-color: #FFFFFF;
   border-bottom: 1px solid rgba(0,0,0,.1);
   box-sizing: border-box;
}
.right{
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   justify-content: space-between;
   margin-right: 30px;
}

.usr-block{
   display: flex;
   margin-right: 8px;
   align-items: center;
   flex-direction: row;
   justify-content: space-between;
   cursor: pointer;
}

.title{
   margin: 0 10px 0 0;
   font-weight: bold !important;
   font-family: "Exo",Arial, Helvetica, sans-serif;
   text-transform: uppercase;
   color: #000000;
   font-size: 25px;
}

.bloc{
   display: block;
}

.label-user{
   margin: 0 10px;
   font-weight: 300;
   font-size: 0.9rem;
   font-family: "Exo",Arial, Helvetica, sans-serif;
   color: #707070;
}

.menu {
   margin: 0 10px;
}
.menu-display {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   width: 14px;
   height: 14px;
   border: 1px solid rgba(112, 112, 112, .1);
   padding: 2px;
}

.box {
   width: 6px;
   height: 6px;
   background-color: #B5B5B5;
   border-radius: 1px;
}

.box-hide {
   width: 4px;
   height: 4px;
   background-color: #B5B5B5;
   border-radius: 1px;
}

.line {
   width: 9px;
   color: #707070;
   border: 1px solid;
   margin-top: 2px;
   border-radius: 1px;
}

.btn-nav-menu{
   position: relative;
   cursor: pointer;
}

@media (max-width: 767.98px) {
   .header{
      position: fixed;
      left: 0; top: 0;
   }
   .container-right{
      padding: 0 16px;
   }
   .title{
      font-size: 1rem;
   }
}

 /* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
   .btn-nav-menu{
      display: none;
   }
}
@media (orientation: landscape) {
   
}
:root{
    --label-navitem: #FFFFFF;
    --separator-color-navitem: rgba(96, 153, 242, 0.4);
    --default-hover-navitem: #B5B5B5;
}
.menu-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 105px;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.menu-link:hover {
    text-decoration: none;
}

.NavItem{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-bottom: 0.5px solid rgba(96, 153, 242, 0.4);
    border-bottom: 0.5px solid var(--separator-color-navitem);
    border-left: none;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.NavItem::after, .NavItem:hover::after{
    content: " " ;
    z-index: 999;
    position: absolute;
    display: block;
    top: 5%; 
    bottom: 5%; 
    left: 0;
    width: 0;
    background-color: transparent; 
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-link.active .NavItem::after, .NavItem:hover::after {
    content: " " ;
    z-index: 999;
    position: absolute;
    display: block;
    top: 5%; 
    bottom: 5%; 
    left: 0;
    width: 4px;
    background-color: var(--active-color); 
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-link.active .label, .menu-link:hover .label {
    color: var(--active-color);
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-link.active .h-bar, .menu-link:hover .h-bar {
    background-color: var(--active-color);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.dashboard-link:hover::after {
    background-color:  #B5B5B5;
    background-color:  var(--default-hover-navitem); 
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.dashboard-link:hover .label {
    color: #B5B5B5;
    color: var(--default-hover-navitem);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.bank-link:hover::after {
    background-color:  #E29300;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.bank-link:hover .label { 
    color: #E29300;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.bill-link:hover::after {
    background-color: #B5B5B5;
    background-color: var(--default-hover-navitem);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.bill-link:hover .label {
    color: #B5B5B5;
    color: var(--default-hover-navitem);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.social-link:hover::after {
    background-color:  #177BD1;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.social-link:hover .label {
    color: #177BD1;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.fees-link:hover::after {
    background-color:  #FD543E;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.fees-link:hover .label{
    color: #FD543E;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.treasury-link:hover::after {
    background-color:  #AF4E07;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.treasury-link:hover .label{
    color: #AF4E07;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.taxe-link:hover::after {
    background-color:  #09B6CC;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.taxe-link:hover .label{
    color: #09B6CC;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.content-btn-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.NavItem .label{
    margin-top: 10px; 
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    color: #FFFFFF;
    color: var(--label-navitem);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.active .label{
    margin-top: 15px; 
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    font-weight: 900;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}


.dashboard-active .label {
    color: #FD543E !important;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.dashboard-active::after {
    background-color: #FD543E;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.bank-active .label {
    color: #E29300;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.bank-active::after {
    background-color: #E29300;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.fees-active .label {
    color:#FD543E ;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.fees-active::after {
    background-color: #FD543E;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.bill-active .label {
    color:#0C9F23;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.bill-active::after {
    background-color: #0C9F23;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.social-active .label {
    color: #177BD1 !important;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.social-active::after {
    background-color: #177BD1;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.treasury-active .label {
    color: #AF4E07;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.treasury-active::after {
    background-color: #AF4E07;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.taxe-active .label {
    color: #09B6CC;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.taxe-active::after {
    background-color: #09B6CC;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
button.NavItem.active::after{
    position: absolute;
    content: " ";
    display: block;
    top: 5%; bottom: 5%; left: 0;
    width: 4px;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.label-active {
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-item {
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-notification {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    width: 15px;
    height: 15px;
    border: 1px solid var(--default-noti-color);
    border-radius: 50%;
    color: var(--default-noti-color);
    top: 10px;
    right: 30px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

/* Added Styles */

/* SOCIAL */
.menu-link .social-icon{
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-link:hover .social-icon{
    fill: var(--theme-color);
}

/* DAHBOARD */
.menu-link .dashboard-icon{
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-link:hover .dashboard-icon {
    fill: var(--theme-color);
}

/* BANK */
.menu-link .bank-icon path{
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-link:hover .bank-icon path:not(.circle) {
    fill: var(--theme-color);
}

/* BILLING */
.menu-link .bill-icon{
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-link:hover .bill-icon {
    fill: var(--theme-color);
}

/* TEASURY */
.menu-link .teasury-icon path {
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-link:hover .teasury-icon path {
    fill: var(--theme-color);
}

/* TAXES */
.menu-link .taxe-icon path {
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-link:hover .taxe-icon path {
    fill: var(--theme-color);
}

/* FEES */
.menu-link .fees-icon path {
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.menu-link:hover .fees-icon path.theme-color-one, .menu-link.active .fees-icon path.theme-color-one {
    fill: #fd543e;
}
.menu-link:hover .fees-icon path.theme-color-two, .menu-link.active .fees-icon path.theme-color-two {
    fill: #ff8b7c;
}
.menu-link:hover .fees-icon path.theme-color-three, .menu-link.active .fees-icon path.theme-color-three {
    fill: #fd543e;
}
.menu-link:hover .fees-icon path.theme-color-four, .menu-link.active .fees-icon path.theme-color-four {
    fill: #ff8b7c;
}
.menu-link:hover .fees-icon path.theme-color-five, .menu-link.active .fees-icon path.theme-color-five {
    fill: #e23e3e;
}
.menu-link:hover .fees-icon path.theme-color-six, .menu-link.active .fees-icon path.theme-color-six {
    fill: #335284;
}
:root{
   --bg-navlink: #335284;
   --color-navlink: #FFFFFF;
   --separator-color-navlink: rgba(96, 153, 242, 0.4);
}
.NavLink{
   z-index: 999;
   position: fixed;
   left: 0; top: 0; bottom: 0;
   margin: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   padding: 0;
   width: 118px;
   /* height: 100vh; */
   background-color: #335284;
   background-color: var(--bg-navlink);
   color: #FFFFFF;
   color: var(--color-navlink);
   border-right: 1px solid rgba(0,0,0,.1);
   transition: all 0.3s ease-in-out;
   -ms-transition: all 0.3s ease-in-out;
   -moz-transition: all 0.3s ease-in-out;
   -o-transition: all 0.3s ease-in-out;
   -webkit-transition: all 0.3s ease-in-out;
}

.NavLink .nav-element{
   position: relative;
   width: 100%;
   margin: 0;
   padding: 0;
   flex: 1 1 auto;
   overflow-y: auto;
   overflow-x: hidden;
}
.NavLink .logo-container {
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0;
   width: 100%;
   height: 75px !important;
   background-color: transparent;
   border-bottom: 0.5px solid rgba(96, 153, 242, 0.4);
   border-bottom: 0.5px solid var(--separator-color-navlink);
}
.NavLink .logo{
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0;
   width: 90%;
   height: 50px !important;
   background-repeat: no-repeat;
   background-position: center;
   background-image: var(--logo-url);
   background-size: contain;
   color: #FFFFFF;
   color: var(--color-navlink);
   font-size: 1.3rem;
   cursor: pointer;
}
.bloc-1{
   display: inline;
   font-weight: bold;
   color: #177BD1;
   font-weight: bold;
   font-size: 0.9rem;
   font-family: "Exo",Arial, Helvetica, sans-serif;
}

 .bloc-2{
    display: inline;
    font-weight: bold;
    color: #FAAA20;
    /* font-weight: 300;
    font-size: 0.9rem; */
    font-family: "Exo",Arial, Helvetica, sans-serif;
 }

 .logo-footer{
   position: fixed;
   bottom: 0;
   width: 100%;
   height: 50px;
   color: #000000;
 }
 .dizen-logo {
    z-index: 1000;
   position: relative;
   width: 100%;
   height: 50px;
   border-top: 0.5px solid rgba(96, 153, 242, 0.4);
   border-top: 0.5px solid var(--separator-color-navlink);
   background-color: transparent;
}

.NavLinkResponsive{
    position: relative;
    display: none;
    flex: auto;
    z-index: 99;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.NavLinkResponsive .navlink-hoverley{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: 0; padding: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

@media (max-width: 767.98px) {
    .NavLinkResponsive.show{
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
    }
    .NavLinkResponsive.show .NavLink.translatreX-0{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .NavLinkResponsive .navlink-hoverley.fade-out{
        animation: fade-out 0.3s ease-in-out forwards;
        -webkit-animation: fade-out 0.3s ease-in-out forwards;
}
}

@-webkit-keyframes slide-in-left-0{
    to{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
}

@keyframes slide-in-left-0{
    to{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
}
.main-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: space-between;
    /* width: 100%; */
    margin-left: 118px;
    padding-top: 75px;
    overflow: hidden;
    z-index: 1;
}

@media (max-width: 767.98px) {
    .main-content{
        margin-left: 0;
    }
}
.main{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: inherit;
    margin: 0; 
    padding: 0;
    background-color: #F8F8F8;
}
.main .explicit-content{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9;
}
footer.container-footer{
    background-color: #FFFFFF;
    position: fixed;
    height: 50px;
    bottom: 0;
    width: 100%;
    z-index: 19;
    border-top: 0.5px solid rgba(0, 0, 0, .1);
    margin-top: 15px;
}

.footer{
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7em;
    font-weight: 300;
    color: #000000;
    padding-left: 30px;
}

@media (max-width: 767.98px) {
    .container-footer{
        justify-content: center;
        align-items: center;
    }
    .footer{
        padding-left: 0;
    }
}
@media (max-width: 575.98px) { 
    footer.container-footer{
        position: fixed;
        bottom: 0;
        bottom: 0;
        left: 0;
        right: 100%;
        z-index: 19;
        border-top: 0.5px solid rgba(0, 0, 0, .1);
    }
}

.account-menu{
    position: fixed;
}

.account-menu-area {
    position: absolute;
    display: none;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: rgba(0,0,0,0.1);
    z-index: 98;
}

.account-menu-wrapper{
    position: absolute;
    top: 76px; right: 0; bottom: 0; left: 0;
    display: none;
    flex-direction: column;
    z-index: 99;
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);
    border-radius: 3px;
    right:0;
    top: 75.5px;
    height: 100%;
    width: 100%;
    z-index: 15;
    background:rgba(0,0,0, 0.5);
}
.account-menu-wrapper.fade-out{
    animation: fade-out 0.3s ease-in-out forwards;
    -webkit-animation: fade-out 0.3s ease-in-out forwards;
}

.account-menu-content{
    position: fixed;
    top: 76px; right: 0;
    display: flex;
    flex-direction: column;
    width: 250px;
    background-color: #FFFFFF;
    z-index: 10;
    box-shadow: 0 0 7px rgba(0,0,0,0.2);
}

.account-menu-header{
    display: flex;
    flex-direction: row;
    align-content: center;
    border-bottom: 0.5px solid #F0F0F0;
    padding-top: 15px;
    padding-left: 10px;
    padding-bottom: 15px;
    min-height: 100px;
}

.account-avatar {
    position: relative;
    display: flex;
    align-items: center;
}

.account-infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
}
.account-name{
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.account-email{
    color: #B5B5B5;
    font-size: 0.8rem;
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.account-menu-item{
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.account-menu-item:hover{
    background-color: #F0F0F0;
}

.account-menu-footer{
    position: relative;
    border-top: 0.5px solid #F0F0F0;
    box-sizing: border-box;
    padding: 5px 0 5px 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.account-menu-footer:hover{
    background-color: #F0F0F0;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    overflow: hidden;
    max-width: 100%;
}

.account-menu-footer-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.account-menu-footer-item{
    cursor: pointer;
}

.fade-in {
    animation: fade-in 0.5s ease-in-out;
    -webkit-animation: fade-in 0.5s ease-in-out;
}

.fade-out {
    animation: fade-out 0.5s ease-in-out 0 forwards;
    -webkit-animation: fade-out 0.5s ease-in-out 0 forwards;
}

.hidden{
    display: none;
}

.visible{
    display: block;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .account-menu-content{
        top: 76px; right: 0;
        width: 100%;
    }
}

.DeconectionButtton{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 5px;
    height: 30px;
}

.DeconectionButtton .DeconectionButtton__loader{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .05);
    z-index: 9999;
}

.DeconectionButtton .DeconectionButtton__label{
    font-size: 0.85rem;
}

.DeconectionButtton .DeconectionButtton__label.processing{
    color: #CCCCCC;
}
/* The Modal (background) */
.switch-org-modal {
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    bottom: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s
}
.switch-org-modal.fadeOut {
    -webkit-animation-name: fadeOut;
    -webkit-animation-duration: 0.4s;
    animation-name: fadeOut;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.switch-org-modal.hidden {
    display: none;
}
/* Modal Content */
.switch-org-modal__content {
    position: fixed;
    top: 0;
    background-color: #fefefe;
    width: 100%;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
    padding-top: 0px;
    overflow-x: auto;
    overflow-y: hidden;
}

.switch-org-modal .switch-org-modal__content.slideOut{
    -webkit-animation-name: slideOut;
    -webkit-animation-duration: 0.4s;
    animation-name: slideOut;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.switch-org-modal .btn-close {
    z-index: 99;
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 0px;
    border: none;
    background-color: transparent;
}

.org-items-wraper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
    /* align-content: flex-start; */
}

.switch-org-modal__content-body-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}

.switch-org-modal__content-body {
    padding-right: 25px;
    padding-bottom: 2px;
    padding-left: 25px;
    width: 100%;
}

.modal-footer {
    padding: 2px 16px;
    background-color: #ffffff;
    color: white;
}

.modal-orgName{
    display: block;
    font-weight: bold;
    color: #177BD1;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-socialReason-wrapper{
    height: 16px;
    width: auto;
}

.modal-socialReason{
    display: block;
    font-weight: 300;
    font-size: 0.8rem;
    color: #FAAA20;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-org-description{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;
    width: 150px;
    margin: 0 0 0 0;
}
.modal-org-logo-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 0;
    padding: 5px;
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: var(--logo-url);
    background-size: contain;
    background-color: #F0F0F0;
    border-radius: 4px;
}

.modal-org-logo {
    height: auto;
    width: 100%;
}

.modal-item-list{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 180px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    padding: 15px;
    margin: 10px;
}

.switch-org-modal .add-org-btn{
    position: relative;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    margin: 0 50px 0 15px;
    padding: 5px;
    width: 150px !important;
    height: 150px !important;
    background-color: transparent !important;
    border-color: #EAEAEA;
    border-width: 3px;
    border-style: dashed;
    border-spacing: 10px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
.switch-org-modal .add-org-btn:hover {
    border-color: #C1BEBE;
}
.switch-org-modal .add-org svg path { 
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
.switch-org-modal .add-org-btn:hover svg path { 
    fill: #C1BEBE;
}
.switch-org-modal .add-org-btn .label{
    margin-top: 10px;
    font-size: 0.7rem;
    color: #C1BEBE;
    text-align: center;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
.switch-org-modal .add-org-btn:hover .label{
    color: #989898;
}
/* Add Animation */
@-webkit-keyframes slideIn {
    from {top: -300px; opacity: 0} 
    to {top: 0; opacity: 1}
}

@-webkit-keyframes slideOut {
    to {top: -300px; opacity: 0}
}

@keyframes slideIn {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
}

@keyframes slideOut {
    to {top: -300px; opacity: 0}
}

@-webkit-keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
}

@-webkit-keyframes fadeOut {
    to {opacity: 0}
}

@keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
}

@keyframes fadeOut {
    to {opacity: 0}
}

@media (max-width: 767.98px) {
    .switch-org-modal__content {
        margin: 0;
        padding: 0;
    }
    .modal-item-list{
        height: 130px;
        padding: 5px;
        margin: 10px 0;
        align-items: flex-start;
    }
    .modal-orgName{
        margin-top: 5px;
        font-weight: bold;
        font-size: 0.75rem;
        color: #177BD1;
    }
    .modal-socialReason{
        font-weight: 300;
        font-size: 0.55rem;
        color: #FAAA20;
    }
    .modal-logo-org{
        padding: 5px;
        width: 100px;
        height: 100px;
    }
    .switch-org-modal .add-org{
        width: 130px;
        height: 125px;
    }
}
@media (orientation: landscape) {
    
}


.item-loader-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 180px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    padding: 15px;
}

.modal-logo-org-loader{
    position: relative;
    display: flex;
    margin: 0 0 0 0;
    padding: 5px;
    width: 150px;
    height: 150px;
    background-color: #F0F0F0;
    border-radius: 3px;
    animation:loader-anim 1.5s;
    -moz-animation: loader-anim 1.5s infinite; /* Firefox */
    -webkit-animation:loader-anim 1.5s infinite; /* Safari and Chrome */
}

.modal-descript-org-loader{
    position: relative;
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
    padding: 5px;
    width: 150px;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 3px;
    animation:loader-anim 1.5s;
    -moz-animation: loader-anim 1.5s infinite; /* Firefox */
    -webkit-animation:loader-anim 1.5s infinite; /* Safari and Chrome */
}
 
@-webkit-keyframes loader-anim
{
    0%   {background: #F0F0F0;}
    50%  {background: #FAFAFA;}
    100%   {background: #F0F0F0;}
}

@media (max-width: 767.98px) {
    .item-loader-wrapper{
        height: 150px;
    }
    .modal-logo-org-loader{
        width: 100px;
        height: 84px;
    }
    .modal-descript-org-loader{
        width: 100px;
        height: 26px;
    }
}
.bank{
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    height: 100%;
}
@media (max-width:767.98px) {
    .bank {
        padding-bottom: 30px;
    }
}
.bank-detail {
    z-index: 10;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.fluid-content-data {
    position: relative; 
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.period-box{
    position: relative;
    width: 260px;
}

.bank .file-prefiew{
    z-index: 999;
    position: absolute;
    top: 0; left: 0;
    display: none;
    width: 100%; height: 100%;
    margin: 0; padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.bank .file-prefiew.show{
    z-index: 999;
    position: absolute;
    top: 0; left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; height: 100%;
    margin: 0; padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
.bank .preview-file-container {
    position: relative;
    flex: 1 1 auto;
}

.flex-part {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
@media (max-width: 767.98px) {
    .flex-part {
        padding-bottom: 30px;
    }
}
.chart-box{
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 250px;
}
.chart-box .chart-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: #FFFFFF;
}
.btn-arrow-circled{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.records{
    position: relative;
    display: flex;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
}
.bank-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.bank-content .bank-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2px 0;
    height: 50px;
    background-color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.bank-content .content-menu{
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
}
.bank-content .active-minimized{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 1;
    margin: 0;
    padding: 0;
    width: 30px;
    overflow: hidden;
}
.bank-content .active-minimized button{
    position: relative;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}
.bank-content .active-minimized .active-menu-item{
    position: relative;
    margin: 0;
    padding: 10px 0;
    width: 30px;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E29300;
    font-size: 0.9rem;
}
ul.bank-menu-container {
    position: relative;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding:0;
    padding-left: 10px;
    flex: 1 1 auto;
}

ul.bank-menu-container.mp-15 {
    position: relative;
    display: block;
    list-style: none;
    margin: 0 15px;
    padding:0;
}

ul.bank-menu-container > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 5px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.bank-menu-container > li:not(:first-child) {
    margin-left: 15px;
}

ul.bank-menu-container > li::before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #E29300;
    opacity: 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.bank-menu-container > li.active::before {
    height: 3px;
    opacity: 1;
}

.bank-menu-item {
    height: inherit;
    width: inherit;
    position: relative;
    cursor: pointer;
    color: #b5b5b5;
    text-decoration: none;
    /* margin-bottom: 15px; */
    font-size: 0.75rem;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

a.bank-menu-item:hover {
    color: #E29300;
    text-decoration: none;
}

a.bank-menu-active {
    color: #E29300;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.active-minimized > .item-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #E29300;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0;
    padding: 5px;
    border-radius: 50%;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
}

.item-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #E29300;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    margin-left: 5px;
    padding: 5px;
    border-radius: 50%;
}
.btn-toggle-chart{
    position: relative;
    background-color: #e2e2e2;
    color: #333333;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.page-bank-content{
    flex: 1 1; 
}

.bank-date-width {
    width: 20%;
}
.bank-account-width {
    width: 20%;
}
.bank-debit-width {
    width: 30%;
}
.bank-credit-width {
    width: 30%;
}

.stmt-date-width {
    width: 25%;
}

.stmt-account-width {
    width: 45%;
}
.stmt-balance-width {
    width: 35%;
}

@media(min-width:575.98px) and (max-width:767.98px) {
    .bank-date-width {
        width: 10%;
    }
    .bank-account-width {
        width: 30%;
    }
    .stmt-balance-width {
        width: 50%;
    }
}

@media(min-width: 767.98px) {
    .bank-date-width {
        width: 11%;
    }
    .bank-reference-width {
        width: 20%;
    } 
    .bank-description-width {
        width: 30%;
    }
    .bank-debit-width {
        width: 25%;
    }
    .bank-credit-width {
        width: 25%;
    }

    .stmt-date-width {
        width: 10%;
    }
    .stmt-period-width {
        width: 30%;
    }
    .stmt-name-width {
        width: 30%;
    }
    .stmt-attachment-width {
        width: 20%;
    }
    .stmt-account-width {
        width: 30%;
    }
    .stmt-balance-width {
        width: 25%;
    }
}
.search{
    padding: 10px 20px;
    width: 260px;
    height: 30px;
    background-color: #F0F0F0;
    border-radius: 50px;
    outline: none;
    border: none;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    background-image: url(/static/media/icon-search.35a34114.svg);
    background-repeat: no-repeat;
    background-position: top 50% right 20px;
}

.search-fees {
    background-image: url(/static/media/icon-search-red.46c144c5.svg);
}

.search-bankRecords {
    background-image: url(/static/media/icon-search-orange.5f9d35da.svg)
}

.search-social {
    background-image: url(/static/media/icon-search.35a34114.svg);
}

@media (max-width: 767.98px) {
    .ct-search{
        width: 300px;
        max-width: 90%;
        margin-left: 5px;
    }
    .ct-search input.search{
        width: 100%;
    }
    .search-social {
        flex: 1 1 auto !important;
    }
}
@media (max-width: 575.98px) {
    .ct-search{
        width: 100%;
        max-width: 100%;
        margin-left: 3px;
    }
}
.CustomInputFile{
    position: relative;
    display: flex;
}

.CustomInputFile .CustomInputFile__controls{
    margin-left: 10px;
}

.CustomInputFile__controls button:not(:first-child){
    margin-left: 10px;
}

.CustomInputFile .btn {
    padding: 0 !important;
}

.CustomInputFile .circular{
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
}

.CustomInputFile .bg-none{
    background-color: transparent !important;
}

.btn__cancel{
    display: inline-block;
    width: inherit;
    height: inherit;
    background-image: url(/static/media/_ionicons_svg_ios-close.f63ef4cd.svg);
    background-position: 45% 48%;
    background-repeat: no-repeat;
    background-size: 30%;
}

.btn__upload{
    display: inline-block;
    width: inherit;
    height: inherit;
    background-image: url(/static/media/_ionicons_svg_ios-cloud-upload.622de54a.svg);
    background-position: 45% 48%;
    background-repeat: no-repeat;
    background-size: 50%;
}

.CustomInputFile .hidden {
    display: none;
}

.import-label{
    margin: 0 15px;
    font-weight: 400;
    font-size: 12px;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    color: #000;
}

.import-file{
    width: 150;
    height: 30px;
    border-radius: 50px;
    border: 1px solid #E29300;
    background-image: url(/static/media/icon-arrow-round-up.c9c58273.svg);
    background-repeat: no-repeat;
    background-position: top 49% right 10%;
    background-size: 6px;
    padding: 0 10px 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.search-bankRecord{
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bank-import {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
}

.title-tri{
    display: flex;
    align-items: flex-end;
    color: #555555;
    font-weight: bold;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem; 
    vertical-align: bottom;
    margin-right: 9px;
}

.col-description {
    padding-left: 10px;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    text-decoration: none
}
.btn-bank-account {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    width: 475px;
}
.reconciliation-filter {
    margin-left: 2px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .search-bankRecord{
        flex-direction: column-reverse;
    }
    .bank-import { 
        width: 100%;
        max-width: 100%;
    }
    .btn-bank-account {
        margin: 10px 0 0; 
    }
    .reconciliation-filter {
        margin: 10px 0 0;
    }
}

@media (max-width: 991.98px) {
    .search-bankRecord .col {
        padding: 0;
    }
    .btn-bank-account .btn {
        font-size: 8px;
        
    }
    .btn-bank-account {
        width: 350px;
        margin-left: 0;
    }
    .reconciliation-filter {
        margin-left: 0;
    }
    .bank-import .CustomInputFile{
        display: none;
    }
}

@media (max-width: 767.98px) {
    .search-bankRecord{
        /* flex-direction: column-reverse; */
    }
    .search-bankRecord .col {
        margin-top: 5px;
        padding: 0;
    }
    .btn-bank-account {
        margin-left: 0;
        max-width: 100%;
        width: 100%;
    }
    .reconciliation-filter {
        margin-left: 0;
    }
    .btn-bank-account .btn {
        font-size: 8px;
    }
}
.bank-drop-down {
    text-align: left;
}
.bank-drop-down-menu {
    margin-top: 5px;
    font-size: 12px;
    max-width: 100%;
}
.bank-drop-down-btn {
    display: flex;
    align-items: center;
    height: 30px;
    width: 100%;
    border: solid 0.3px;
    border-radius: 20px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
}
.bank-arrow {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bank-drop-down-btn::after {
    display: none;
}
.text-color-bank {
    color: #707070;
}
.dropdown-item:active {
    background-color: transparent;
}
.bank-item-wrapped{
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 115px;
    font-size: 0.75rem;
    overflow: hidden;
    display: inline-block;
}
@media (max-width: 575.98px) {
    .bank-item-wrapped{
        font-size: 0.65rem;
    }
}
.period-dropdown{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 35px;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.period-dropdown .btn-selector-item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: inherit;
    margin: 0;
    padding: 0 15px;
    color: #707070;
    background-color: #FFFFFF;
    border: 0.5px solid #CCCCCC;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.btn-selector-item .period-dropdown-icon{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin: 0;
    overflow: hidden;
}
.btn-selector-item .period-dropdown-value{
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    height: inherit;
    margin: 0;
    padding: 0 0 0 5px;
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.period-dropdown > .list-box {
    position: absolute;
    z-index: 999;
    top: 40px;
    right: 0;
    display: flex;
    margin: 0;
    padding: 0;
    min-width: 100%;
    background-color: #FFFFFF;
    border: 0.5px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.list-box > .list-box-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;

}
.list-box-content > .list-box-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    height: 30px;
    font-size: 0.75rem;
    color: #707070;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.list-box-content > .list-box-item.active {
    background-color: rgba(0, 0, 0, 0.05);
}
.list-box-content > .list-box-item:not(.active):hover{
    background-color: rgba(0, 0, 0, 0.05);
}
.list-box > .custom-box-range{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 300px;
    border-left: 0.5px solid #CCCCCC;
}
.custom-box-range .title-custom-box{
    font-size: 0.75rem;
    color: #707070;
    font-weight: 700;
}
.custom-box-range .react-datepicker__input-container input[type="text"] {
    font-size: 0.75rem;
    padding: 5px;
    color: #707070;
    background-color: #EEEEEE;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.list-box-content .list-box-item.active-custom{
    background-color: rgba(0, 0, 0, 0.05);
}
.ft-white {
    color: #FFFFFF;
}
.ft-white:hover {
    color: #FFFFFF;
}

.truncate-string {
    position: relative;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #707070;
}
.displayContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    width: 350px;
    background-color: #FFFFFF;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 50px;
}
.displayContainer .headerDisplayContainer{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 50px;
}
.headerDisplayContainer .titleStatement {
    font-size: 0.8rem;
    font-weight: bold !important;
    color: #707070;
}

.headerDisplayContainer button.btn-close {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    /* margin-right: 15px; */
}
.headerDisplayContainer .customHeaderContent {
    color: #707070;
    position: relative;
    flex: 1 1 auto;
}
.displayContainer .contentDisplayContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50px; bottom: 0; left: 0; right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1 auto;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .displayContainer{
        position: fixed;
        top: 75px; right: 0; bottom: 50px; left: 0;
        width: 100vw;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .displayContainer{
        position: fixed;
        top: 75px; right: 0; bottom: 0;
        box-shadow: -3px 0 5px rgba(0,0,0,0.1);
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .displayContainer{
        position: absolute;
        z-index: 18;
        top: 0; right: 0; bottom: 0;
        box-shadow: -3px 0 5px rgba(0,0,0,0.1);
    }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    
}
:root{
    --light-grey: #B5B5B5;
    --dark-grey: #707070;
    --font-size-small: .85rem;
    --font-size-smaller: .75rem;
    --font-size-smallest: .65rem;
    --font-size-medium: .95rem;
    --font-size-normal: 1rem;
    --font-size-big: 1.2rem;
    --font-size-bigger: 1.35rem;
    --font-size-biggest: 1.5rem;
    --blue: #177BD1;
    --error-color: rgb(250, 182, 170);
    --error-textcolor: rgb(185, 72, 52);
}
.EditableInput{
    position: relative;
    display: block;
}

/**
* RELATIVE TO THE INPUT
* {text} {number} {email} {date} {password} {tel} {time} {url} {search}
*/

.EditableInput .input-txt{
    position: relative;
    display: block;
    margin: 0;
    padding: 5px;
    width: 100%;
    border: .5px solid #B5B5B5;
    border: .5px solid var(--light-grey);
    border-radius:3px;
    background: #FFFFFF;
    font-size: .85rem;
    font-size: var(--font-size-small);
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -ms-border-radius:3px;
    -o-border-radius:3px;
}
.EditableInput .input-txt:disabled{
    border: none;
    background-color: #B5B5B5;
    background-color: var(--light-grey);
}
.EditableInput .valueRecord {
    position: relative;
    margin: 0; padding: 0;
    width: 100%;
    color: #707070;
    color: var(--dark-grey);
    font-size: .85rem;
    font-size: var(--font-size-small);
}
.EditableInput .valueRecord.withIcon {
    position: relative;
    display: flex;
    align-items: center;
    padding:5px 5px 5px 0;
    width: 100%;
    height: auto;
}
.EditableInput .valueRecord.withIcon .icon-comp{
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin-left: 5px;
}
.EditableInput .valueRecord.withIcon .input-txt {
    position: absolute;
    /* z-index: -1; */
    top: 0; left: 0; right: 0; bottom: 0;
    padding-left: 25px;
    color: #707070;
    color: var(--dark-grey);
}
.EditableInput .valuePreview{
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: .75rem;
    font-size: var(--font-size-smaller);
    font-weight: bold;
    color: #707070;
    color: var(--dark-grey);
}
.EditableInput .valuePreview.withIcon{
    padding-left: 0;
}
.EditableInput .valuePreview.withIcon .icon-comp{
    position: relative;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 20px;
    height: 25px;
}
.EditableInput .valuePreview.withIcon div.txt-preview{
    margin-left: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}
span.puce{
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    background-color: #177BD1;
    background-color: var(--blue);
    font-family: 'Exo';
    font-size: 0.6rem;
    font-weight: 900;
    color: #FFFFFF;
    border-radius: 50%;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.EditableInput .valueRecord.withIcon input.pl-30-10{
    padding-left: 30px;
}
.EditableInput .valuePreview.withIcon  .pl-30-10{
    padding-left: 10px;
}
.EditableInput.boxSizing-inherit {
    width: inherit !important;
}
.EditableInput.ei-error {
    color: rgb(185, 72, 52);
    color: var(--error-textcolor);
}

.EditableInput.ei-error .input-txt {
    border-color: rgb(250, 182, 170);
    border-color: var(--error-color);
}
.EditableInput.ei-error .valuePreview{
    color: rgb(185, 72, 52);
    color: var(--error-textcolor);
}
.EditableInput.ei-social-error {
    border-bottom: thin solid rgb(250, 182, 170);
    border-bottom: thin solid var(--error-color);
    color: rgb(185, 72, 52);
    color: var(--error-textcolor);
}
.EditableInput.ei-social-error::after {
    content: attr(content);
    position: absolute;
    display: inline-block;
    bottom: 0;
    right: 0;
    padding: 1px 2px 0 2px;
    font-size: 0.45rem;
    color: rgb(185, 72, 52);
    color: var(--error-textcolor);
    background-color: rgb(250, 182, 170);
    background-color: var(--error-color);
    border-radius: 2px 2px 0 0;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
}

.EditableInput.ei-social-error .input-txt {
    border-color: rgb(250, 182, 170);
    border-color: var(--error-color);
}
.EditableInput.ei-social-error .valuePreview{
    color: rgb(185, 72, 52);
    color: var(--error-textcolor);
}
.react-datepicker__input-container input[type="text"] {
    border: none;
    background-color: transparent;
    font-size: 0.7rem;
}
.react-datepicker-popper{
    z-index: 9999 !important;
}
.custom-date-picker{
    position: relative;
    font-weight: 900;
    color: #707070;
    color: var(--dark-grey);
    width: 100%;
    height: 100%;
}
.custom-date-picker::-webkit-input-placeholder{
    font-weight: 900;
    color: #707070;
    color: var(--dark-grey);
}
.custom-date-picker:-ms-input-placeholder{
    font-weight: 900;
    color: #707070;
    color: var(--dark-grey);
}
.custom-date-picker::-ms-input-placeholder{
    font-weight: 900;
    color: #707070;
    color: var(--dark-grey);
}
.custom-date-picker::placeholder{
    font-weight: 900;
    color: #707070;
    color: var(--dark-grey);
}
.popover-body{
    padding: 0 !important;
    background-color: transparent !important;
    border: thin solid rgba(0,0,0,0) !important;
}
.react-datepicker__month-select, .react-datepicker__year-select{
    background-color: #335284;
    border: none;
    padding: 2px 3px !important;
    border-radius:3px;
    color: #FFFFFF;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -ms-border-radius:3px;
    -o-border-radius:3px;
}
.react-datepicker__month-select{
    width: 80px;
}
.react-datepicker__year-select{
    width: 60px;
}

.EditableInput .input-drop-down{
    text-align: left;
}
.EditableInput .input-drop-down .input-drop-down-btn{
    display: flex;
    align-items: center;
    height: 30px;
    width: 100%;
    border: solid 0.3px;
    border-radius: 20px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    outline: none;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.EditableInput .dropdown-toggle.btn.btn-primary:active{
    border-color: rgba(0,0,0,0.2) !important;
    outline: none !important;
}
.EditableInput .input-drop-down .input-drop-down-btn::after {
    display: none;
}
.EditableInput .text-color-input{
    color: #707070;
    font-size: 0.8rem;
}
.EditableInput a.dropdown-item{
    padding: 4px 15px;
}
.EditableInput .text-color-input.active{
    background-color: #335284;
    color: #FFFFFF;
    font-weight: 800;
}
.EditableInput .input-nane{
    color: #707070;
    font-size: 0.8rem;
}
.input-drop-down-menu.show{
    margin-top: 8px;
    width: 100%;
}
.EditableInput .input-arrow{

}

.btn-date{
    position: relative;
    background-color: transparent;
    border: none;
    font-size: 0.75rem;
    font-weight: 900;
    color: #707070;
}
.btn-date::-webkit-input-placeholder{
    color: #707070;
}
.btn-date:-ms-input-placeholder{
    color: #707070;
}
.btn-date::-ms-input-placeholder{
    color: #707070;
}
.btn-date::placeholder{
    color: #707070;
}
.zi-9999{
    z-index: 9999 !important;
}
.popover.err{
    padding: 10px !important;
    background-color: rgb(255 231 231) !important;
}
.popover.err .arrow::after{
    border-top-color: rgb(255 231 231) !important;
}

.popover.err.top .arrow::after{
  border-top-color: rgb(255 231 231) !important;
}

.popover.err.bottom .arrow::after{
  border-bottom-color: rgb(255 231 231) !important;
}

.popover.err.left .arrow::after{
  border-left-color: rgb(255 231 231) !important;
}

.popover.err.right .arrow::after{
  border-right-color: rgb(255 231 231) !important;
}
.DateItemList{
    width: 35px;
    height: 35px;
    border-radius: 5px;
    color:  #FFFFFF ;
    overflow: hidden;
    font-family:  "Exo" ;
    transform: scale(0.8);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.DateItemList.active{
    width: 35px;
    height: 35px;
    border-radius: 2px;
    border: none;
    color:  #333333 ;
    overflow: hidden;
    font-family:  "Exo" ;
    transform: scale(0.8);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.DateItemList .boxDay{
    display: flex;
    height: 60%;
    background-color: #CBCBCB;
    align-items: center;
    justify-content: center ;
}
.DateItemList .boxDayactive{
    display: flex;
    height: 60%;
    align-items: center ;
    justify-content: center ;
}
.DateItemList .active-fees{
    background-color:  #F56969 ;
}
.DateItemList .active-bank{
    background-color:  #FAAA20 ;
}
.DateItemList .active-bill{
    background-color:  #0CBF28 ;
}
.DateItemList .boxMonth{
    display:  flex ;
    height: 40%;
    align-items: center ;
    justify-content: center ;
    background-color:#AAA7A7 ;
}
.DateItemList .boxMonthactive{
    display: flex ;
    height: 40%;
    align-items: center ;
    justify-content: center ;
    /* border-top: 0.5px solid rgba(255,255,255,0.7); */
}
.DateItemList .month-fees{
    background-color:#E23E3E ;
}
.DateItemList .month-bank{
    background-color:#E29300 ;
}
.DateItemList .month-bill{
    background-color:#0C9F23;
}
.DateItemList .txtDay{
   font-family: "Exo" ;
   font-size: 0.9rem;
   font-weight: bold ;
   color: #FFFFFF ;
   margin-top: 5px;
}
.boxDayactive .txtDay{
    color:  #FFFFFF ; 
}
.DateItemList .txtMonth{
   font-family: "Exo" ;
   font-size: 0.55rem;
   font-weight: bold !important;
   color: #FFFFFF ;
   text-transform: capitalize;
}
.boxMonthactive .txtMonth{
    color:  #FFF ; 
}
.invoice-row-item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: thin solid rgba(0, 0, 0, 0);
    margin-bottom: 1px;
    padding-right: 3px;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px #E1E1E1;
    -moz-box-shadow: 0px 1px 3px  #E1E1E1;
    -webkit-box-shadow: 0px 1px 3px #E1E1E1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.invoice-row-item:hover{
    border: thin solid #E1E1E1;
}
.invoice-row-item .invoice-action{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.invoice-row-item .invoice-action:hover{
    background-color: #E1E1E1;
}
.invoice-row-item .invoice-statement{
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    flex: auto;
    font-size: 0.75rem;
}
.invoice-row-item .invoice-deliver{
    justify-content: flex-start;
    padding-left: 10px;
}
.invoice-row-item .invoice-amount{
    justify-content: flex-end;
    padding-right: 10px;
    font-weight: bold;
 }
.invoice-row-item .invoice-action {
    transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
}
.opacity-0 {
    opacity: 0;
}
.invoice-row-item:hover .invoice-unreconciled .ico-link{
    opacity: 1;
}
.invoice-row-item .invoice-detail .ico-unlink{
    display: none;
}
.invoice-row-item:hover .invoice-detail .ico-unlink{
    display: block;
    opacity: 1;
}
.invoice-row-item:hover .invoice-detail .ico-link{
    display: none;
    opacity: 0;
}

.invoice-active {
    background-color: rgba(23, 123, 209, 0.1);
    border: thin solid #E1E1E1;
}

@media (max-width: 767.98px) {
    .invoice-unreconciled .ico-link{
        opacity: 1;
    }

    .invoice-detail .ico-link{
        display: none;
    }
    .invoice-row-item .invoice-detail .ico-unlink{
        display: block;
        opacity: 1;
    }
}

.bg-light-grey{
    background-color: #F0F0F0;;
}
.br-5{
    border: solid none;
    border-radius: 5px;
}
.minimized-attachment-preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #CBCBCB;
    height: 180px;
    border-radius: 5px;
}

.minimized-attachment-preview img {
    display: inline-block;
}
img.width-auto{
    width: auto;
    max-width: calc(100% - 10px);
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
}
img.height-auto{
    height: auto;
    max-height: calc(100% - 10px);
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
}
img.width-height-auto{
    max-width: calc(100% - 10px);
    max-height: calc(100% - 10px);
    width: auto;
    height: auto;
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out; 
}
img.opacity-0 { 
    opacity: 0;
}
.box-loader{
    z-index: 99;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-color: transparent;
    height: 50px;
    line-height: 50px;
    padding: 5px;
}
.box-loader .b-load{
    position: relative;
    width: 25px;
    height: 25px;
    animation: b-spin 4s ease-in-out infinite;
    -webkit-animation: b-spin 4s ease-in-out infinite;
}

.minimized-attachment-preview, .minimized-attachment-preview img, .minimized-attachment-preview object #root {
    cursor: pointer;
}
.overlay-box{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 99;
}
.reconciliation{
    position: relative;
    color: #333333;
}
.period-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;;
}
.period-form .date-form{
    flex: 1 1;
    margin-right: 5px;
}
.period-form .date-form:last-child{
    margin-right: 0;
}
.text-header-bank{
    font-size: 0.8rem;
    color: #707070;
}
.text-modal-bank{
    color: #707070;
    font-size: 0.75rem;
}
.date-filter{
    background-color: #F0F0F0;
    border-radius: 50px;
    outline: none;
    border: none;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    background-repeat: no-repeat;
    background-position: top 50% right 20px;
}
.invoice-filter{
    position: relative;
    display: block;
    border: none;
    background-color: #F0F0F0;
    border-radius: 50px;
    padding: 5px 30px 5px 10px;
    font-size: 0.75rem;
    width: 100%;
    height: 30px;
    background-image: url(/static/media/icon-search-orange.5f9d35da.svg);
    background-repeat: no-repeat;
    background-position: top 49% right 10px;
}
.invoice-filter::-webkit-input-placeholder{
    color: #707070;
}
.invoice-filter:-ms-input-placeholder{
    color: #707070;
}
.invoice-filter::-ms-input-placeholder{
    color: #707070;
}
.invoice-filter::placeholder{
    color: #707070;
}
.card-item-detail{
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 1px 7px #E1E1E1;
    -moz-box-shadow: 0px 1px 7px  #E1E1E1;
    -webkit-box-shadow: 0px 1px 7px #E1E1E1;
}
.btn-drop-up{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.btn-drop-up:hover{
    background-color: transparent;
}

button.upload {
    display: inline-block;
    justify-content: center;
    align-items: center;
    height: 30px;
    border: 1px solid gray;
    border-radius: 4px;
    width: 100%;
    background-color: #FFFFFF;
    font-size: 0.8rem;
}

button.upload > img {
    margin-right: 5px;
}

button.upload {
    border: 1px solid #e29300;
}
.custom-image-zoom{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom-image-zoom img{
    position: relative;
    display: block;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
.custom-image-zoom img:hover{
    cursor: move;
}
.custom-image-zoom img.width-auto{
    width: auto;
    max-width: calc(100% - 10px);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom-image-zoom img.height-auto{
    height: auto;
    max-height: calc(100% - 10px);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom-image-zoom img.width-height-auto{
    max-width: calc(100% - 10px);
    max-height: calc(100% - 10px);
    width: auto;
    height: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out; 
}
.custom-image-zoom .custom-image-zoom-controls{
    z-index: 99;
    position: absolute;
    bottom: 30px;
    left: calc(50% - 60px);
    right: calc(50% - 60px);
    display: flex;
    width: 120px;
    background-color: rgba(255, 255, 255, 0.623);
    overflow: hidden;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.custom-image-zoom .percentage-view{
    z-index: 99;
    position: absolute;
    bottom: 30px;
    right: 50px;
    color: rgb(143, 143, 143);
    height: 40px;
    line-height: 40px;
    padding: 5px;
}
.custom-image-zoom .box-loader{
    z-index: 99;
    position: absolute; 
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 25px);
    right: calc(50% - 25px);
    bottom: calc(50% - 25px);
    left: calc(50% - 25px);
    background-color: rgba(0, 0, 0, 0.3);
    height: 50px;
    line-height: 50px;
    padding: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.box-loader .b-load{
    position: relative;
    width: 25px;
    height: 25px;
    background-image: url(/static/media/_ionicons_svg_ios-qr-scanner.56df6e21.svg);
    animation: b-spin 4s ease-in-out infinite;
    -webkit-animation: b-spin 4s ease-in-out infinite;
}
.close-zoom {
    position: absolute;
    top: 5px; right: 25px;
    margin: 0;
    padding: 5px;
    width: 35px;
    height: 35px;
    text-align: center;
    cursor: pointer;
    z-index: 9999;
}
.custom-image-zoom-controls .zoom-control-item{
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-right: 0.5px solid rgba(0,0,0,.3);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out; 
}
.custom-image-zoom-controls .zoom-control-item:last-child{
    border-right: none;
}
.custom-image-zoom-controls .zoom-control-iten:hover{
    background-color: rgba(0,0,0,0.1);
}
.zoom-control-item.zoom-in{
    background-image: url(/static/media/_ionicons_svg_ios-add.2cb95301.svg);
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
}
.zoom-control-item.zoom-out{
    background-image: url(/static/media/_ionicons_svg_ios-remove.f1869ab7.svg);
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;

}
.zoom-control-item.zoom-rotate{
    background-image: url(/static/media/_ionicons_svg_ios-refresh.428b5e91.svg);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;

}
.custom-image-zoom .image-view {
    position: relative;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    background-position: center;
    background-size: contain;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
img.opacity-0 {
    opacity: 0;
}

@-webkit-keyframes b-spin {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
    }
    100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
}

@keyframes b-spin {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
    }
    100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
}

@media (max-width: 767.98px) {
    .custom-image-zoom .percentage-view{
        z-index: 99;
        position: fixed !important;
        top: 3px;
        right: 0;
        left: 0;
        width: 100%;
        /* left: calc(137.5px /2 - 50%); */
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #F0F0F0;
        padding: 5px;
        font-weight: bold;
        font-size: 0.8rem;
    }
    .close-zoom-responsive{
        position: fixed;
        top: 5px; right: 30px;
        margin: 0;
        padding: 5px;
        z-index: 9999;
    }
    .custom-image-zoom .custom-image-zoom-controls{
        bottom: 60px;
        border: 0.5px solid rgba(0,0,0,.3);
    }
}
.btn-annuler {
    background-color: transparent;
}
.list-bank-statement {
    position: relative;
}
.card{
    position: relative;
    display: flex;
    flex: 1 1 auto;
}
.invoice-row-item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: thin solid rgba(0, 0, 0, 0);
    margin-bottom: 1px;
    padding-right: 3px;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px #E1E1E1;
    -moz-box-shadow: 0px 1px 3px  #E1E1E1;
    -webkit-box-shadow: 0px 1px 3px #E1E1E1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.invoice-row-item:hover{
    border: thin solid #E1E1E1;
}
.invoice-row-item .invoice-action{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.invoice-row-item .invoice-action:hover{
    background-color: #E1E1E1;
}
.invoice-row-item .invoice-statement{
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    flex: auto;
    font-size: 0.75rem;
}
.invoice-row-item .invoice-deliver{
    justify-content: flex-start;
    padding-left: 10px;
}
.invoice-row-item .invoice-amount{
    justify-content: flex-end;
    padding-right: 10px;
    font-weight: bold;
 }
.invoice-row-item .invoice-action {
    transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
}
.invoice-row-item:hover .invoice-unreconciled .ico-link{
    opacity: 1;
}
.invoice-row-item .invoice-detail .ico-unlink{
    display: none;
}
.invoice-row-item:hover .invoice-detail .ico-unlink{
    display: block;
    opacity: 1;
}
.invoice-row-item:hover .invoice-detail .ico-link{
    display: none;
    opacity: 0;
}

@media (max-width: 767.98px) {
    .invoice-row-item .invoice-detail .ico-unlink{
        display: block;
        opacity: 1;
    }
}
.bank-statement-details{
    position: relative;
}
.text-modal-bank{
    color: #707070;
    font-size: 0.75rem;
}
.invoice-filter{
    position: relative;
    display: block;
    border: none;
    background-color: #F0F0F0;
    border-radius: 50px;
    padding: 5px 30px 5px 10px;
    font-size: 0.75rem;
    width: 100%;
    height: 30px;
    background-image: url(/static/media/icon-search-orange.5f9d35da.svg);
    background-repeat: no-repeat;
    background-position: top 49% right 10px;
}
.invoice-filter::-webkit-input-placeholder{
    color: #707070;
}
.invoice-filter:-ms-input-placeholder{
    color: #707070;
}
.invoice-filter::-ms-input-placeholder{
    color: #707070;
}
.invoice-filter::placeholder{
    color: #707070;
}
/* :root{
    --primary-color: #177BD1;
    --secondary-color: #b5b5b5;
    --bgnav-color: #FFFFFF;
    --hovernav-color: transparent;
} */
a{ text-decoration: none; }
.sub-navlink{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2px 0;
    height: 50px;
    font-size: 0.75rem;
    background-color: var(--bgnav-color);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
ul.sub-navlink-container {
    position: relative;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding:0;
    padding-left: 10px;
    flex: 1 1 auto;
}

ul.sub-navlink-container.mp-15 {
    position: relative;
    display: block;
    list-style: none;
    margin: 0 15px;
    padding:0;
}

ul.sub-navlink-container > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 5px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.sub-navlink-container > li:hover{
    background-color: var(--hovernav-color);
}

ul.sub-navlink-container > li:not(:first-child) {
    margin-left: 15px;
}

ul.sub-navlink-container > li::before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background-color: var(--primary-color);
    opacity: 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.sub-navlink-container > li.active::before {
    height: 1.5px;
    opacity: 1;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

a.sub-navlink-item-item {
    position: relative;
    display: flex;
    cursor: pointer;
    color: var(--secondary-color);
    /* margin-bottom: 15px; */
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none !important;
    text-overflow: ellipsis;
}

a.sub-navlink-item-item:hover {
    color: var(--primary-color);
    text-decoration: none !important;
}

a.sub-navlink-active {
    color: var(--primary-color);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.sub-navlink-item-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    margin-left: 5px;
    padding: 5px;
    border-radius: 50%;
}
.tri-search{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-items: center;
}

.title-tri{
    display: flex;
    align-items: flex-end;
    color: #555555;
    font-weight: bold;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem; 
    vertical-align: bottom;
    margin-right: 9px;
}

.export-label{
   margin: 0 15px;
   font-weight: 400;
   font-size: 12px;
   font-family: "Exo",Arial, Helvetica, sans-serif;
   color: #000;
}

.export-file{
   width: 150;
   height: 30px;
   border-radius: 50px;
   border: 1px solid #FD543E;
   background-image: url(/static/media/icon-arrow-round-down.5989e353.svg);
   background-repeat: no-repeat;
   background-position: top 49% right 10%;
   background-size: 6px;
   padding: 0 10px 0 5px;
}





:root{
    --light-grey: #B5B5B5;
    --lighter-grey: #F0F0F0;
  }
  .tag {
    position: relative;
    display: inline-flex;
    height: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 4px;
    align-items: center;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    border: 0.5px solid #B5B5B5;
    border: 0.5px solid var(--light-grey);
    border-radius: 4px;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .tag.active{
    background-color: #B5B5B5;
    background-color: var(--light-grey);
  }

  .tag.pointer, .tag.active.pointer, .tag-row.pointer, .tag-row.active.pointer{
    cursor: pointer;
  }

  .tag-title {
    position: relative;
    font-size: 0.7rem;
    color: #B5B5B5;
    color: var(--light-grey);
    padding: 0px 5px;
  }
  
  .tag.active .tag-title.active{
    color: #FFFFFF;
  }
  
  .tag-active {
      background-color: #177BD1;
  }
  
  .tag-accountant {
    background-color: #B5B5B5;
    background-color: var(--light-grey);
    color: #fff !important;
  }
  
  .tag-close {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(112, 112, 112, 0.7);
      margin-left: 5px;
      padding: 3px; 
  }
  
  .tag-description{
      border-radius: 5px;
      border: 1px solid #b5b5b5;
      box-sizing: border-box;
      padding-right: 10px;
      padding-left: 10px;
      padding-top: 2px;
      padding-bottom: 1px;
      margin-right: 10px;
      margin-bottom: 10px;
      color: #b5b5b5;
      font-family: "Exo",Arial, Helvetica, sans-serif;
      font-size: 11px;
    }
  
  
    .tag-description img{
      margin-right: 5px;
    }
  
    .ct-tag{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  
  .clickable {
    cursor: pointer;
  }
  .tag .price-tag-box{
    position: relative;
    margin-left: 5px;
  }
  .tag .btn-renover, .tag-row .btn-renover{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border: none;
    background-color: #F0F0F0;
    background-color: var(--lighter-grey);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .tag-row {
    position: relative;
    padding: 2px 4px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #B5B5B5;
    color: #FFFFFF;
    width: 100%;
    height: 25px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    -ms-border-radius: 3px;
  }
  .tag-row .content-tag{
    position: relative;
    flex: 1 1 auto;
    font-size: 0.8rem;
  }
:root{
    --maxwidth-tag: 400px;
}
.InputFee{
    position: relative;
    display: flex;
    margin: 20px 0 5px 0;
    padding: 10px;
    background-color: rgba(253, 84, 62, 0.25);
}
.upload-process{
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    width: 500px;
}
button.btn-upload {
    position: relative;
    display: inline-block;
    flex: auto;
    height: 30px;
    border: none;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: red;
    font-size: 0.8rem;
    text-align: center;
}
button.btn-upload > img{ margin-right: 5px; }
.observation{
    position: relative;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0 5px;
    height: 30px;
    align-items: center;
    flex: auto;
    background-color: #FFFFFF;
    border-radius: 4px;
}
.observation > input[type="text"]{
    position: relative;
    padding: 5px 5px 5px 10px;
    height: 30px;
    border: none;
    background-color: #FFFFFF;
    font-size: 0.8rem;
    flex: auto;
    border-radius: 4px;
}
.observation > input[type="text"]::-webkit-input-placeholder{
    color: #B5B5B5;
}
.observation > input[type="text"]:-ms-input-placeholder{
    color: #B5B5B5;
}
.observation > input[type="text"]::-ms-input-placeholder{
    color: #B5B5B5;
}
.observation > input[type="text"]::placeholder{
    color: #B5B5B5;
}
button.validation{
    position: relative;
    width: 45px;
    height: 30px;
    border: none;
    background-color: #FFFFFF;
    border-radius: 4px;
}
button.validation:first-child{
    margin-right: 9px;
}
.InputFee .tags{
    position: relative;
    max-width: 400px;
    max-width: var(--maxwidth-tag);
    height: 30px;
    line-height: 30px;
    overflow-y: auto;
    overflow-x: hidden;
}
@media (max-width: 991.98px) {
    .upload-process{
        width: 300px;
    }
}
.fees-content{
    position: relative;
    display: flex;
    height: 100%;
}
.fees{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}
.fees .headerContent{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 70px;
    border-bottom: 0.5px solid rgba(125,125,125,.2);
    box-sizing: border-box;
}
.display-table {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.fee-detail {
    z-index: 10;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.attachment-prefiew {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(100% - 50px);
    background-color: rgba(0, 0, 0, .6);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.preview-file-container .btn-close {
    z-index: 999;
    position: absolute;
    top: 17px;
    right: 10px;
    padding: 0px;
    border: none;
    width: 25px;
    height: 25px;
    background-color: rgba(0, 0, 0, .6);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

/* For small screens */
@media (max-width: 575.98px) {
    .fees-date-width {
        width: 20%;
    }
    .fees-deliver-width {
        width: 45%;
    }
    .fees-amount-width {
        width: 45%;
    }
    .preview-file-container .btn-close {
        top: 50px;
        width: 50px;
        height: 50px;
    }
}

/* For medium screens */
@media (min-width: 767.98px) {
    .fees-date-width {
        width: 10%;
    }
    .fees-deliver-width {
        width: 20%;
    }
    .fees-amount-width {
        width: 25%;
    }
    .fees-vat-width {
        width: 20%;
    }
    .fees-observation-width {
        width: 25%;
    }
    .fees-attachment-width {
        width: 4%;
    }
}

/* For large screens */
@media only screen and (min-width: 991.98px) {
    .fees-date-width {
        width: 7%;
    }
    .fees-deliver-width {
        width: 12%;
    }
    .fees-amount-width {
        width: 12%;
    }
    .fees-vat-width {
        width: 12%;
    }
    .fees-observation-width {
        width: 55%;
    }
    .fees-attachment-width {
        width: 4%;
    }
}

.scan-invoices {
  position: relative;
  padding-top: 15px;
  width: 70%;
}

.scan-info {
  position: relative;
  padding-top: 15px;
  width: 30%;
}

.pdf-view {
  height: 100vh;
}

.container-img-invoice {
  background-color: rgba(0, 0, 0, .4);
  height: 100vh;
}

.container-img-invoice .container-fluid {
  transform: scale(0.7) translate(0%, -15%);
  zoom: 115%;
}
  
.container-img-invoice > div:first-of-type {
  display: flex;
  flex-direction: column;
}

.container-fluid .pan-container img {
  height: 100vh;
}

.category {
  color: #FD543E;
}

.border-bottom-1 {
  border-bottom: 1px solid #b5b5b5;
}

/* NEW STYLE */

.invoice-preview{
  --bg-color-light: #F0F0F0;
  position: relative;
  margin: 0; padding: 0;
  width: 100%;
  height: 100%;
}
.file-prefiew{
  position: relative;
  margin: 0; padding: 0;
  width: calc(100% - 300px);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.data-preview{
  position: relative;
  margin: 0; padding: 0;
  width:100%;
  height: 100%;
  background-color: #FFFFFF;
  font-size: 0.8rem;
  color: #707070 !important;
}
.preview-file-container{
  width: calc(100% - 10px); 
  /* width: 100%;*/
  height: calc(100% - 10px) ;
}
.item-row{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}
.tags-wrapper{
  display: flex;
  flex-wrap: wrap;
}
.overflow-y{
  height: 110px;
  overflow: scroll;
}

@media (max-width: 767.98px) {
  .file-prefiew{
    display: none !important;
  }
  .file-prefiew.show{
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex !important;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 9999;
  }
  .data-preview{
    width:100% !important;
  }
}
.inputarea-wrapper {
  background-color: #f1f1f1;
  border-radius: 3px;
  min-height: 35px;
  padding: 10px;
}
.inputarea-actions-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.inputarea-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #f0f0f0;
  height: 30px;
  width: 30px;
  z-index: 99;
}
.inputarea-action:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.inputarea-action:active {
  background-color: #c5c5c5;
}
.hidden {
  display: none;
}
.visible{
  display: inline;
}
.edit-mode {
  border: 1px solid #007bff;
  background-color: #fff;
}
.inputarea-edit{
  width: 100%;
}
.inputarea {
  outline: none;
  resize: none;
}
.label-status {
    position: relative;
    display: inline-block;
    padding: 5px;
    border: none;
    border-radius: 4px;
    color: #333333;
    font-size: 9px;
}

.success {
    background-color: #0C9F23 !important;
    color: #ffffff !important;
}

.danger {
    background-color: #FD543E !important;
    color: #ffffff !important;
}

.default { background-color: #f0f0f0 !important; }

.warning { background-color: #FFD58D !important; }

.primary { 
    background-color: #345283 !important; 
    color: #ffffff !important;
}
.bill-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.btn-plus.bill {
    border: 1px solid #0C9F23;
    background-color: #0C9F23;
}

.edit-bill-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.bill-content-box{ 
    display: flex;
    flex-direction: column;
    background-color: #FDFDFD;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.btn-cancel-bill{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #FDFDFD;
    border: none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    color: #B5B5B5;
}

.btn-save-bill{
    background-color: rgba(0, 0, 0, 0);
    border: thin solid #0C9F23;
    color: #707070;
    font-size: 0.75rem;
    height: 30px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.btn-plus-bill{
    width: 30px;
    height: 30px;
    background-color: #0C9F23;
    border: none;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.btn-edit-bill{
    color: #707070;
    font-size: 0.75rem;
    width: 30px;
    height: 30px;
    background-color: #FDFDFD;
    border: none;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.total-ttc{ font-weight: bold; color: #707070; }

.box-infos{
    position: relative;
    margin: 0;
    padding: 0;
    width: 290px;
}
.logo-area{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    overflow: hidden;
    background-color: #F0F0F0;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.label-title{
    color: #A7A7A7;
    font-weight: bold;
    font-size: 0.9rem;
}
.label-desc{
    color: #A7A7A7;
    font-weight: normal;
    font-size: 0.7rem;
}
.form-box{
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 20px;
}
.form-box input:not([type="checkbox"]), .form-box select{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    padding-left: 5px;
    width: 100%;
    height: inherit;
    border: none;
    background-color: transparent;
    font-size: 0.65rem;
    color: #707070;
}
.form-box .form-inline{
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex: 1 1;
}
.form-box input::-webkit-input-placeholder{
    font-size: 0.65rem;
    color: #707070;
}
.form-box input:-ms-input-placeholder{
    font-size: 0.65rem;
    color: #707070;
}
.form-box input::-ms-input-placeholder{
    font-size: 0.65rem;
    color: #707070;
}
.form-box input::placeholder{
    font-size: 0.65rem;
    color: #707070;
}
.form-box .react-datepicker-wrapper{
    position: relative;
    display: flex;
    flex: 1 1;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 20px !important;
}
.form-box .react-datepicker-wrapper input{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}
.txt-title{
    color: #707070;
    font-weight: bold;
    text-transform: uppercase;
}

.bill-display .dashed{
    border-width: 0.5px;
    border-style: dashed;
    border-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.bill-display .top-dashed{
    border-top-width: 0.5px;
    border-top-style: dashed;
    border-top-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .bill-display .left-dashed{
    border-left-width: 0.5px;
    border-left-style: dashed;
    border-left-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .bill-display .right-dashed{
    border-right-width: 0.5px;
    border-right-style: dashed;
    border-right-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .bill-display .bottom-dashed{
    border-bottom-width: 0.5px;
    border-bottom-style: dashed;
    border-bottom-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

.bill-display:hover .dashed{
    border-color: rgba(197, 197, 197, 1);
}
.bill-display:hover .left-dashed{
    border-left-color: rgba(197, 197, 197, 1);
}
.bill-display:hover .bottom-dashed{
    border-bottom-color: rgba(197, 197, 197, 1);
}
.bill-display:hover .right-dashed{
    border-right-color: rgba(197, 197, 197, 1);
}
.bill-display:hover .top-dashed{
    border-top-color: rgba(197, 197, 197, 1);
}

.table-bill{
    position: relative;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}


.table-bill thead tr {
    background-color: #DEDEDE;
    border-radius: 5px;
}

.table-bill thead tr th {
    font-size: 0.75rem;
    font-weight: bold;
    color: #707070;
    padding: 0 7px;
    height: 35px;
    overflow: hidden;
}

.table-bill tbody tr{
    position: relative;
}

.table-bill tr th span, .table-bill tr td {
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}
.plr-10{
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.btn-add-row {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: none;
    background-color: #0C9F23;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 0.7rem;
}
.text-total-row{
    font-size: 0.75rem;
    font-weight: bold;
    color: #707070;
    align-items: center;
}
.label-title{
    font-size: 0.85rem;
    font-weight: 300;
    color: #707070;
}
.text-amount{
    font-size: 0.9rem;
    font-weight: bold;
    color: #707070;
}
.bill-primary-color{ color: #0C9F23 }
.bg-bill-primary-light{ background-color: rgba(12, 159, 35, 0.05); }
.remove-row-btn{
    position: absolute;
    top: 1px;
    left: -26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    border: none;
    background-color: #FFD3D3;
    cursor: pointer;
    z-index: 999;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}
.remove-row-btn.fades{
    position: absolute;
    top: 1px;
    left: -26px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    border: none;
    background-color: #FFD3D3;
    cursor: pointer;
    z-index: 999;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.table-bill tbody tr {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.table-bill tbody tr:hover .remove-row-btn, .bill-footer .form-box:hover .remove-row-btn {
    left: -4px;
}
.table-bill tbody tr:hover .remove-row-btn.fades, .bill-footer .form-box:hover .remove-row-btn.fades {
    left: -4px;
    opacity: 1;
}

.bill-footer .form-box{
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}


.pl-push-hover {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.table-bill tbody tr:hover .pl-push-hover, .bill-footer .form-box:hover .pl-push-hover {
    padding-left: 25px;
}
.alert.bill-alert{
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 25px;
    font-size: 0.7rem;
    margin-bottom: 0;
    background-color: rgba(255,0,0,0.1);
    color: #FF0000;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.alert.bill-alert button.close{
    margin-left: 5px;
    color: #FF0000;
}
.form-box.msg-error, tr.msg-error {
    background-color: rgba(255,0,0,0.1);
}
.msg-table-error{
    font-size: 0.7rem;
    color: #FF0000;
}
.customer{
    position: relative;
    margin-top: 130px;
}
.bill-header-right{
    position: relative;
}
.w-unit-mesure{ width: 100px; }
.w-qty{ width: 50px; }
.w-pu{ width: 125px; }
.w-vat{ width: 80px; }
.w-pt{ width: 125px; }
.hoverlay-controls{
    display: none;
}
.font-small{ font-size: 0.65rem; }
.hoverlay-controls.offset{
    z-index: 99;
    position: fixed;
    top: 90px;
    left: 135px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F8F8F8;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.sm-txt-light{
    font-size: 0.65rem;
    color: #707070;
}

@media (max-width: 575.98px) { 
    .bill-header-right{
        margin-top: 10px;
    }
    .box-infos{
        width: 100%;
    }
    .customer{
        margin-top: 10px;
    }
    .table-bill thead tr th {
        font-size: 0.65rem;
        font-weight: bold;
        color: #707070;
        padding: 0 4px;
        overflow: hidden;
    }
    
    .table-bill tr th span, .table-bill tr td {
        max-width: 100px;
    }
    .alert.bill-alert{
        font-size: 0.55rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
    }
    .hoverlay-controls.offset{
        left: 15px;
        right: 15px;
    }

}

@media (max-width: 767.98px) { 
    .hoverlay-controls.offset{
        left: 15px;
        right: 15px;
    }
}


.form-box .dropdown{
    width: 100%;
}
.custom-dropdown{
    position: relative;
}
.custom-dropdown-btn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    font-size: 0.65rem;
    color: #707070;
    width: 100%;
    cursor: pointer;
}
.custom-dropdown-menu{
    position: relative;
    width: 100%;
}
.custom-dropdown-menu ul.list-unstyled{
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
}
.custom-dropdown-menu ul.list-unstyled a{
    font-size: 0.65rem;
    padding: 4px 6px;
}
.custom-dropdown-menu input.custom-dropdown-filter-input{
    position: relative;
    display: block;
    margin: 0 5px;
    padding: 4px 1px;
    font-size: 0.65rem;
    border: thin solid #F0F0F0;
    box-sizing: border-box;
    width: calc(100% - 10px) ;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.custom-dropdown-menu .dropdown-item.active{
    background-color: rgba(12, 159, 35, 0.05);
    color: #0C9F23;
}
input.new-elm::-webkit-input-placeholder{
    padding: 5px !important;
    background-color: rgba(12, 159, 34, 0.068);
    color: #0C9F23;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
}
input.new-elm:-ms-input-placeholder{
    padding: 5px !important;
    background-color: rgba(12, 159, 34, 0.068);
    color: #0C9F23;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
}
input.new-elm::-ms-input-placeholder{
    padding: 5px !important;
    background-color: rgba(12, 159, 34, 0.068);
    color: #0C9F23;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
}
input.new-elm, input.new-elm::placeholder{
    padding: 5px !important;
    background-color: rgba(12, 159, 34, 0.068);
    color: #0C9F23;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
}
.social-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.right-content-fluid{
    margin-right: -450px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.showTab{
    margin-right: 0;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.social-detail {
    z-index: 10;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 175px);
    background-color: #FFFFFF;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.social-container {
    /* flex-wrap: nowrap; */
    position: relative;
    flex-grow: 1;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.social-content .social-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2px 0;
    height: 50px;
    background-color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.social-content .content-menu{
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
}
.social-content .active-minimized{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 1;
    margin: 0;
    padding: 0;
    width: 30px;
    overflow: hidden;
}
.social-content .active-minimized button{
    position: relative;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}
.social-content .active-minimized .active-menu-item{
    position: relative;
    margin: 0;
    padding: 10px 0;
    width: 30px;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #177BD1;
    font-size: 0.9rem;
}
ul.social-menu-container {
    position: relative;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding:0;
    padding-left: 10px;
    flex: 1 1 auto;
}

ul.social-menu-container.mp-15 {
    position: relative;
    display: block;
    list-style: none;
    margin: 0 15px;
    padding:0;
}

ul.social-menu-container > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0 5px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.social-menu-container > li:not(:first-child) {
    margin-left: 15px;
}

ul.social-menu-container > li::before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #177BD1;
    opacity: 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

ul.social-menu-container > li.active::before {
    height: 3px;
    opacity: 1;
}

a.social-menu-item {
    height: inherit;
    width: inherit;
    position: relative;
    cursor: pointer;
    color: #b5b5b5;
    /* margin-bottom: 15px; */
    font-size: 0.75rem;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

a.social-menu-item:hover {
    color: #177BD1;
    text-decoration: none;
}

a.social-menu-active {
    color: #177BD1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.active-minimized > .item-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #177BD1;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0;
    padding: 5px;
    border-radius: 50%;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
}

.item-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #177BD1;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    margin-left: 5px;
    padding: 5px;
    border-radius: 50%;
}

.page-social-content{
    flex: 1 1; 
}
.tdatas{
    position: relative;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.btn-hide {
    border: none;
    background-color: transparent;
}
.head-option-left{
    position: relative;
    display: flex;
    align-items: center;
}

.btn-plus {
    width: 27px;
    height: 28px;
    border: 1px solid #177BD1;
    background-color: #177BD1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-period-title {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    height: 28px;
    border: 0.5px solid #177BD1;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
}

.btn-period-title span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

.btn-period-share{
    position: relative;
    display: flex;
    height: 28px;
    background-color: #177BD1;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
}

.btn-primary-2{
    position: relative;
    height: 30;
    border: none;
    background-color: #177BD1;
    border-radius: 3px;
}

.vertical {
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
}

.flex-part {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    
}

@media (max-width: 767.98px) {
    .flex-part {
        padding-bottom: 30px;
    }
}

.w-1 {
    width: 2%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-40 { 
    width: 40%;
}

.w-15 {
    width: 13%;
}

.w-80 {
    width: 80%;
}

.w-77 {
    width: 80%;
}

.w-90 {
    width: 99.5%;
}

.w-100 {
    width: 100%;
}
.width100{ width: 100px !important; }
.width150{ width: 150px !important; }
.width200{ width: 200px !important; }
.min-width100{ min-width: 100px !important; }
.min-width150{ min-width: 150px !important; }
.min-width200{ min-width: 200px !important; }
.pl-15{
    padding-left: 15 !important;
}
.pr-15{
    padding-right: 15 !important;
}
.width-0{
    width: 0 !important;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.minimized-btn-in {
    animation: minimize-in 0.3s ease-in-out;
    -ms-animation: minimize-in 0.3s ease-in-out;
    -moz-animation: minimize-in 0.3s ease-in-out;
    -o-animation: minimize-in 0.3s ease-in-out;
    -webkit-animation: minimize-in 0.3s ease-in-out;
    -webkit-animation: minimize-in 0.3s ease-in-out;
}
.minimized-btn-out {
    animation: minimize-out 0.3s ease-in-out;
    -ms-animation: minimize-out 0.3s ease-in-out;
    -moz-animation: minimize-out 0.3s ease-in-out;
    -o-animation: minimize-out 0.3s ease-in-out;
    -webkit-animation: minimize-out 0.3s ease-in-out;
    -webkit-animation: minimize-out 0.3s ease-in-out;
}
.width-minimized{
    margin-left: -140px;
    /* width: 30px !important; */
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.width-minimized button.minimize-btn {
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.slide-in-right{
    animation: slide-in-right 0.3s ease-in-out 0.3s;
    -webkit-animation: slide-in-right 0.3s ease-in-out 0.3s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}
.slide-out-left{
    animation: slide-out-left 0.3s ease-in-out;
    -webkit-animation: slide-out-left 0.3s ease-in-out;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}
.rotate-180{
    animation: rotate-180 0.3s ease-in-out 0.3s;
    -webkit-animation: rotate-180 0.3s ease-in-out 0.3s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

@-webkit-keyframes minimize-in{
    to{
        width: 30px;
    }
}

@keyframes minimize-in{
    to{
        width: 30px;
    }
}
@-webkit-keyframes minimize-out{
    to{
        width: 170px;
    }
}
@keyframes minimize-out{
    to{
        width: 170px;
    }
}
@-webkit-keyframes slide-in-right{
    from{
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
    }
    to{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
}
@keyframes slide-in-right{
    from{
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
    }
    to{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
}
@-webkit-keyframes slide-out-left{
    from{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
    to{
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
    }
}
@keyframes slide-out-left{
    from{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
    to{
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
    }
}
@-webkit-keyframes rotate-180 {
    from{
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    to{
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
}
}
@keyframes rotate-180 {
    from{
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    to{
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
}
}

@-webkit-keyframes show-tab {
    to{
        margin-right: 0;
    }
}

@keyframes show-tab {
    to{
        margin-right: 0;
    }
}

.fluid-content-data {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

/* Medium devices (tablets, less than 992px) */
/* @media (max-width: 991.98px) {
    .social-detail.w-450, .social-detail {
        width: 310px;
    }
} */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .social-detail.w-450, .social-detail {
        position: fixed;
        top: 75px;
        left: 0;
        bottom: 0;
        width: 100vw !important;
        height: calc(100vh - 125px);
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 9;
    }
    ul.social-menu-container > li {
        padding: 0 2.5px;
    }
    .ul.social-menu-container > li a {
        font-size: 0.6rem;
    }
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .social-detail {
        position: fixed;
        top: 75px;
        right: 0;
        bottom: 0;
        width: 440px;
        height: calc(100vh - 125px);
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 9;
        box-shadow: -3px 0 5px rgba(0,0,0,0.1);
    }
}
.modal-detail{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: #fff;
    overflow: hidden;
}
.modal-active {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 450px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.modal-detail-content {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}
.details-header {
    font-size: 15;
    font-weight: bold;
}
.btn-close {
    padding: 0px;
    border: none;
    background-color: transparent;
}

.btn-detail {
    height: 30px;
    padding: 0px !important;
}
.nav{
    flex-wrap: nowrap;
}

.nav-staff-info {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: row !important;
    border-bottom: 0.4px solid rgba(112, 112, 112, 0.3);
    margin-top: 20px;
}
.MuiTabs-flexContainer {
    border-bottom: 0.3px solid rgba(112, 112, 112, 0.2);
}
.MuiTab-wrapper{
    font-weight: bold;
}
.MuiTab-root {
    font-family: 'Exo' !important;
    text-transform: none !important;
    color: #707070 !important;
}
.nav-staff-info .nav-link.active {
    background-color: none;
    color: #177BD1;
    border-bottom: 4px solid rgba(112, 112, 112, 0.1);
}

.nav-staff-info .nav-link {
    border-radius: 0px;
    color: #707070;
}

.nav-link {
    padding: 10px 0px;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
    width: 105px;
}

.container-btn {
    border-top: 0.2px solid rgba(112, 112, 112, 0.2);
}

.btn-back {
    background-color: transparent;
    border: 1px solid #177BD1;
    border-radius: 3px
}

.btn-next {
    height: 30px;
    background-color: #177BD1;
    border: 1px solid #177BD1;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.w-46 {
    width: 46%;
}

.tag-container{
    flex-wrap: wrap;
}

.box-child {
    padding-left: 5px;
    border: 1px solid #b5b5b5;
    border-radius: 3px;
    min-height: 32px;
    margin-bottom: 10px;
    margin-top: 1px;
}

.box-listChild {
    padding: .375rem .25rem;
}
.modal-child-title{
    margin: 1rem 0rem;
    color: #177BD1;
}
.nb-child {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #177BD1;
    color: #fff;
    padding: 3px;
    font-size: 10px;
    border-radius: 50%;
    margin-left: 5px;
    width: 15px;
    height: 15px;
}
.modal-child-btn{
    margin-top: 50px;
}
.btn {
    height: 30px;
    font-size: 12px;
}
.btn:focus {
    box-shadow: none;
}
.btn-annuler {
    text-align: center;
    background-color: #b5b5b5;
    color: #fff;
}
.btn-annuler:hover {
    color: #fff;
}
.btn-validate {
    text-align: center;
    border: 0.2px solid #177BD1;
    color: #177BD1;
    background-color: transparent;
}
.btn-validate:hover {
    color: #177BD1;
}

.pdf-box {
    background-color: #E8E8E8;
    border-radius: 3px;
}
.pdf-title {
    font-size: 10px;
    padding-left: 5px;
}

.swal-custom-container {
    background-color: red
}
.swal-custom-notif{
    color: #555555 !important;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.9rem !important;
}
.swal-custom-icon {
    width: 24px;
    height: 24px;
}
/* Modal content preview */
.modal-detail-preview{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-detail-preview .header-preview{
    position: relative;
    padding: 10px;
    background-color: #F8F8F8;
}
.modal-detail-preview .ico-content{
    position: relative;
    display: flex;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
}
.modal-detail-preview .header-preview-title{
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    color: #707070;
}
.modal-detail-preview .content-preview{
    position: relative;
    border-top: 0.4px solid rgba(112, 112, 112, 0.2);
}
.modal-detail-preview .item-separator{
    position: relative;
    /* display: block; */
    border-top: 0.4px solid rgba(112, 112, 112, 0.2);
}
div.px-0{
    /* overflow-x: hidden !important; */
}
div.px-0 > div{
    padding-right: 1em;
    /* overflow: hidden; */
}
.labeled{
    background-color: #177BD1;
    padding: 1px 5px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    color: #FFFFFF;
    font-size: .65rem;
    font-weight: bold;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.txt-primary{
    color: #177BD1;
}

.payTitle {
    font-size: 12px;
    margin-bottom: 0;
    font-weight: bold;
    color: #707070;
}
.react-swipeable-view-container{
    height: 100% !important;
}
div[data-swipeable="true"]{
    overflow-x: hidden !important;
}
div[data-swipeable="true"], div[data-swipeable="true"] > div:first-child{
    display: flex;
    flex-direction: column;
}
div[data-swipeable="true"] > div:first-child{
    flex: 1 1 auto !important;
}
.modal-detail-preview div.MuiTabs-root + div {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto !important;
    height: 30vh;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.payslip-tab div.MuiTabs-root + div {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto !important;
    height: 30vh;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 112px !important;
    }
}
@media (max-width: 575.98px) {
    .modal-detail{
        width: 100%;
    }
    .modal-detail-preview{
        margin-top: 0 !important;
    }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .modal-detail{
        width: 100%;
    }
    .modal-detail-preview{
        margin-top: 0 !important;
    }
}
.input-box {
    margin-bottom: 10px;
}
.active-label {
    color: #177BD1 !important;
}
.form-control {
    padding: .375rem .25rem;
}
.form-control:focus {
    outline: none !important;
    box-shadow: none;
}
.input-icon{
    height: 30px;
    width: 30px;
}
.input-group {
    border: 1px solid #b5b5b5;
    border-radius: 3px;
    padding-left: 5px;
}
.label-input {
    display: flex;
    /* justify-content: space-between;
    align-items: center; */
    color: #707070;
    font-size: 10px;
    font-family: 'Exo-light', sans-serif
}
.label-child {
    color: #707070;
    font-size: 12px;
    font-family: 'Exo-light', sans-serif
}
.extra-label {
    background-color: #177BD1;
    border-radius: 3px;
    color: #ffffff;
    font-size: 10px;
    font-weight: bold;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
}
.input-box .input {
    height: 30px;
    border: none;
    background-color: transparent !important;
    color: #707070;
    font-size: 12px;
    font-family: 'Exo-light', sans-serif
}
.input:focus {
    color: #177BD1 !important;
}
.input-box .input-datepicker {
    position: relative;
    border: none !important;
    color: #707070;
    font-size: 0.75rem;
}
.input-box .input-datepicker:focus {
    color: #177BD1 !important;
}

.required {
    color: #C60000;
    font-size: 15px;
}

.input-group:focus {
    outline: none !important;
    box-shadow: none;
    border: 1px solid #177BD1 !important;
}

.input-focused {
    border: 1px solid #177BD1!important;
}

.form-check-label {
    font-size: 10px;
    color: #707070;
}

select {
    -webkit-appearance: none;
    background-image: url(/static/media/arrow-down.28b03b39.svg);
    background-repeat: no-repeat;
    background-position: top 50% right 10px;
    background-color: #707070;
}

.input-readonly {
    background-color: #EDEAEA;
    border: none;
}
.input-error {
    border-color: #C60000 !important;
}
.input-hasError {
    margin-left: 5px;
    font-size: 8px;
    color: #C60000;
}
.card-bank-preview{
    background-color: #F8F8F8;
    padding: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
}
.card-bank-preview:hover{
    background-color: rgba(0,0,0, .1);
}
.card-bank-preview.avtive{
    background-color: rgba(23,123,209,.05);
}
.card-bank-preview.avtive:hover{
    background-color: rgba(23,123,209,.1);
}
/* CSS property right to left transition */

.right-to-left {
    display: block;
    margin-left: 500px;
    background-color: #fff;
    border: 1px solid white;
    width: 100%;
    overflow: hidden;
}

.right-to-left-entering {
    transition: left 200ms, right 200ms;
}

.right-to-left-entered {
    margin-left: 0px;
    transition: left 200ms, right 200ms;
}

.right-to-left-exiting {
    margin-left: 500px;
    transition: left 200ms, right 200ms;
    display: none
}

.right-to-left-exited {
    margin-right: 500px;
    /* transition-duration: 1s; */
    /* display: none; */
}

/* CSS property left to right transition */

.left-to-right {
    display: block;
    margin-right: 500px;
    background-color: #fff;
    border: 1px solid #fff;
    width: 100%;
    overflow: hidden;
}

.left-to-right.entering {
    transition-duration: 1s;
}

.left-to-right.entered {
    margin-right: 0px;
    transition-duration: 1s;
}

.left-to-right.exiting {
    display: none;
}

/* CSS property bottom to top transition */

.bottom-to-top {
    display: block;
    margin-top: 100vh;
    background-color: #fff;
    border: 1px solid white;
    width: 100%;
}

.bottom-to-top-entering {
    transition-duration: 500ms;
}

.bottom-to-top-entered {
    margin-top: 0px;
    transition-duration: 500ms;
}

.bottom-to-top-exiting {
    margin-top: 100vh;
    transition-duration: 500ms;
    display: none;
}

.bottom-to-top-exited {
    display: none;
}

/* CSS property top to bottom transition */

.top-to-bottom {
    display: block;
    margin-bottom: 100vh;
    background-color: #fff;
    border: 1px solid white;
    width: 100%;
    overflow: hidden;
}

.top-to-bottom-entering {
    transition-duration: 900ms;
}

.top-to-bottom-entered {
    margin-bottom: 0px;
    transition-duration: 900ms;
}

.top-to-bottom-exiting {
    margin-bottom: 100vh;
    transition-duration: 900ms;
    display: none;
}

/* CSS property custom transition */
.card-preview{
    background-color: #F8F8F8;
    padding: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
}
  .card-preview:hover{
    background-color: rgba(0,0,0, .1);
  }
.allowances{
    position: relative;
}
.allowances .input-label{
    position: relative;
    margin-bottom: 5px;
    font-family: 'Exo Light', Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    color: #707070;
}
.allowances .form-control:focus, .allowances  .form-control:focus ~ .input-label{
    border-color: #177BD1 ;
    color: #177BD1;
}
.allowances input[type="text"], .allowances input[type="number"]{
    font-family: 'Exo Light',Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    color: #707070;
}

.allowances .content-prime{
    position: relative;
    display: block;
    padding: 5px;
    min-height: 100px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #F0F0F0;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.detail-container {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}
.detail-container .close-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.detail-container .header-detail-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
    padding: 0 10px;
    height: 40px;
}
.header-detail-container .detail-container-title {
    font-size: 1rem;
    font-weight: 800;
    color: #707070;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    
}
.row-group{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
}
.col-display{
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1 1 auto;
}
.bg-dark-grey {
    background-color: #707070;
}
.round-1{
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
}
.round-2{
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.round-3{
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.round-4{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.round-5{
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.btn-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.w-140{ width: 140px; }
.content-details{
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    flex: 1 1 auto;
    overflow: hidden;
}
.title-content-section {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 10px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #707070;
}
.row-detail-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    height: 40px;
}
.options-control-header{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.head-option-left button span {
    position: relative;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 575.98px) {
    .payslip-staff-width {
        width: 40%;
    }
    
    .payslip-attachment-width {
        width: 40%;
    }
    .payslip-status-width {
        width: 25%;
    }
 }
@media (max-width: 768.98px){
    .payslip-period-width {
        width: 50%;
    }
    .payslip-payroll-width {
        width: 50%;
    }
}
@media(min-width:575.98px) and (max-width:767.98px) {
    .payslip-staff-width {
        width: 60%;
    }
    .payslip-attachment-width {
        width: 30%;
    }
    .payslip-status-width {
        width: 20%;
    }
}

@media (min-width: 767.98px) {
    .payslip-period-width {
        width: 25%;
    }
    .payslip-payroll-width {
        width: 25%;
    }
    .payslip-declaration-width {
        width: 25%;
    }
    .payslip-tax-width {
        width: 25%;
    }

    .payslip-staff-width {
        width: 60%;
    }
    .payslip-attachment-width {
        width: 30%;
    }
    .payslip-status-width {
        width: 20%;
    }
}
@media (min-width:991.98px) {
    .payslip-staff-width {
        width: 35%;
    }
    .payslip-attachment-width {
        width: 12%;
    }
    .payslip-absence-width {
        width: 20%;
    }
    .payslip-status-width {
        width: 9%;
    }
    .payslip-hsupp-width {
        width: 20%;
    }
    .payslip-allowance-width {
        width: 10%;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .options-control-header{
        flex-direction: column-reverse;
        justify-content: center;
    }
    .options-control-header .head-option-left{
        flex-direction: column;
    }
    .options-control-header .head-option-left button:not(:first-child){
        margin: 5px 0 !important;
        padding: 5px 0;
        width: 100%;
    }

    .options-control-header .head-option-left button:first-child{
        margin: 10px 0 5px 0 !important;
        padding: 5px 0;
        width: 100%;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
    .options-control-header .ct-search{
        flex: 1 1 auto !important;
    }
}
.btn-disabled{
    cursor: not-allowed !important;
    background-color: #CCCCCC !important;
}

.drop-down-div {
    width: 200px;
    margin-left: 16px;
}
.drop-down-txt {
    margin-top: 5px;
    font-size: 12px;
}
.drop-down-btn {
    text-align: left;
    height: 30px;
    width: 100%;
    border: solid 0.3px;
    border-radius: 3px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
}
.drop-down-btn::after {
    margin-right: 0.745em;
    margin-top: .5em;
    vertical-align: .255em;
    content: "";
    float: right;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.item-line .active {
    background-color: rgba(23, 123, 209, 0.1) !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .drop-down-div {
        width: 100% !important;
        margin-left: 0;
    }
    .drop-down-btn{
        padding-left: 10px !important;
    }
    .dropdown-menu{
        width: 100% !important;
    }
    .dropdown-menu .dropdown-item {
        padding-left: 10px !important;
    }
}
    

.payslip-tab{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    /* flex-shrink: 1; */
    font-size: 0.8rem;
}

div.ctn-tab {
    padding: 20px !important;
}

.payslip-tab .sub-title-tab{
    position: relative;
    margin: 5px 0 10px 0;
    height: 30px;
    line-height: 30px;
    font-family: 'Exo ExtraLight', Arial, Helvetica, sans-serif;
    color: #177BD1;
    border-bottom: 0.4px solid rgba(181, 181, 181, 0.7);
}
.payslip-tab .input-label{
    position: relative;
    margin-bottom: 5px;
    font-family: 'Exo Light', Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    color: #707070;
}
.payslip-tab input[type="text"], .payslip-tab input[type="number"]{
    font-family: 'Exo Light',Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    color: #707070;
}
.payslip-tab .add-btn{
    font-size: 0.75rem;
    color: #ffffff;
    border: none;
    background-color: #177BD1;
    border-radius: 3px;
}
.payslip-tab .add-btn:focus{
    color: #ffffff !important;
}
.payslip-tab .form-control:focus, .payslip-tab  .form-control:focus ~ .input-label{
    border-color: #177BD1 ;
    color: #177BD1;
}
.payslip-tab .s-dropdown{
    width: 100%;
    border: 0.5px solid #B5B5B5;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.payslip-tab .dropdown-toggle{
    width: 100%;
    text-align: right;
}
.payslip-tab .dropdown-menu {
    margin-left: -5px;
    width: calc(99% + 5px);
    font-size: 0.75rem;
    font-family: 'Exo', Arial, Helvetica, sans-serif !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
}
.payslip-tab .dropdown-item{
    padding-left: 5px;
    padding-right: 5px;
}
.payslip-tab .content-prime{
    position: relative;
    display: block;
    padding: 5px;
    height: 200px;
    background-color: #F0F0F0;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.table-cumuls {
    position: relative;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2px;
    margin-bottom: 1rem;
}
.table-cumuls > thead {
    background-color: #F4F4F4;
}
.table-cumuls tr {
    height: 30px;
    box-shadow: 0 0 1px 0 #B5B5B5;
}
.table-cumuls th:not(:first-child):not(:last-child) {
   border-top: 0.5px solid #B5B5B5;
   border-bottom: 0.5px solid #B5B5B5;
}
.table-cumuls th:first-child {
   border: 0.5px solid #B5B5B5;
   border-right: none;
}
.table-cumuls th:last-child {
   border: 0.5px solid #B5B5B5;
   border-left: none;
}
.table-cumuls td:not(:first-child) {
   border: 0.5px solid #B5B5B5;
   border-left: none;
}
.table-cumuls td:first-child {
    border: 0.5px solid #B5B5B5;
 }
.table-cumuls th {
    border: none;
    font-size: 0.7rem;
    color: #707070;
    font-weight: 300;
    text-align: left;
}
.table-cumuls td {
    font-size: 0.7rem;
    color: #707070;
    font-weight: 600;
    text-align: left;
    padding-left: 0.2rem;
    height: 30px;
}
 input[role="combobox"]{ /*for autocomplete component */
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
    width: 100%;
    border: none;
    background-color: transparent;
}

.adder{
    position: relative;
    display: flex;
    min-width: 25px;
    height: 25px;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}
.adder > button.btn-add {
    position: relative;
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: thin solid #177BD1;
    border-radius: 3px 0 0 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
}
/* .adder > button.btn-add:hover {
    background-color: rgb(22, 97, 163);
} */
.adder > span.value {
    position: relative;
    width: 25px;
    height: 25px;
    background-color: #177BD1;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-right: 0.3px solid rgba(255,255,255,0.7); */
}
.adder > span.value:last-child {
    border-radius: 0  3px 3px 0;
    border-right: none;
}
.taxe-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.table-taxes{
    position: relative;
    width: 100%;
    border-collapse: collapse;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.table-taxes thead tr{
    border: 0.5px solid rgb(230, 230, 230);
}

.table-taxes thead tr td:not(:last-child){
    border-right: 0.5px solid rgb(230, 230, 230);
}
.table-taxes tbody tr td:first-child {
    border-left: 0.5px solid rgb(230, 230, 230);
    border-bottom: 0.5px solid rgb(230, 230, 230);
    border-right: 0.5px solid rgb(230, 230, 230);
}

.table-taxes thead tr td, .table-taxes tbody tr td {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.btn-plus.taxe {
    border: 1px solid #09B6CC;
    background-color: #09B6CC;
}

.edit-taxe-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.taxe-content-box{ 
    display: flex;
    flex-direction: column;
    background-color: #FDFDFD;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.btn-cancel-taxe{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: #FDFDFD;
    border: none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    color: #B5B5B5;
}

.btn-save-taxe{
    background-color: rgba(0, 0, 0, 0);
    border: thin solid #0C9F23;
    color: #707070;
    font-size: 0.75rem;
    height: 30px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.total-ttc{ font-weight: bold; color: #707070; }

.box-infos{
    position: relative;
    margin: 0;
    padding: 0;
    width: 290px;
}
.logo-area{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    overflow: hidden;
    background-color: #F0F0F0;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.label-title{
    color: #A7A7A7;
    font-weight: bold;
    font-size: 0.9rem;
}
.label-desc{
    color: #A7A7A7;
    font-weight: normal;
    font-size: 0.7rem;
}
.form-box{
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 20px;
}
.form-box input:not([type="checkbox"]), .form-box select{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    padding-left: 5px;
    width: 100%;
    height: inherit;
    border: none;
    background-color: transparent;
    font-size: 0.65rem;
    color: #707070;
}
.form-box .form-inline{
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex: 1 1;
}
.form-box input::-webkit-input-placeholder{
    font-size: 0.65rem;
    color: #707070;
}
.form-box input:-ms-input-placeholder{
    font-size: 0.65rem;
    color: #707070;
}
.form-box input::-ms-input-placeholder{
    font-size: 0.65rem;
    color: #707070;
}
.form-box input::placeholder{
    font-size: 0.65rem;
    color: #707070;
}
.form-box .react-datepicker-wrapper{
    position: relative;
    display: flex;
    flex: 1 1;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 20px !important;
}
.form-box .react-datepicker-wrapper input{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}
.txt-title{
    color: #707070;
    font-weight: bold;
    text-transform: uppercase;
}

.taxe-display .dashed{
    border-width: 0.5px;
    border-style: dashed;
    border-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.taxe-display .top-dashed{
    border-top-width: 0.5px;
    border-top-style: dashed;
    border-top-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .taxe-display .left-dashed{
    border-left-width: 0.5px;
    border-left-style: dashed;
    border-left-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .taxe-display .right-dashed{
    border-right-width: 0.5px;
    border-right-style: dashed;
    border-right-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .taxe-display .bottom-dashed{
    border-bottom-width: 0.5px;
    border-bottom-style: dashed;
    border-bottom-color: rgba(230, 230, 230, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

.taxe-display:hover .dashed{
    border-color: rgba(197, 197, 197, 1);
}
.taxe-display:hover .left-dashed{
    border-left-color: rgba(197, 197, 197, 1);
}
.taxe-display:hover .bottom-dashed{
    border-bottom-color: rgba(197, 197, 197, 1);
}
.taxe-display:hover .right-dashed{
    border-right-color: rgba(197, 197, 197, 1);
}
.taxe-display:hover .top-dashed{
    border-top-color: rgba(197, 197, 197, 1);
}

.table-taxe{
    position: relative;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}


.table-taxe thead tr {
    background-color: #DEDEDE;
    border-radius: 5px;
}

.table-taxe thead tr th {
    font-size: 0.75rem;
    font-weight: bold;
    color: #707070;
    padding: 0 7px;
    height: 35px;
    overflow: hidden;
}

.table-taxe tbody tr{
    position: relative;
}

.table-taxe tr th span, .table-taxe tr td {
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}
.plr-10{
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.btn-add-row {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: none;
    background-color: #0C9F23;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 0.7rem;
}
.text-total-row{
    font-size: 0.75rem;
    font-weight: bold;
    color: #707070;
    align-items: center;
}
.label-title{
    font-size: 0.85rem;
    font-weight: 300;
    color: #707070;
}
.text-amount{
    font-size: 0.9rem;
    font-weight: bold;
    color: #707070;
}
.taxe-primary-color{ color: #0C9F23 }
.bg-taxe-primary-light{ background-color: rgba(12, 159, 35, 0.05); }
.remove-row-btn{
    position: absolute;
    top: 1px;
    left: -26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    border: none;
    background-color: #FFD3D3;
    cursor: pointer;
    z-index: 999;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}
.remove-row-btn.fades{
    position: absolute;
    top: 1px;
    left: -26px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    border: none;
    background-color: #FFD3D3;
    cursor: pointer;
    z-index: 999;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.table-taxe tbody tr {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.table-taxe tbody tr:hover .remove-row-btn, .taxe-footer .form-box:hover .remove-row-btn {
    left: -4px;
}
.table-taxe tbody tr:hover .remove-row-btn.fades, .taxe-footer .form-box:hover .remove-row-btn.fades {
    left: -4px;
    opacity: 1;
}

.taxe-footer .form-box{
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}


.pl-push-hover {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.table-taxe tbody tr:hover .pl-push-hover, .taxe-footer .form-box:hover .pl-push-hover {
    padding-left: 25px;
}
.alert.taxe-alert{
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 25px;
    font-size: 0.7rem;
    margin-bottom: 0;
    background-color: rgba(255,0,0,0.1);
    color: #FF0000;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.alert.taxe-alert button.close{
    margin-left: 5px;
    color: #FF0000;
}
.form-box.msg-error, tr.msg-error {
    background-color: rgba(255,0,0,0.1);
}
.msg-table-error{
    font-size: 0.7rem;
    color: #FF0000;
}
.customer{
    position: relative;
    margin-top: 130px;
}
.taxe-header-right{
    position: relative;
}
.w-unit-mesure{ width: 100px; }
.w-qty{ width: 50px; }
.w-pu{ width: 125px; }
.w-vat{ width: 80px; }
.w-pt{ width: 125px; }
.hoverlay-controls{
    display: none;
}
.font-small{ font-size: 0.65rem; }
.hoverlay-controls.offset{
    z-index: 99;
    position: fixed;
    top: 90px;
    left: 135px;
    right: 15px;
    /* width: calc(100% - 35px); */
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.block-step{
    position: relative;
    width: 200px;
}
.taxe-step-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    overflow-x: hidden;
    overflow-y: auto;
}
.btn-plus-taxe{
    width: 30px;
    height: 30px;
    background-color: #09B6CC;
    border: none;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.btn-update-taxe{
    background-color: rgba(0, 0, 0, 0);
    border: thin solid #09B6CC;
    color: #707070;
    font-size: 0.75rem;
    height: 30px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.btn-taxes{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: thin solid #09B6CC;
    color: #09B6CC;
    background-color: transparent;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-taxes:hover{
    background-color: #09B6CC;
    color: #FFFFFF;
}
.taxe-step, .taxe-declaration{
    position: relative;
    display: flex;
    flex-direction: column;
    color: #707070;
}
.taxe-step .taxe-header-form {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}
.head-static-center{
    position: relative;
    width: 250px;
    text-align: center;
    line-height: 15px;
}
.normal-txt-uppercase{
    font-size: 10pt;
    text-transform: uppercase;
}
.normal-txt{
    font-size: 10pt;
}
.sm-txt{font-size: 8pt;}
.block-separator{font-size: 8pt;}
.bottom-bordered{border-bottom: 0.5px solid #c9c9c9;}
.bottom-bordered-light{border-bottom: 1px solid #E0E0E0;}
.mw-100{ min-width: 100px; }
.mw-150{ min-width: 150px; }
.h-20{ height: 20px !important; line-height: 20px !important; }
.main-title{
    position: relative;
    margin: 5px auto;
    padding: 10px;
    width: 400px;
    background-color: #EBF6FF;
    font-size: 18px;
    color: #707070;
    font-weight: 700;
    text-align: center;
    line-height: 25px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.d-inline-block{display: inline-block;}
input.input-text{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    padding-left: 5px;
    width: 100%;
    height: inherit;
    border: none;
    background-color: transparent;
    font-size: 0.65rem;
    color: #707070;
}
input.input-text::-webkit-input-placeholder{
    font-size: 0.65rem;
    color: #707070;
}
input.input-text:-ms-input-placeholder{
    font-size: 0.65rem;
    color: #707070;
}
input.input-text::-ms-input-placeholder{
    font-size: 0.65rem;
    color: #707070;
}
input.input-text::placeholder{
    font-size: 0.65rem;
    color: #707070;
}
td input.input-text{
    height: 25px;
}
.table {
    border-collapse: collapse;
    font-size: 14px;
    border-spacing: 0;
    width: 100%;
    color: #707070;
}
table.table.table-bordered{border: 0.5px solid #999999;}
table.table td, table.table th {border-collapse: collapse; margin: 0; padding: 0; height: 25px !important;}
.v-align-middle{vertical-align: middle;}
.v-align-top{vertical-align: top;}
.v-align-bottom{vertical-align: bottom;}
.line-indicator{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
}
.table-checkbox{
    position: relative;
    margin: 0;
    padding: 5px;
    border: 0.5px solid #999999;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    color: #09B6CC;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.table-checkbox:checked{
    border: 1px solid #09B6CC;
    background-color: #09B6CC;
}

@media (max-width: 575.98px) { 
    .taxe-header-right{
        margin-top: 10px;
    }
    .box-infos{
        width: 100%;
    }
    .customer{
        margin-top: 10px;
    }
    .table-taxe thead tr th {
        font-size: 0.65rem;
        font-weight: bold;
        color: #707070;
        padding: 0 4px;
        overflow: hidden;
    }
    
    .table-taxe tr th span, .table-taxe tr td {
        max-width: 100px;
    }
    .alert.taxe-alert{
        font-size: 0.55rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
    }
    .hoverlay-controls.offset{
        left: 15px;
        right: 15px;
    }

}

@media (max-width: 767.98px) { 
    .hoverlay-controls.offset{
        left: 15px;
        right: 15px;
    }
}

.clearfix {
    overflow: auto;
  }

#tax-footer {
    font-size: 0.65rem;
}
.steps-content{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0 5px;
}
.steps-content .btn-step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-left: 10px;
    border: thin solid var(--default-btncolor);
    color: var(--default-btncolor);
    background-color: var(--default-btnbg);
    outline: none !important;
    cursor: pointer;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.steps-content .btn-step .step-position{
    position: relative;
    font-weight: bold;
    font-size: 0.8rem;
}

button.btn-step + div.step-name {
    position: relative;
    font-size: 0.8rem;
    color: var(--txt-color);
    margin-left: 5px;
}
.steps-content .btn-step.active {
    border: thin solid var(--active-btncolor);
    background-color: var(--active-btnbg);
    color: var(--active-btncolor);
}
.steps-content .btn-step.visited {
    border: thin solid var(--visited-btncolor);
    background-color: var(--visited-btnbg);
    color: var(--visited-btncolor);
}

.user-views {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
}
.invitation-form {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
.btn-user-add, .btn-user-add:hover, .btn-user-add:active, .btn-user-add:visited {
    background-color: #335284;
    height: 32px;
    border-radius: 4px;
    color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
    outline: none !important;
    border: none;
}
.tag-area{
    position: relative;
    padding: 5px;
    background-color: #F5F5F5;
    border-radius: 4px;
    min-height: 150px;
}
.btn-cancel, .btn-cancel:hover, .btn-cancel:active, .btn-cancel:focus, .btn-cancel.btn.btn-primary:active .btn-cancel:visited {
    display: block;
    background-color: #FFFFFF !important;
    height: 32px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    border: 0.5px solid #B5B5B5 !important;
    color: #B5B5B5 !important;
    outline: none !important;
}

.btn-color-blue {
    background-color: #335284;
}

.btn-color-blue:hover {
    background-color: #335284 !important;
    cursor: pointer;
}

.btn-submit, .btn-submit:hover, .btn-submit:active, .btn-submit:visited{
    display: block;
    background-color: #335284 !important;
    height: 32px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    color: #FFFFFF;
    outline: none !important;
    border: none;
    color: #FFFFFF;
}
.email-text{
    color: #177BD1;
}

.user-name-width {
    width: 25%;
}
.user-surname-width {
    width: 25%;
}
.user-email-width {
    width: 30%;
}
.user-role-width {
    width: 20%;
}

@media (min-width: 767.98px) {
    .user-surname-width {
        width: 20%;
    }
    .user-role-width {
        width: 15%;
    } 
    .user-status-width {
        width: 10%;
    }
}

.users-page {
    position: relative;
}
.users-content{
    position: relative;
    flex: 1 1 auto;
}
.user-detail {
    position: relative;
    display: flex;
    flex-direction: column;
}
.registerAcc{
    position: relative;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background-color: #335284;
}

.ra-wrapper{
    position: relative;
    margin: 0;
    padding: 0;
    height: inherit;
    width: inherit;
    background: url(/static/media/worldmap.06492a7b.svg) no-repeat fixed content-box;
    background-size: contain;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.ra-form-wrapper{
    background-color: #FFF;
    border-radius: 5px;
    height: auto;
    width: 395px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0px;
    margin-right: 200px;
}

.ra-form-title{
    color: #177BD1;
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ra-fields-wrapper{
    padding: 10px 20px 0px 20px;
    display: flex;
    flex-direction: column;
}

.ra-field-item{
    border-radius: 3px;
    height: 40px;
    width: 100%;
}

.ra-form-button{
    width: 100%;
    height: 40px;
    background-color: #005AAA;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 3px;
}

.ra-form-google{
    width: 100%;
    height: 40px;
    background-color: #FFF;
    color: #b5b5b5;
    font-size: 0.85rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.ra-wrapper .co-note{
    padding: 3px;
    font-size: 0.7rem;
    color: #666666;
}
.ra-note b {
    color: #005AAA;
    font-size: 0.8rem;
    font-weight: bold;
}

@media (max-width: 767.98px) { 
    .ra-wrapper{
        display: flex;
        justify-content: center;
        background-size: 110%;
        background-position: top -75px left 75px;
    }
    .ra-form-wrapper{
        position: relative;
        width: 395px;
        margin: 200px 0 0 0;
    }
    .co-form-title{
        font-size: 1.1rem;
    }
}

@media (max-width: 575.98px) { 
    .ra-wrapper{
        display: flex;
        justify-content: center;
        background-size: 150%;
        background-position: top -50px left 0;
    }
    .ra-form-wrapper{
        position: relative;
        margin: 150px 0 0 0;
        width: calc(100% - 20px);
    }
    .ra-form-title{
        font-size: 1.1rem;
    }
}

.registerAcc{
    height: 100vh;
    width: 100vw;
    background-color: #335284;
}

.ra-wrapper{
    height: 100vh;
    width: 100vw;
    background: url(/static/media/worldmap.06492a7b.svg) no-repeat fixed content-box;
    background-size: contain;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.ra-form-wrapper{
    background-color: #FFF;
    border-radius: 5px;
    height: auto;
    width: 395px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0px;
    margin-right: 200px;
}

.ra-form-title{
    color: #177BD1;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ra-fields-wrapper{
    padding: 10px 20px 0px 20px;
    display: flex;
    flex-direction: column;
}

.ra-form-button{
    width: 100%;
    height: 40px;
    background-color: #005AAA;
    color: #FFF;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    border-radius: 3px;
}

.ra-form-google{
    width: 100%;
    height: 40px;
    background-color: #FFF;
    color: #b5b5b5;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.pwd-btn-back {
    background-color: transparent;
    border-radius: 3px;
    padding: 2px;
    width: 25px;
    float: left;
    margin: 0 0px 0px 10px;
    cursor: pointer;
}
.pwd-ra-form-title{
    color: #177BD1;
    font-size: 20px;
    font-weight: bold;
    display: flex;
}

.pwd-title-text {
    text-align: center;
    width: 100%;
    height: 100%;
}

.pwd-title-email{
    width: 100%;
    height: 100%;
    color: #177BD1;
    font-size: 10px;
    text-transform: lowercase;
    font-style: italic;
    text-align: center;
}
