.NavLinkResponsive{
    position: relative;
    display: none;
    flex: auto;
    z-index: 99;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.NavLinkResponsive .navlink-hoverley{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: 0; padding: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

@media (max-width: 767.98px) {
    .NavLinkResponsive.show{
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
    }
    .NavLinkResponsive.show .NavLink.translatreX-0{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .NavLinkResponsive .navlink-hoverley.fade-out{
        animation: fade-out 0.3s ease-in-out forwards;
        -webkit-animation: fade-out 0.3s ease-in-out forwards;
}
}

@keyframes slide-in-left-0{
    to{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
}