:root{
    --maxwidth-tag: 400px;
}
.InputFee{
    position: relative;
    display: flex;
    margin: 20px 0 5px 0;
    padding: 10px;
    background-color: rgba(253, 84, 62, 0.25);
}
.upload-process{
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    width: 500px;
}
button.btn-upload {
    position: relative;
    display: inline-block;
    flex: auto;
    height: 30px;
    border: none;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: red;
    font-size: 0.8rem;
    text-align: center;
}
button.btn-upload > img{ margin-right: 5px; }
.observation{
    position: relative;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0 5px;
    height: 30px;
    align-items: center;
    flex: auto;
    background-color: #FFFFFF;
    border-radius: 4px;
}
.observation > input[type="text"]{
    position: relative;
    padding: 5px 5px 5px 10px;
    height: 30px;
    border: none;
    background-color: #FFFFFF;
    font-size: 0.8rem;
    flex: auto;
    border-radius: 4px;
}
.observation > input[type="text"]::placeholder{
    color: #B5B5B5;
}
button.validation{
    position: relative;
    width: 45px;
    height: 30px;
    border: none;
    background-color: #FFFFFF;
    border-radius: 4px;
}
button.validation:first-child{
    margin-right: 9px;
}
.InputFee .tags{
    position: relative;
    max-width: var(--maxwidth-tag);
    height: 30px;
    line-height: 30px;
    overflow-y: auto;
    overflow-x: hidden;
}
@media (max-width: 991.98px) {
    .upload-process{
        width: 300px;
    }
}