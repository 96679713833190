html {
    
}
/* Let's get this party started */
::-webkit-scrollbar {
    width: 6px;
}
/* Btton control */
::-webkit-scrollbar-button{

}
/* Track */
::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    background-color: 0.5px solid rgba(34, 34, 34, 0.2);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: rgba(34, 34, 34, 0.4); 
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(34, 34, 34, 0); 
}