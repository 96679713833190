.input-box {
    margin-bottom: 10px;
}
.active-label {
    color: #177BD1 !important;
}
.form-control {
    padding: .375rem .25rem;
}
.form-control:focus {
    outline: none !important;
    box-shadow: none;
}
.input-icon{
    height: 30px;
    width: 30px;
}
.input-group {
    border: 1px solid #b5b5b5;
    border-radius: 3px;
    padding-left: 5px;
}
.label-input {
    display: flex;
    /* justify-content: space-between;
    align-items: center; */
    color: #707070;
    font-size: 10px;
    font-family: 'Exo-light', sans-serif
}
.label-child {
    color: #707070;
    font-size: 12px;
    font-family: 'Exo-light', sans-serif
}
.extra-label {
    background-color: #177BD1;
    border-radius: 3px;
    color: #ffffff;
    font-size: 10px;
    font-weight: bold;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
}
.input-box .input {
    height: 30px;
    border: none;
    background-color: transparent !important;
    color: #707070;
    font-size: 12px;
    font-family: 'Exo-light', sans-serif
}
.input:focus {
    color: #177BD1 !important;
}
.input-box .input-datepicker {
    position: relative;
    border: none !important;
    color: #707070;
    font-size: 0.75rem;
}
.input-box .input-datepicker:focus {
    color: #177BD1 !important;
}

.required {
    color: #C60000;
    font-size: 15px;
}

.input-group:focus {
    outline: none !important;
    box-shadow: none;
    border: 1px solid #177BD1 !important;
}

.input-focused {
    border: 1px solid #177BD1!important;
}

.form-check-label {
    font-size: 10px;
    color: #707070;
}

select {
    -webkit-appearance: none;
    background-image: url('../../assets/svg/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: top 50% right 10px;
    background-color: #707070;
}

.input-readonly {
    background-color: #EDEAEA;
    border: none;
}
.input-error {
    border-color: #C60000 !important;
}
.input-hasError {
    margin-left: 5px;
    font-size: 8px;
    color: #C60000;
}