.search{
    padding: 10px 20px;
    width: 260px;
    height: 30px;
    background-color: #F0F0F0;
    border-radius: 50px;
    outline: none;
    border: none;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    background-image: url('../../assets/svg/icon-search.svg');
    background-repeat: no-repeat;
    background-position: top 50% right 20px;
}

.search-fees {
    background-image: url('../../assets/svg/icon-search-red.svg');
}

.search-bankRecords {
    background-image: url('../../assets/svg/icon-search-orange.svg')
}

.search-social {
    background-image: url('../../assets/svg/icon-search.svg');
}

@media (max-width: 767.98px) {
    .ct-search{
        width: 300px;
        max-width: 90%;
        margin-left: 5px;
    }
    .ct-search input.search{
        width: 100%;
    }
    .search-social {
        flex: 1 auto !important;
    }
}
@media (max-width: 575.98px) {
    .ct-search{
        width: 100%;
        max-width: 100%;
        margin-left: 3px;
    }
}