.search-bankRecord{
    position: relative;
    display: flex;
    flex: 1 auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bank-import {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 auto;
}

.title-tri{
    display: flex;
    align-items: flex-end;
    color: #555555;
    font-weight: bold;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem; 
    vertical-align: bottom;
    margin-right: 9px;
}

.col-description {
    padding-left: 10px;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    text-decoration: none
}
.btn-bank-account {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    width: 475px;
}
.reconciliation-filter {
    margin-left: 2px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .search-bankRecord{
        flex-direction: column-reverse;
    }
    .bank-import { 
        width: 100%;
        max-width: 100%;
    }
    .btn-bank-account {
        margin: 10px 0 0; 
    }
    .reconciliation-filter {
        margin: 10px 0 0;
    }
}

@media (max-width: 991.98px) {
    .search-bankRecord .col {
        padding: 0;
    }
    .btn-bank-account .btn {
        font-size: 8px;
        
    }
    .btn-bank-account {
        width: 350px;
        margin-left: 0;
    }
    .reconciliation-filter {
        margin-left: 0;
    }
    .bank-import .CustomInputFile{
        display: none;
    }
}

@media (max-width: 767.98px) {
    .search-bankRecord{
        /* flex-direction: column-reverse; */
    }
    .search-bankRecord .col {
        margin-top: 5px;
        padding: 0;
    }
    .btn-bank-account {
        margin-left: 0;
        max-width: 100%;
        width: 100%;
    }
    .reconciliation-filter {
        margin-left: 0;
    }
    .btn-bank-account .btn {
        font-size: 8px;
    }
}