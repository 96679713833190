.DateItemList{
    width: 35px;
    height: 35px;
    border-radius: 5px;
    color:  #FFFFFF ;
    overflow: hidden;
    font-family:  "Exo" ;
    transform: scale(0.8);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.DateItemList.active{
    width: 35px;
    height: 35px;
    border-radius: 2px;
    border: none;
    color:  #333333 ;
    overflow: hidden;
    font-family:  "Exo" ;
    transform: scale(0.8);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.DateItemList .boxDay{
    display: flex;
    height: 60%;
    background-color: #CBCBCB;
    align-items: center;
    justify-content: center ;
}
.DateItemList .boxDayactive{
    display: flex;
    height: 60%;
    align-items: center ;
    justify-content: center ;
}
.DateItemList .active-fees{
    background-color:  #F56969 ;
}
.DateItemList .active-bank{
    background-color:  #FAAA20 ;
}
.DateItemList .active-bill{
    background-color:  #0CBF28 ;
}
.DateItemList .boxMonth{
    display:  flex ;
    height: 40%;
    align-items: center ;
    justify-content: center ;
    background-color:#AAA7A7 ;
}
.DateItemList .boxMonthactive{
    display: flex ;
    height: 40%;
    align-items: center ;
    justify-content: center ;
    /* border-top: 0.5px solid rgba(255,255,255,0.7); */
}
.DateItemList .month-fees{
    background-color:#E23E3E ;
}
.DateItemList .month-bank{
    background-color:#E29300 ;
}
.DateItemList .month-bill{
    background-color:#0C9F23;
}
.DateItemList .txtDay{
   font-family: "Exo" ;
   font-size: 0.9rem;
   font-weight: bold ;
   color: #FFFFFF ;
   margin-top: 5px;
}
.boxDayactive .txtDay{
    color:  #FFFFFF ; 
}
.DateItemList .txtMonth{
   font-family: "Exo" ;
   font-size: 0.55rem;
   font-weight: bold !important;
   color: #FFFFFF ;
   text-transform: capitalize;
}
.boxMonthactive .txtMonth{
    color:  #FFF ; 
}