.modal-detail{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    background-color: #fff;
    overflow: hidden;
}
.modal-active {
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    width: 450px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.modal-detail-content {
    flex: 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}
.details-header {
    font-size: 15;
    font-weight: bold;
}
.btn-close {
    padding: 0px;
    border: none;
    background-color: transparent;
}

.btn-detail {
    height: 30px;
    padding: 0px !important;
}
.nav{
    flex-wrap: nowrap;
}

.nav-staff-info {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: row !important;
    border-bottom: 0.4px solid rgba(112, 112, 112, 0.3);
    margin-top: 20px;
}
.MuiTabs-flexContainer {
    border-bottom: 0.3px solid rgba(112, 112, 112, 0.2);
}
.MuiTab-wrapper{
    font-weight: bold;
}
.MuiTab-root {
    font-family: 'Exo' !important;
    text-transform: none !important;
    color: #707070 !important;
}
.nav-staff-info .nav-link.active {
    background-color: none;
    color: #177BD1;
    border-bottom: 4px solid rgba(112, 112, 112, 0.1);
}

.nav-staff-info .nav-link {
    border-radius: 0px;
    color: #707070;
}

.nav-link {
    padding: 10px 0px;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
    width: 105px;
}

.container-btn {
    border-top: 0.2px solid rgba(112, 112, 112, 0.2);
}

.btn-back {
    background-color: transparent;
    border: 1px solid #177BD1;
    border-radius: 3px
}

.btn-next {
    height: 30px;
    background-color: #177BD1;
    border: 1px solid #177BD1;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.w-46 {
    width: 46%;
}

.tag-container{
    flex-wrap: wrap;
}

.box-child {
    padding-left: 5px;
    border: 1px solid #b5b5b5;
    border-radius: 3px;
    min-height: 32px;
    margin-bottom: 10px;
    margin-top: 1px;
}

.box-listChild {
    padding: .375rem .25rem;
}
.modal-child-title{
    margin: 1rem 0rem;
    color: #177BD1;
}
.nb-child {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #177BD1;
    color: #fff;
    padding: 3px;
    font-size: 10px;
    border-radius: 50%;
    margin-left: 5px;
    width: 15px;
    height: 15px;
}
.modal-child-btn{
    margin-top: 50px;
}
.btn {
    height: 30px;
    font-size: 12px;
}
.btn:focus {
    box-shadow: none;
}
.btn-annuler {
    text-align: center;
    background-color: #b5b5b5;
    color: #fff;
}
.btn-annuler:hover {
    color: #fff;
}
.btn-validate {
    text-align: center;
    border: 0.2px solid #177BD1;
    color: #177BD1;
    background-color: transparent;
}
.btn-validate:hover {
    color: #177BD1;
}

.pdf-box {
    background-color: #E8E8E8;
    border-radius: 3px;
}
.pdf-title {
    font-size: 10px;
    padding-left: 5px;
}

.swal-custom-container {
    background-color: red
}
.swal-custom-notif{
    color: #555555 !important;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.9rem !important;
}
.swal-custom-icon {
    width: 24px;
    height: 24px;
}
/* Modal content preview */
.modal-detail-preview{
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-detail-preview .header-preview{
    position: relative;
    padding: 10px;
    background-color: #F8F8F8;
}
.modal-detail-preview .ico-content{
    position: relative;
    display: flex;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
}
.modal-detail-preview .header-preview-title{
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    color: #707070;
}
.modal-detail-preview .content-preview{
    position: relative;
    border-top: 0.4px solid rgba(112, 112, 112, 0.2);
}
.modal-detail-preview .item-separator{
    position: relative;
    /* display: block; */
    border-top: 0.4px solid rgba(112, 112, 112, 0.2);
}
div.px-0{
    /* overflow-x: hidden !important; */
}
div.px-0 > div{
    padding-right: 1em;
    /* overflow: hidden; */
}
.labeled{
    background-color: #177BD1;
    padding: 1px 5px;
    height: max-content;
    color: #FFFFFF;
    font-size: .65rem;
    font-weight: bold;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.txt-primary{
    color: #177BD1;
}

.payTitle {
    font-size: 12px;
    margin-bottom: 0;
    font-weight: bold;
    color: #707070;
}
.react-swipeable-view-container{
    height: 100% !important;
}
div[data-swipeable="true"]{
    overflow-x: hidden !important;
}
div[data-swipeable="true"], div[data-swipeable="true"] > div:first-child{
    display: flex;
    flex-direction: column;
}
div[data-swipeable="true"] > div:first-child{
    flex: 1 auto !important;
}
.modal-detail-preview div.MuiTabs-root + div {
    display: flex;
    flex-direction: column;
    flex: 1 auto !important;
    height: 30vh;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.payslip-tab div.MuiTabs-root + div {
    display: flex;
    flex-direction: column;
    flex: 1 auto !important;
    height: 30vh;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 112px !important;
    }
}
@media (max-width: 575.98px) {
    .modal-detail{
        width: 100%;
    }
    .modal-detail-preview{
        margin-top: 0 !important;
    }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .modal-detail{
        width: 100%;
    }
    .modal-detail-preview{
        margin-top: 0 !important;
    }
}