.reconciliation{
    position: relative;
    color: #333333;
}
.period-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;;
}
.period-form .date-form{
    flex: 1;
    margin-right: 5px;
}
.period-form .date-form:last-child{
    margin-right: 0;
}
.text-header-bank{
    font-size: 0.8rem;
    color: #707070;
}
.text-modal-bank{
    color: #707070;
    font-size: 0.75rem;
}
.date-filter{
    background-color: #F0F0F0;
    border-radius: 50px;
    outline: none;
    border: none;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    background-repeat: no-repeat;
    background-position: top 50% right 20px;
}
.invoice-filter{
    position: relative;
    display: block;
    border: none;
    background-color: #F0F0F0;
    border-radius: 50px;
    padding: 5px 30px 5px 10px;
    font-size: 0.75rem;
    width: 100%;
    height: 30px;
    background-image: url('../../../assets/svg/icon-search-orange.svg');
    background-repeat: no-repeat;
    background-position: top 49% right 10px;
}
.invoice-filter::placeholder{
    color: #707070;
}
.card-item-detail{
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 1px 7px #E1E1E1;
    -moz-box-shadow: 0px 1px 7px  #E1E1E1;
    -webkit-box-shadow: 0px 1px 7px #E1E1E1;
}
.btn-drop-up{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.btn-drop-up:hover{
    background-color: transparent;
}

button.upload {
    display: inline-block;
    justify-content: center;
    align-items: center;
    height: 30px;
    border: 1px solid gray;
    border-radius: 4px;
    width: 100%;
    background-color: #FFFFFF;
    font-size: 0.8rem;
}

button.upload > img {
    margin-right: 5px;
}

button.upload {
    border: 1px solid #e29300;
}