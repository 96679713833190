.payslip-tab{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    /* flex-shrink: 1; */
    font-size: 0.8rem;
}

div.ctn-tab {
    padding: 20px !important;
}

.payslip-tab .sub-title-tab{
    position: relative;
    margin: 5px 0 10px 0;
    height: 30px;
    line-height: 30px;
    font-family: 'Exo ExtraLight', Arial, Helvetica, sans-serif;
    color: #177BD1;
    border-bottom: 0.4px solid rgba(181, 181, 181, 0.7);
}
.payslip-tab .input-label{
    position: relative;
    margin-bottom: 5px;
    font-family: 'Exo Light', Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    color: #707070;
}
.payslip-tab input[type="text"], .payslip-tab input[type="number"]{
    font-family: 'Exo Light',Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    color: #707070;
}
.payslip-tab .add-btn{
    font-size: 0.75rem;
    color: #ffffff;
    border: none;
    background-color: #177BD1;
    border-radius: 3px;
}
.payslip-tab .add-btn:focus{
    color: #ffffff !important;
}
.payslip-tab .form-control:focus, .payslip-tab  .form-control:focus ~ .input-label{
    border-color: #177BD1 ;
    color: #177BD1;
}
.payslip-tab .s-dropdown{
    width: 100%;
    border: 0.5px solid #B5B5B5;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.payslip-tab .dropdown-toggle{
    width: 100%;
    text-align: right;
}
.payslip-tab .dropdown-menu {
    margin-left: -5px;
    width: calc(99% + 5px);
    font-size: 0.75rem;
    font-family: 'Exo', Arial, Helvetica, sans-serif !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
}
.payslip-tab .dropdown-item{
    padding-left: 5px;
    padding-right: 5px;
}
.payslip-tab .content-prime{
    position: relative;
    display: block;
    padding: 5px;
    height: 200px;
    background-color: #F0F0F0;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.table-cumuls {
    position: relative;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2px;
    margin-bottom: 1rem;
}
.table-cumuls > thead {
    background-color: #F4F4F4;
}
.table-cumuls tr {
    height: 30px;
    box-shadow: 0 0 1px 0 #B5B5B5;
}
.table-cumuls th:not(:first-child):not(:last-child) {
   border-top: 0.5px solid #B5B5B5;
   border-bottom: 0.5px solid #B5B5B5;
}
.table-cumuls th:first-child {
   border: 0.5px solid #B5B5B5;
   border-right: none;
}
.table-cumuls th:last-child {
   border: 0.5px solid #B5B5B5;
   border-left: none;
}
.table-cumuls td:not(:first-child) {
   border: 0.5px solid #B5B5B5;
   border-left: none;
}
.table-cumuls td:first-child {
    border: 0.5px solid #B5B5B5;
 }
.table-cumuls th {
    border: none;
    font-size: 0.7rem;
    color: #707070;
    font-weight: 300;
    text-align: left;
}
.table-cumuls td {
    font-size: 0.7rem;
    color: #707070;
    font-weight: 600;
    text-align: left;
    padding-left: 0.2rem;
    height: 30px;
}
 input[role="combobox"]{ /*for autocomplete component */
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
    width: 100%;
    border: none;
    background-color: transparent;
}
