.createOrg{
    position: relative;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background-color: #335284;
}

.co-wrapper{
    position: relative;
    margin: 0;
    padding: 0;
    height: inherit;
    width: inherit;
    background: url("../../assets/svg/worldmap.svg") no-repeat fixed content-box;
    background-size: contain;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.co-form-wrapper{
    background-color: #FFF;
    border-radius: 5px;
    height: auto;
    width: 395px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0px;
    margin-right: 200px;
}

.co-form-title{
    color: #177BD1;
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.co-fields-wrapper{
    padding: 10px 20px 0px 20px;
    display: flex;
    flex-direction: column;
}

.co-field-item{
    border-radius: 3px;
    height: 40px;
    width: 100%;
}

.co-form-button{
    width: 100%;
    height: 40px;
    background-color: #005AAA;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 3px;
}
.co-wrapper .co-note{
    padding: 3px;
    font-size: 0.7rem;
    color: #666666;
}
.co-note b {
    color: #005AAA;
    font-size: 0.8rem;
    font-weight: bold;
}
@media (max-width: 767.98px) { 
    .co-wrapper{
        display: flex;
        justify-content: center;
        background-size: 110%;
        background-position: top -75px left 75px;
    }
    .co-form-wrapper{
        position: relative;
        width: 395px;
        margin: 200px 0 0 0;
    }
    .co-form-title{
        font-size: 1.1rem;
    }
}

@media (max-width: 575.98px) { 
    .co-wrapper{
        display: flex;
        justify-content: center;
        background-size: 150%;
        background-position: top -50px left 0;
    }
    .co-form-wrapper{
        position: relative;
        margin: 150px 0 0 0;
        width: calc(100% - 20px);
    }
    .co-form-title{
        font-size: 1.1rem;
    }
}

