.registerAcc{
    height: 100vh;
    width: 100vw;
    background-color: #335284;
}

.ra-wrapper{
    height: 100vh;
    width: 100vw;
    background: url("../../assets/svg/worldmap.svg") no-repeat fixed content-box;
    background-size: contain;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.ra-form-wrapper{
    background-color: #FFF;
    border-radius: 5px;
    height: auto;
    width: 395px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0px;
    margin-right: 200px;
}

.ra-form-title{
    color: #177BD1;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ra-fields-wrapper{
    padding: 10px 20px 0px 20px;
    display: flex;
    flex-direction: column;
}

.ra-form-button{
    width: 100%;
    height: 40px;
    background-color: #005AAA;
    color: #FFF;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    border-radius: 3px;
}

.ra-form-google{
    width: 100%;
    height: 40px;
    background-color: #FFF;
    color: #b5b5b5;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.pwd-btn-back {
    background-color: transparent;
    border-radius: 3px;
    padding: 2px;
    width: 25px;
    float: left;
    margin: 0 0px 0px 10px;
    cursor: pointer;
}
.pwd-ra-form-title{
    color: #177BD1;
    font-size: 20px;
    font-weight: bold;
    display: flex;
}

.pwd-title-text {
    text-align: center;
    width: 100%;
    height: 100%;
}

.pwd-title-email{
    width: 100%;
    height: 100%;
    color: #177BD1;
    font-size: 10px;
    text-transform: lowercase;
    font-style: italic;
    text-align: center;
}