footer.container-footer{
    background-color: #FFFFFF;
    position: fixed;
    height: 50px;
    bottom: 0;
    width: 100%;
    z-index: 19;
    border-top: 0.5px solid rgba(0, 0, 0, .1);
    margin-top: 15px;
}

.footer{
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7em;
    font-weight: 300;
    color: #000000;
    padding-left: 30px;
}

@media (max-width: 767.98px) {
    .container-footer{
        justify-content: center;
        align-items: center;
    }
    .footer{
        padding-left: 0;
    }
}
@media (max-width: 575.98px) { 
    footer.container-footer{
        position: fixed;
        bottom: 0;
        bottom: 0;
        left: 0;
        right: 100%;
        z-index: 19;
        border-top: 0.5px solid rgba(0, 0, 0, .1);
    }
}
