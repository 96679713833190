/* CSS property right to left transition */

.right-to-left {
    display: block;
    margin-left: 500px;
    background-color: #fff;
    border: 1px solid white;
    width: 100%;
    overflow: hidden;
}

.right-to-left-entering {
    transition: left 200ms, right 200ms;
}

.right-to-left-entered {
    margin-left: 0px;
    transition: left 200ms, right 200ms;
}

.right-to-left-exiting {
    margin-left: 500px;
    transition: left 200ms, right 200ms;
    display: none
}

.right-to-left-exited {
    margin-right: 500px;
    /* transition-duration: 1s; */
    /* display: none; */
}

/* CSS property left to right transition */

.left-to-right {
    display: block;
    margin-right: 500px;
    background-color: #fff;
    border: 1px solid #fff;
    width: 100%;
    overflow: hidden;
}

.left-to-right.entering {
    transition-duration: 1s;
}

.left-to-right.entered {
    margin-right: 0px;
    transition-duration: 1s;
}

.left-to-right.exiting {
    display: none;
}

/* CSS property bottom to top transition */

.bottom-to-top {
    display: block;
    margin-top: 100vh;
    background-color: #fff;
    border: 1px solid white;
    width: 100%;
}

.bottom-to-top-entering {
    transition-duration: 500ms;
}

.bottom-to-top-entered {
    margin-top: 0px;
    transition-duration: 500ms;
}

.bottom-to-top-exiting {
    margin-top: 100vh;
    transition-duration: 500ms;
    display: none;
}

.bottom-to-top-exited {
    display: none;
}

/* CSS property top to bottom transition */

.top-to-bottom {
    display: block;
    margin-bottom: 100vh;
    background-color: #fff;
    border: 1px solid white;
    width: 100%;
    overflow: hidden;
}

.top-to-bottom-entering {
    transition-duration: 900ms;
}

.top-to-bottom-entered {
    margin-bottom: 0px;
    transition-duration: 900ms;
}

.top-to-bottom-exiting {
    margin-bottom: 100vh;
    transition-duration: 900ms;
    display: none;
}

/* CSS property custom transition */