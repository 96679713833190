.allowances{
    position: relative;
}
.allowances .input-label{
    position: relative;
    margin-bottom: 5px;
    font-family: 'Exo Light', Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    color: #707070;
}
.allowances .form-control:focus, .allowances  .form-control:focus ~ .input-label{
    border-color: #177BD1 ;
    color: #177BD1;
}
.allowances input[type="text"], .allowances input[type="number"]{
    font-family: 'Exo Light',Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    color: #707070;
}

.allowances .content-prime{
    position: relative;
    display: block;
    padding: 5px;
    min-height: 100px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #F0F0F0;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}