
.item-loader-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 180px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    padding: 15px;
}

.modal-logo-org-loader{
    position: relative;
    display: flex;
    margin: 0 0 0 0;
    padding: 5px;
    width: 150px;
    height: 150px;
    background-color: #F0F0F0;
    border-radius: 3px;
    animation:loader-anim 1.5s;
    -moz-animation: loader-anim 1.5s infinite; /* Firefox */
    -webkit-animation:loader-anim 1.5s infinite; /* Safari and Chrome */
}

.modal-descript-org-loader{
    position: relative;
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
    padding: 5px;
    width: 150px;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 3px;
    animation:loader-anim 1.5s;
    -moz-animation: loader-anim 1.5s infinite; /* Firefox */
    -webkit-animation:loader-anim 1.5s infinite; /* Safari and Chrome */
}


@-moz-keyframes loader-anim /* Firefox */
{
    0%   {background: #F0F0F0;}
    50%  {background: #FAFAFA;}
    100%   {background: #F0F0F0;}
}
 
@-webkit-keyframes loader-anim
{
    0%   {background: #F0F0F0;}
    50%  {background: #FAFAFA;}
    100%   {background: #F0F0F0;}
}

@media (max-width: 767.98px) {
    .item-loader-wrapper{
        height: 150px;
    }
    .modal-logo-org-loader{
        width: 100px;
        height: 84px;
    }
    .modal-descript-org-loader{
        width: 100px;
        height: 26px;
    }
}