.invoice-row-item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: thin solid rgba(0, 0, 0, 0);
    margin-bottom: 1px;
    padding-right: 3px;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px #E1E1E1;
    -moz-box-shadow: 0px 1px 3px  #E1E1E1;
    -webkit-box-shadow: 0px 1px 3px #E1E1E1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.invoice-row-item:hover{
    border: thin solid #E1E1E1;
}
.invoice-row-item .invoice-action{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.invoice-row-item .invoice-action:hover{
    background-color: #E1E1E1;
}
.invoice-row-item .invoice-statement{
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    flex: auto;
    font-size: 0.75rem;
}
.invoice-row-item .invoice-deliver{
    justify-content: flex-start;
    padding-left: 10px;
}
.invoice-row-item .invoice-amount{
    justify-content: flex-end;
    padding-right: 10px;
    font-weight: bold;
 }
.invoice-row-item .invoice-action {
    transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
}
.opacity-0 {
    opacity: 0;
}
.invoice-row-item:hover .invoice-unreconciled .ico-link{
    opacity: 1;
}
.invoice-row-item .invoice-detail .ico-unlink{
    display: none;
}
.invoice-row-item:hover .invoice-detail .ico-unlink{
    display: block;
    opacity: 1;
}
.invoice-row-item:hover .invoice-detail .ico-link{
    display: none;
    opacity: 0;
}

.invoice-active {
    background-color: rgba(23, 123, 209, 0.1);
    border: thin solid #E1E1E1;
}

@media (max-width: 767.98px) {
    .invoice-unreconciled .ico-link{
        opacity: 1;
    }

    .invoice-detail .ico-link{
        display: none;
    }
    .invoice-row-item .invoice-detail .ico-unlink{
        display: block;
        opacity: 1;
    }
}
