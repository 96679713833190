.main-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    /* width: 100%; */
    margin-left: 118px;
    padding-top: 75px;
    overflow: hidden;
    z-index: 1;
}

@media (max-width: 767.98px) {
    .main-content{
        margin-left: 0;
    }
}