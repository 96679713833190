.period-dropdown{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    height: 35px;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.period-dropdown .btn-selector-item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: inherit;
    margin: 0;
    padding: 0 15px;
    color: #707070;
    background-color: #FFFFFF;
    border: 0.5px solid #CCCCCC;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.btn-selector-item .period-dropdown-icon{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin: 0;
    overflow: hidden;
}
.btn-selector-item .period-dropdown-value{
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 auto;
    height: inherit;
    margin: 0;
    padding: 0 0 0 5px;
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.period-dropdown > .list-box {
    position: absolute;
    z-index: 999;
    top: 40px;
    right: 0;
    display: flex;
    margin: 0;
    padding: 0;
    min-width: 100%;
    background-color: #FFFFFF;
    border: 0.5px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.list-box > .list-box-content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    margin: 0;
    padding: 0;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;

}
.list-box-content > .list-box-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    height: 30px;
    font-size: 0.75rem;
    color: #707070;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.list-box-content > .list-box-item.active {
    background-color: rgba(0, 0, 0, 0.05);
}
.list-box-content > .list-box-item:not(.active):hover{
    background-color: rgba(0, 0, 0, 0.05);
}
.list-box > .custom-box-range{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 300px;
    border-left: 0.5px solid #CCCCCC;
}
.custom-box-range .title-custom-box{
    font-size: 0.75rem;
    color: #707070;
    font-weight: 700;
}
.custom-box-range .react-datepicker__input-container input[type="text"] {
    font-size: 0.75rem;
    padding: 5px;
    color: #707070;
    background-color: #EEEEEE;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.list-box-content .list-box-item.active-custom{
    background-color: rgba(0, 0, 0, 0.05);
}
.ft-white {
    color: #FFFFFF;
}
.ft-white:hover {
    color: #FFFFFF;
}
