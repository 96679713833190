.bank-statement-details{
    position: relative;
}
.text-modal-bank{
    color: #707070;
    font-size: 0.75rem;
}
.invoice-filter{
    position: relative;
    display: block;
    border: none;
    background-color: #F0F0F0;
    border-radius: 50px;
    padding: 5px 30px 5px 10px;
    font-size: 0.75rem;
    width: 100%;
    height: 30px;
    background-image: url('../../../assets/svg/icon-search-orange.svg');
    background-repeat: no-repeat;
    background-position: top 49% right 10px;
}
.invoice-filter::placeholder{
    color: #707070;
}