:root{
   --bg-navlink: #335284;
   --color-navlink: #FFFFFF;
   --separator-color-navlink: rgba(96, 153, 242, 0.4);
}
.NavLink{
   z-index: 999;
   position: fixed;
   left: 0; top: 0; bottom: 0;
   margin: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   padding: 0;
   width: 118px;
   /* height: 100vh; */
   background-color: var(--bg-navlink);
   color: var(--color-navlink);
   border-right: 1px solid rgba(0,0,0,.1);
   transition: all 0.3s ease-in-out;
   -ms-transition: all 0.3s ease-in-out;
   -moz-transition: all 0.3s ease-in-out;
   -o-transition: all 0.3s ease-in-out;
   -webkit-transition: all 0.3s ease-in-out;
}

.NavLink .nav-element{
   position: relative;
   width: 100%;
   margin: 0;
   padding: 0;
   flex: 1 auto;
   overflow-y: auto;
   overflow-x: hidden;
}
.NavLink .logo-container {
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0;
   width: 100%;
   height: 75px !important;
   background-color: transparent;
   border-bottom: 0.5px solid var(--separator-color-navlink);
}
.NavLink .logo{
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0;
   width: 90%;
   height: 50px !important;
   background-repeat: no-repeat;
   background-position: center;
   background-image: var(--logo-url);
   background-size: contain;
   color: var(--color-navlink);
   font-size: 1.3rem;
   cursor: pointer;
}
.bloc-1{
   display: inline;
   font-weight: bold;
   color: #177BD1;
   font-weight: bold;
   font-size: 0.9rem;
   font-family: "Exo",Arial, Helvetica, sans-serif;
}

 .bloc-2{
    display: inline;
    font-weight: bold;
    color: #FAAA20;
    /* font-weight: 300;
    font-size: 0.9rem; */
    font-family: "Exo",Arial, Helvetica, sans-serif;
 }

 .logo-footer{
   position: fixed;
   bottom: 0;
   width: 100%;
   height: 50px;
   color: #000000;
 }
 .dizen-logo {
    z-index: 1000;
   position: relative;
   width: 100%;
   height: 50px;
   border-top: 0.5px solid var(--separator-color-navlink);
   background-color: transparent;
}
