/* The Modal (background) */
.switch-org-modal {
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    bottom: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s
}
.switch-org-modal.fadeOut {
    -webkit-animation-name: fadeOut;
    -webkit-animation-duration: 0.4s;
    animation-name: fadeOut;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
}
.switch-org-modal.hidden {
    display: none;
}
/* Modal Content */
.switch-org-modal__content {
    position: fixed;
    top: 0;
    background-color: #fefefe;
    width: 100%;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
    padding-top: 0px;
    overflow-x: auto;
    overflow-y: hidden;
}

.switch-org-modal .switch-org-modal__content.slideOut{
    -webkit-animation-name: slideOut;
    -webkit-animation-duration: 0.4s;
    animation-name: slideOut;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
}

.switch-org-modal .btn-close {
    z-index: 99;
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 0px;
    border: none;
    background-color: transparent;
}

.org-items-wraper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 auto;
    /* align-content: flex-start; */
}

.switch-org-modal__content-body-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}

.switch-org-modal__content-body {
    padding-right: 25px;
    padding-bottom: 2px;
    padding-left: 25px;
    width: 100%;
}

.modal-footer {
    padding: 2px 16px;
    background-color: #ffffff;
    color: white;
}

.modal-orgName{
    display: block;
    font-weight: bold;
    color: #177BD1;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-socialReason-wrapper{
    height: 16px;
    width: auto;
}

.modal-socialReason{
    display: block;
    font-weight: 300;
    font-size: 0.8rem;
    color: #FAAA20;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-org-description{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    justify-content: center;
    width: 150px;
    margin: 0 0 0 0;
}
.modal-org-logo-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 0;
    padding: 5px;
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: var(--logo-url);
    background-size: contain;
    background-color: #F0F0F0;
    border-radius: 4px;
}

.modal-org-logo {
    height: auto;
    width: 100%;
}

.modal-item-list{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 180px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    padding: 15px;
    margin: 10px;
}

.switch-org-modal .add-org-btn{
    position: relative;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    margin: 0 50px 0 15px;
    padding: 5px;
    width: 150px !important;
    height: 150px !important;
    background-color: transparent !important;
    border-color: #EAEAEA;
    border-width: 3px;
    border-style: dashed;
    border-spacing: 10px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
.switch-org-modal .add-org-btn:hover {
    border-color: #C1BEBE;
}
.switch-org-modal .add-org svg path { 
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
.switch-org-modal .add-org-btn:hover svg path { 
    fill: #C1BEBE;
}
.switch-org-modal .add-org-btn .label{
    margin-top: 10px;
    font-size: 0.7rem;
    color: #C1BEBE;
    text-align: center;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
.switch-org-modal .add-org-btn:hover .label{
    color: #989898;
}
/* Add Animation */
@-webkit-keyframes slideIn {
    from {top: -300px; opacity: 0} 
    to {top: 0; opacity: 1}
}

@-webkit-keyframes slideOut {
    to {top: -300px; opacity: 0}
}

@keyframes slideIn {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
}

@keyframes slideOut {
    to {top: -300px; opacity: 0}
}

@-webkit-keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
}

@-webkit-keyframes fadeOut {
    to {opacity: 0}
}

@keyframes fadeIn {
    from {opacity: 0} 
    to {opacity: 1}
}

@keyframes fadeOut {
    to {opacity: 0}
}

@media (max-width: 767.98px) {
    .switch-org-modal__content {
        margin: 0;
        padding: 0;
    }
    .modal-item-list{
        height: 130px;
        padding: 5px;
        margin: 10px 0;
        align-items: flex-start;
    }
    .modal-orgName{
        margin-top: 5px;
        font-weight: bold;
        font-size: 0.75rem;
        color: #177BD1;
    }
    .modal-socialReason{
        font-weight: 300;
        font-size: 0.55rem;
        color: #FAAA20;
    }
    .modal-logo-org{
        padding: 5px;
        width: 100px;
        height: 100px;
    }
    .switch-org-modal .add-org{
        width: 130px;
        height: 125px;
    }
}
@media (orientation: landscape) {
    
}
